import React from "react";

/* IMG */
import porQueGS360 from "../../assets/img/home/porQueGS360.png";
import iconAdministreEmpresa from "../../assets/img/home/iconAdministreEmpresa.svg";
import iconMenorCosto from "../../assets/img/home/iconMenorCosto.svg";
import iconSoporte from "../../assets/img/home/iconSoporte.svg";
import iconPlanIntegral from "../../assets/img/home/iconPlanIntegral.svg";
import iconSatisfaccion from "../../assets/img/home/iconSatisfaccion.svg";

/*CSS*/
import "./css/PorqueGS360.css";

/*BOOTSTRAP*/
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function PorqueGS360() {
  const options = [
    {
      value: 1,
      img: iconAdministreEmpresa,
      class: "text-left",
      tit: "Administra tu empresa online.",
    },
    {
      value: 2,
      img: iconMenorCosto,
      class: "text-left",
      tit: "El costo mas bajo con el mejor servicio",
    },
    {
      value: 3,
      img: iconSoporte,
      class: "text-left",
      tit: "Soporte personalizado",
    },
    {
      value: 4,
      img: iconPlanIntegral,
      class: "text-left pl-3",
      tit: "Servicios integrales 360",
    },
    {
      value: 5,
      img: iconSatisfaccion,
      class: "text-left",
      tit: "Satisfacción garantizada",
    },
  ];

  return (
    <Container className="pqGS360">
      <Row>
        <Col
          md={6}
          xs={12}
          className="text-center text-sm-left d-flex align-items-center flex-row"
        >
          <img src={porQueGS360} className="img-fluid mt-3" alt="GS360" />
        </Col>

        <Col md={6} xs={12} className="text-center text-sm-left">
          <h5 className="text-sm-left text-center pb-3 titH5">
            Nuestra ventaja
          </h5>

          <h2 className="titHero text-sm-left text-center pb-3 titH2">
            ¿Por qué GS360?
          </h2>

          <p className="heroText text-sm-left text-center pt-3 pb-3">
            En GS360 ofrecemos soluciones pensadas por y para emprendedores que
            necesitan ayuda y acompañamiento en asuntos empresariales para
            lograr posicionarse y expandirse en el mercado de forma segura.{" "}
          </p>

          <Row>
            <Col md={12}>
              {options.map((item) => (
                <div class="bgGreyDegrade w-100 d-flex justify-content-start align-items-center mt-4">
                  <img src={item.img} alt={item.tit} />
                  <p className={item.class}>{item.tit}</p>
                </div>
              ))}
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

export default PorqueGS360;

import React from "react";

/*CSS*/
import "../css/MrBills.css";

/*BOOTSTRAP*/
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default function ComoFuncionaMrBills() {
  const options = [
    {
      value: 1,
      tit: "Mr Bill’s realizará reuniones contigo.",
      txt:
        "Para entender el Core de tu negocio realizamos reuniones, proponiendo un plan de trabajo para adelantar la contabilidad de forma eficaz, eficiente y segura.",
    },
    {
      value: 2,
      tit: "Concierge personal",
      txt:
        "Elegirá uno de sus discípulos preferidos y lo asignará como tu concierge personal. El será tu contacto permanente para resolver todas tus inquietudes y se encargará de llevar la contabilidad de tu negocio al día, de acuerdo al plan de trabajo.",
    },
    {
      value: 3,
      tit: "Elaboración de Informes",
      txt:
        "Te reportará previamente las novedades y realizará en fechas claves; los reportes en los estados financieros, declaraciones de impuestos entre otros, para presentarlos ante las entidades correspondientes, para cumplir con todas tus obligaciones fiscales. En el evento en que tengas que pagar algún impuesto, te indicaremos con tiempo para que puedas asegurar tu flujo de caja.",
    },
    {
      value: 4,
      tit: "Presentación de Reportes",
      txt:
        "Tu concierge elaborará reportes claros e intuitivos sumamente útiles para tomar decisiones eficaces.",
    },
    {
      value: 5,
      tit: "Mr Bill´s Alert",
      txt:
        "Te mantendrá informado de cualquier novedad impositiva ó acción que requiera tu aprobación.",
    },
  ];

  return (
    <Container className="ComoFuncionaMrBills" fluid>
      <Container>
        <Row className="mt-4 pt-5">
          <Col md={7} xs={12} className="text-center mx-auto">
            <h2 className="titHero text-center pb-0">¿Cómo funciona?</h2>
          </Col>
        </Row>

        <Row className="pt-5 pb-4">
          <Col md={7} xs={12} className="text-center mx-auto">
            {options.map((item) => (
              <Row>
                <Col md={2} xs={12} className="text-center mt-5">
                  <span className="numeroItem mx-auto">{item.value}</span>
                </Col>
                <Col md={10} xs={12} className="text-center text-sm-left mt-5">
                  <h6 className="text-center text-sm-left">{item.tit}</h6>
                  <p className="text-center text-sm-left">{item.txt}</p>
                </Col>
              </Row>
            ))}
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

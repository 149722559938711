import React from "react";

/*CSS*/
import "../css/Faqs.css";

/*BOOTSTRAP*/
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import BoxesContacto from "../../Contacto/BoxesContacto";


export default function ContactoFaq() {

  
  return (  
       <Container className="contactoFaq" fluid>  
        <BoxesContacto  clase="containerBoxContacto"/>
      </Container>   
  );
}


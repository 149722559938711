import React from "react";
import { Link } from "react-router-dom";

/*IMAGENES*/
import logoSasWizars from "../../../assets/img/home/imgSasWizars.svg";
import logoRocketContracts from "../../../assets/img/rocketContracts/logowhite.png";
import logoBrandineitor from "../../../assets/img/brandineitor/logoBrandineitor.png";
import logoMrBill from "../../../assets/img/mrBill/logoMrBills.png";

/*CSS*/
import "./css/Bienvenido.css";

/*BOOTSTRAP*/
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default function FinalizarRegistro() {
  const optionsRocket = [
    { value: 1, tit: "Aumenta tu eficiencia" },
    { value: 2, tit: "Crea plantillas personalizadas" },
    { value: 3, tit: "Seguridad" },
    { value: 4, tit: "Seguimiento de los documentos" },
  ];

  return (
    <Container>
      <Row>
        <Col md={7} xs={12} className="pb-5  pt-5 text-center mx-auto">
          <p style={{ fontSize: "16px", lineHeight: "32px" }}>
            Esperamos ser tu aliado para que puedas enfocarte en tu empresa y
            crecer rápidamente!. Nuestra plataforma se encuentra en etapa beta e
            iremos activando las plataformas de cada uno de nuestros servicios
            progresivamente. ¿Cómo podemos ayudarte?
          </p>
        </Col>
      </Row>
      <Row>
        <Col md={12} xs={12} className="text-center boxPrecios">
          <div className="fndBoxPreciosRocket">
            <Row>
              <Col md={3} xs={12} className="pb-0 text-left">
                <img src={logoRocketContracts} alt="Rocket Contracts" />
              </Col>
            </Row>
            <Row className="pt-3">
              <Col md={4} xs={12} className="pb-3">
                <p class="text-sm-left text-center" style={{ color: "#fff" }}>
                  Comienza a operar tu empresa en Colombia, concéntrate en tus
                  negocios, deja los trámites en nuestras manos.
                </p>
              </Col>
              <Col md={4} xs={12}>
                <Row>
                  <Col md={10} xs={10} className="mx-auto">
                    <Row className="list-unstyled listaPreciosSpecs mb-4">
                      {optionsRocket.map((item) => (
                        <Col className="col-md-12 col-12">
                          <p>{item.tit}</p>
                        </Col>
                      ))}
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col md={4} xs={12} className="pb-3">
                <a
                  href="https://www.globalstartup360.com/demo_rocket/login"
                  className="botones btnAzulDarkHover mx-auto "
                  style={{ width: "160px", margin: "20px 0 0 0" }}
                  rel="noreferrer"
                  target="_blank"
                >
                  Ingresar ahora
                </a>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <Row className="d-flex">
        <Col md={4} xs={12} className="mb-4">
          <div
            className="fndBoxOpciones text-center text-sm-left"
            style={{ backgroundColor: "transparent" }}
          >
            <img
              src={logoSasWizars}
              alt="SAS Wizard"
              className="mx-auto mx-sm-0"
            />
            <h5 className="introSasWizardBlue text-center text-sm-left">
              Crea tu empresa por $935.000
            </h5>
            <p className="txtPagoUnico text-center text-sm-left">
              {" "}
              + PROMO %5 Desc. (Pago único)
            </p>

            <Link
              to="/servicios/saswizard"
              className="botones btnNaranja mx-auto mx-sm-0 "
              style={{ width: "160px", margin: "15px 0 0 0" }}
            >
              Crear mi empresa
            </Link>
          </div>
        </Col>

        <Col md={4} xs={12} className="mb-4">
          <div className="fndBoxOpciones">
            <img src={logoBrandineitor} alt="Brandineitor" className="mb-3" />
            <p className="mhTxtPrec">
              Brandineitor es el guardián de tu marca, la mantendrá protegida de
              posibles riesgos de similitud y plagio o uso indebido de tu marca
              que puedan afectarla.
            </p>
            <p className="precioOpcion">
              Desde
              <b style={{ fontSize: "1.4rem" }}> $ 2.240.000 </b>
              <span
                style={{
                  width: "160px",
                  fontSize: "10px",
                  fontWeight: "normal",
                }}
              >
                {" "}
                 IVA Incluido{" "}
              </span>
            </p>
            <Link
              to="/servicios/brandineitor"
              className="botones btnCelesteExtra mx-auto mx-sm-0 "
              style={{ width: "160px", margin: "17px 0 0 0" }}
            >
              Registra tu marca
            </Link>
          </div>
        </Col>

        <Col md={4} xs={12} className="mb-4">
          <div className="fndBoxOpciones">
            <img src={logoMrBill} alt="Mr Bill" className="mb-3" />
            <p className="mhTxtPrec">
              Mr Bill’s contabiliza, reporta y presenta las declaraciones de
              impuestos por ti con total seguridad y compromiso.
            </p>
            <p className="precioOpcion">
              Desde
              <b style={{ fontSize: "1.4rem" }}> $300.000</b>
              <span> / Mes IVA Incluido</span>
            </p>
            <Link
              to="/servicios/mrbill"
              className="botones btnVioletaInverse mx-auto mx-sm-0 "
              style={{ width: "160px", margin: "15px 0 0 0" }}
            >
              Comienza Ahora
            </Link>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

import React from "react";
import { Link } from "react-router-dom";

/* IMG */
import legalManagerAsesoramiento from "../../../assets/img/legalManager/legalManagerAsesoramiento.png";

/*CSS*/
import "../css/LegalManager.css";

/*BOOTSTRAP*/
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default function AsesoramientoLegalManager() {
  return (
    <Container className="asesoramientoLegalManager" fluid>
      <Container>
        <Row>
          <Col md={6} xs={12} className="d-flex align-items-center">
            <Row>
              <Col md={10} xs={12}>
                <h6 className="text-center text-sm-left">
                  ¿Necesitas asesoramiento, personalizar o crear un nuevo
                  documento?
                </h6>

                <p className="text-center text-sm-left">
                  Cuentas con el respaldo de nuestro staff de abogados listos
                  para darte las soluciones que requieres a una tarifa accesible
                  de $375.000 COP IVA Incluido (hr).
                </p>

                <p className="text-center text-sm-left">
                  Mediante una videollamada tomamos tu requerimiento y
                  trabajaremos para darte una solución a medida.
                </p>

                <Link
                  to="/contacto"
                  className="botones btnVerdeDarkOutline mx-auto mx-sm-0"
                  style={{ width: "200px", margin: "30px 0 0 0" }}
                >
                  Comprar documentos
                </Link>
              </Col>
            </Row>
          </Col>
          <Col md={6} xs={12}>
            <img
              src={legalManagerAsesoramiento}
              className="mx-auto mb-1 img-fluid"
              alt="Necesitas asesoramiento, personalizar o crear un nuevo documento: Legal Manager"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

import React from "react";

/* IMG */
import imagenRocketContracts from "../../../assets/img/rocketContracts/imagenRocketContracts.png";

/*CSS*/
import "../css/RocketContracts.css";

/*BOOTSTRAP*/
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default function BioSeguridad() {
  return (
    <Container className="bgBioseguridad" fluid>
      <Container>
        <Row>
          <Col md={5} xs={12} className="text-center text-sm-left">
            <img
              src={imagenRocketContracts}
              className="mx-auto mb-1 img-fluid"
              alt="Rocket Contracts"
            />
          </Col>

          <Col md={7} xs={12} className="text-center text-sm-left">
            <h2 className="text-center text-sm-left pb-0">Bioseguridad</h2>

            <p className="text-center text-sm-left pt-4">
              La transformación digital de las empresas se ha intensificado
              durante la pandemia.
            </p>

            <p className="text-center text-sm-left pt-4">
              Rocket Contracts te ayudará a cumplir con las normas de la nueva
              realidad y cuidar la salud de tu equipo de trabajo y clientes,
              manteniendo el distanciamiento social con la seguridad, convicción
              y certeza que el contenido del documento es real y válido para los
              firmantes. Rocket Contracts te ayudará a cumplir con las normas de
              la nueva realidad y cuidar la salud de tu equipo de trabajo y
              clientes, manteniendo el distanciamiento social con la seguridad,
              convicción y certeza que el contenido del documento es real y
              válido para los firmantes.
            </p>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

import React, { useState } from "react";
import { Link } from "react-router-dom";

/* IMG */

import logoSasWizarsPrecios from "../../../assets/img/precios/logoSasWizarsPrecios.svg";
import ModalContacto from "../../Contacto/ModalContacto";

/*CSS*/
import "../css/SasWizard.css";

/*BOOTSTRAP*/
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default function PreciosSasWizard() {
  const [modalContactoShow, setModalContactoShow] = useState(false);

  const options = [
    { value: 1, tit: "Análisis de Registro" },
    { value: 2, tit: "Obtención de NIT" },
    { value: 3, tit: "Documentos" },
    { value: 4, tit: "Obtención de RUT" },
    { value: 5, tit: "Cálculo y gestión del pago de tasas." },
    { value: 5, tit: "Orientación Apertura Cuenta" },
  ];

  const selectedItems = [
    { id: 0, titulo: "Crea tu empresa por $935.000", precio: 935000 },
  ];
  const totalPrice = 935000;

  return (
    <Container>
      <Row>
        <Col md={12} xs={12} className="text-center boxPrecios mt-5">
          <div className="fndBoxPrecios">
            <Row>
              <Col md={2} xs={12} className="pb-3">
                <img src={logoSasWizarsPrecios} alt="SAS Wizard" />
              </Col>
              <Col md={10} xs={12}>
                <h6 className="pb-3 pt-2 text-center text-sm-left">
                  Crea tu empresa por $935.000 PROMO %5 Desc.
                  <span style={{ fontSize: "12px", fontWeight: "normal" }}>
                    <br />
                    (No incluye impuestos, tasas e importe de registro en la
                    cámara de comercio.)
                  </span>
                </h6>
              </Col>
            </Row>
            <Row className="pt-4">
              <Col md={4} xs={12} className="pb-4">
                <p class="text-sm-left text-center" style={{ color: "#fff" }}>
                  Comienza a operar tu empresa en Colombia, concéntrate en tus
                  negocios, deja los trámites en nuestras manos.
                </p>
              </Col>
              <Col md={8} xs={12}>
                <Row>
                  <Col md={12} xs={11} className="mx-auto">
                    <Row className="list-unstyled listaPreciosSpecs mb-4">
                      {options.map((item) => (
                        <Col className="col-md-6 col-12">
                          <p>{item.tit}</p>
                        </Col>
                      ))}
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col md={3} xs={12}>
                <button
                  onClick={() => setModalContactoShow(true)}
                  className="botones btnAzulDarkHover mx-auto mx-sm-0 "
                  style={{ width: "200px", margin: "0" }}
                >
                  Crear mi empresa
                </button>
              </Col>
              <Col md={9} xs={12}>
                <p
                  className="text-sm-left text-center pt-3"
                  style={{ color: "#fff" }}
                >
                  Si tienes dudas,{" "}
                  <Link to="/contacto" className="linkWhite">
                    contactanos para poder asesorarte &gt;
                  </Link>
                </p>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <ModalContacto
        datos={selectedItems}
        total={totalPrice}
        show={modalContactoShow}
        onHide={() => setModalContactoShow(false)}
      />
    </Container>
  );
}

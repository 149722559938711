import React from "react";

/*CSS*/
import "./css/Terminos.css";

/*BOOTSTRAP*/
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";

export default function Terminos() {
  return (
    <Container>
      <Row className="pt-4 seccionTerminos">
        <Col md={12} xs={12} className="text-justify">
          <p>
            Gracias, Gracias, Gracias. Le damos las gracias por confiar en
            nosotros y por tomarse el tiempo de explorar los servicios que
            queremos brindarles a los emprendedores, para hacer las gestiones,
            fáciles, rápidas y simples. Si está en esta página, es porque quiere
            conocer las reglas de juego para usar, navegar, explorar, registrar
            o conocer lo que hacemos en Global StartUp 360 Colombia S.A.S.
            (“GS360”) y con el fin de alimentar su curiosidad, ponemos a su
            disposición los siguientes Términos y Condiciones (en adelante
            “Términos” o “T&C”).
          </p>
          <p>
            Este documento contempla los términos entre Usted (“Usuario,
            Licenciatario, Firmante o Usted”) y GS360, respecto al uso,
            navegación, exploración, registro o acceso al sitio web (“Sitio”) y
            su contenido, el cual es de propiedad de GS360 y por el hecho de
            encontrarnos en un entorno virtual, autorizamos el uso, navegación,
            exploración, registro o acceso limitado no exclusivo al Sitio para
            efectos de informar lo que hacemos en el mercado, así como disponer
            de las interfaces que permitan interactuar y acceder a los demás
            micrositios web de nuestra propiedad.
          </p>
          <p>
            Para que seamos claros, el Código Fuente y la información depositada
            en nuestro Sitio es de nuestra propiedad y teniendo en cuenta que el
            uso, navegación, exploración, registro o acceso a nuestro Sitio lo
            hace bajo su propia voluntad y responsabilidad, al hacerlo reconoce
            nuestra condición de propietarios del Código Fuente al cual no
            tendrá acceso, y por tanto, tiene prohibido acceder bajo cualquier
            metodología conocida o por conocerse de acuerdo con los avances
            tecnológicos que día a día son cambiantes.
          </p>
          <p>
            Por tanto, prepárese una bebida y disponga del tiempo necesario para
            conocer, leer y comprender los Términos que ponemos a su
            disposición, antes de ingresar al Sitio. Entenderemos que si usa,
            navega, explora, registra o accede a nuestro Sitio o a cualquier
            Aplicación Informática (Las Aplicaciones) que pongamos en el Sitio,
            Usted acepta y por tanto se obliga libre y voluntariamente al
            cumplimiento de estos Términos y al uso responsable del contenido.{" "}
          </p>
          <p>
            <b>Edad mínima requerida.</b> Para usar, navegar, explorar,
            registrar, acceder e interactuar a nuestro Sitio o a cualquier(a)
            Aplicación(es), Usted deberá ser mayor de edad según la legislación
            aplicable de acuerdo con su nacionalidad. Para efectos de lo
            anterior, en Colombia, Usted deberá tener 18 años o más y por tanto,
            si no tiene la edad mínima requerida o la autorización requerida,
            por favor absténgase de usar, navegar, explorar, registrar, acceder
            e interactuar en nuestro Sitio o a cualquier(a) Aplicación(es).
          </p>
          <p>
            <b
              style={{
                textDecoration: "underline",
                textTransform: "uppercase",
              }}
            >
              1. Acerca de GS360:
            </b>{" "}
            GS360® ofrece el acceso a servicio de autogestión por medio de
            contenidos jurídicos, contables y administrativos para las
            actividades que pueda requerir su emprendimiento, de acuerdo con las
            instrucciones impartidas por Usted y a través del uso, navegación,
            exploración, registro, acceso e interacción en el Sitio o sus
            Aplicaciones. Los Servicios varían según las subcategorías en las
            cuales se dividen y en conjunto con el dominio www.gs360.co hacen
            parte de las prestaciones mercantiles de GS360 para el desarrollo de
            nuestra actividad empresarial.
          </p>
          <p>
            El acceso al portal en línea es gratuito y abierto al público
            general sobre la página de inicio. El acceso a los Servicios
            prestados estará definido a nuestra discreción, en cuanto a
            servicios gratuitos u onerosos de acuerdo con los Planes,
            Suscripciones o Membresías establecidos previamente por nosotros.
            Los Servicios podrán prestarse mediante la interacción en línea
            entre Usted y nosotros a través del Sitio o Aplicaciones, o por
            sistemas informáticos que desarrollan actividades mediante
            programación para desarrollar una función o tarea específica de
            acuerdo con la configuración que previamente hemos definido en
            función del servicio que pretenda adquirir.{" "}
          </p>
          <p>
            Los Servicios pueden ser gratuitos, onerosos o contratados
            directamente por El Usuario, según el tipo de Suscripción, Plan o
            Membrecía que contrate directamente con nosotros y cuyo precio será
            previamente publicado en el Sitio o enviado y comunicado a través de
            correo electrónico si fuere necesario. Todos los servicios son
            prestados a través del Sitio y bajo ninguna circunstancia podrán
            prestarse por fuera del Sitio o personalmente por parte del equipo
            de GS360.
          </p>
          <p>
            GS360 no es una Firma de Abogados ni de Contadores, sino por el
            contrario, es una herramienta tecnológica que facilita y favorece el
            acceso a servicios integrales tanto jurídicos, contables y
            administrativos para el desarrollo por autogestión de su propio
            emprendimiento. Salvo en el evento en que realicemos su
            representación o que por algún(o) servicio(s) debamos actuar por
            cuenta suya, dispondremos de los mayores y mejores esfuerzos para
            brindar el servicio, según como se explicará más adelante.{" "}
          </p>
          <p>
            Tanto el Sitio como su contenido no es un sustituto de la asesoría o
            servicios que pueda prestar un profesional de la abogacía o de la
            contabilidad y por tanto no es posible garantizar el éxito del
            mismo. Salvo en los eventos en que los servicios sean prestados
            directamente por nosotros, o que incumba realizar gestiones
            profesionales en nombre y por cuenta suya, GS360 lo informará y
            celebrará el correspondiente contrato para esos efectos.{" "}
          </p>
          <p>
            <b
              style={{
                textDecoration: "underline",
                textTransform: "uppercase",
              }}
            >
              2. El Sitio:
            </b>{" "}
            Corresponde al ambiente virtual desarrollado mediante un código
            fuente y dirección de URL www.gs360.co de la cual somos
            propietarios. El Sitio comprende tanto el frontdesk como el backdesk
            así como los micrositios y la(s) Aplicación(es) alojadas y puestas a
            disposición de Usuarios y Visitantes.
          </p>
          <p>
            {" "}
            <b>2.1. Licenciamiento.</b> Es el uso no exclusivo, limitado y
            revocable del Sitio y su(s) Aplicaciones de los cuales somos
            propietarios. GS360 prohíbe la realización de cualquier acto que
            tenga el objeto o como efecto modificar, reproducir, decodificar,
            desencriptar, desarmar, realizar ingeniería inversa, alterar,
            publicar, hipervincular, transferir, instalar software malicioso
            (malware); spyware; o phishing y en general realizar cualquier acto
            relacionado la alteración o divulgación de los contenidos y la
            información depositada en el Sitio o su(s) Aplicación(es) o respecto
            del código fuente, para sí mismo o para otras personas siendo una
            obligación de no hacer a su cargo.{" "}
          </p>
          <p>
            {" "}
            <b>2.2. Actualizaciones:</b> Son los procedimientos mediante
            programación del código fuente que realiza GS360 para mejorar el
            Sitio o su(s) Aplicaciones, los cuales podrán ser periódicos y a
            cargo de nosotros en cualquier tiempo. Y sin previo aviso o
            consentimiento de los Usuarios o Visitantes.
          </p>
          <p>
            {" "}
            <b>2.3. Términos:</b> Se refiere a los Términos y Condiciones para
            el uso, navegación, exploración, registro o acceso limitado y no
            exclusivo del Sitio o su(s) Aplicaciones.
          </p>
          <p>
            <b>2.4. Usuario(s):</b> Se refiere al perfil que Usted crea cuando
            ingresa y se registra en las bases de datos del Sitio, mediante un
            registro en un formulario que lo identifica plenamente como Usuario
            de GS360 a través de un nombre de usuario y una contraseña asignada
            por Usted, para su uso exclusivo, confidencial, personal e
            intransferible salvo autorización previa. La contraseña y el nombre
            de usuario serán de su absoluta responsabilidad en cuanto a la
            conservación, administración, custodia, uso y acceso en el Sitio o
            sus Aplicaciones, lo que nos permitirá identificarlo en cada
            registro, acceso, compra o adquisición de servicios ofrecidos por
            GS360. GS360 no se hace responsable por la perdida, transferencia,
            uso indebido o no autorizado de la(s) cuenta(s) registrada(s) en el
            Sitio, por cuanto es su obligación la custodia, uso y administración
            de dicha cuenta de usuario.
          </p>
          <p>
            <b>2.5. Registro de usuarios:</b> procedimiento que realiza al
            momento de inscribirse y depositar libre, consciente y expresamente
            sus datos personales
          </p>
          <p>
            <b>2.6. Uso del Sitio y su(s) Aplicaciones.</b> Usted podrá usar,
            navegar, explorar, acceder e interactuar bien sea como Visitante o
            como Usuario en el Sitio y su(s) Aplicaciones bajo el
            Licenciamiento, siendo usted el único responsable bien sea
            directamente, con o sin intención, conocimiento o consentimiento del
            buen uso de la información, el contenido y del sitio y su(s)
            Aplicaciones en todo momento. En el evento en que GS360 evidencie,
            detecte o sea alertado por cualquier medio sobre el uso indebido, no
            autorizado y en general por cualquier violación por parte de Usted
            respecto de estos Términos, podremos realizar una investigación para
            establecer y conocer los hechos pudiendo adoptar todas las medidas
            necesarias existentes o por existir para restringir las acciones que
            por uso indebido o no autorizado y/o violación de los Términos este
            realizando. De igual manera podremos iniciar todas las acciones
            legales en su contra y obtener la reparación de los perjuicios e
            indemnizaciones a que haya lugar bajo la legislación aplicable. El
            incumplimiento de estos Términos así como las conductas tendientes a
            realizar un daño o perjuicio de carácter informático pueden resultar
            en responsabilidad civil o penal en el evento en que determinemos
            que Usted incurre en alguna de esas conductas.
          </p>
          <p>
            <b>2.7. Prohibición de interferencia:</b> GS360 prohíbe la
            realización de cualquier acto que tenga el objeto o como efecto
            modificar, reproducir, decodificar, desencriptar, desarmar, realizar
            ingeniería inversa, alterar, publicar, hipervincular, transferir,
            instalar software malicioso (malware); spyware; y en general
            realizar cualquier acto relacionado la alteración o divulgación de
            los Contenidos y la información contenida en el Sitio o su(s)
            Aplicación(es) o respecto del código fuente, bien sea mediante la
            realización directa o por contrata de cualquier conducta, o por la
            imposición de cargas desproporcionadas sobre los sistemas de red de
            infraestructura del Sitio y su(s) Aplicación(es) para sí mismo o
            para otras personas siendo una obligación de no hacer a cargo de
            Usted.
          </p>
          <p>
            <b>2.8. Vínculo con sitios de terceros.</b>
            Recomendaciones para pasarelas de pago, terceros anunciantes etc. en
            donde GS360 no participa ni tenemos control sobre los datos de
            terceros.
          </p>
          <p>
            <b>2.9. Publicidad y avisos de terceros:</b> Se refiere a la
            información, Sitio, publicidad, oferta o Ads de terceros anunciantes
            a través de nuestro Sitio, proveniente de terceras personas que no
            tienen una vinculación directa con GS360 salvo por la posibilidad de
            realizar pautas publicitarias en nuestro Sitio, para lo cual Usted
            entiende y acepta que la información, comunicación, negociaciones,
            acuerdos o preacuerdos, promociones, o las relaciones que se
            ocasionen o se deriven de su interacción con el sitio del tercero
            directamente, se constituye en un acuerdo exclusivamente celebrado
            entre Usted y el tercero sin que GS360, participe, tenga o asuma
            algún tipo de responsabilidad sobre la relación contractual o
            extracontractual que tengan entre sí. GS360 no garantiza ni
            participa respecto de la veracidad, certeza ni por la
            responsabilidad de la información y contenido publicitado por el
            anunciante en el Sitio, siendo obligación exclusiva del anunciante
            respecto de su veracidad, legalidad, legitimidad y no vulneración de
            derechos patrimoniales o morales de terceros. Los sitios de terceros
            no son vinculados, operados, codificados, programados o
            administrados por GS360 y por tanto la información, contenido,
            ofertas, privacidad, términos y condiciones y políticas establecidos
            en los sitios de terceros, será de exclusiva relación entre Usted y
            el Sitio del Tercero.
          </p>
          <p>
            <b>2.10. Cookies:</b> Paquete de datos que un navegador web almacena
            de forma automática en el dispositivo electrónico del Usuario cuando
            visita nuestra página web, los cuales indican la interacción y
            trazabilidad de la experiencia virtual que Usted ha tenido en el
            Sitio y su(s) Aplicación(es), los cuales reciben y reenvían
            información sin modificar a través del uso, navegación, exploración,
            registro o acceso a nuestro Sitio para indicar que ha ocurrido un
            evento o situación especial. La cookie es enviada desde el servidor
            al visitante de la página web, para que cada vez que el usuario
            visite El Sitio, Sitio y su(s) Aplicación(es) de propiedad de GS360,
            la cookie sea leída por el navegador web, sin ser modificada, y
            devuelta al servidor web, pudiendo ser utilizada para mejorar la
            navegación en nuestro sitio web, para ofrecer un mejor servicio,
            publicidad, networking o experiencia de acuerdo con los servicios
            que ofrecemos en nuestra empresa. Usted podrá aceptar, rechazar,
            modificar o eliminar las cookies almacenadas en su dispositivo en
            cualquier momento.
          </p>
          <p>
            <b>2.11. SaaS:</b> Software as a Service. El Software as a Service o
            por sus siglas en inglés (SaaS), es la herramienta tecnológica que
            permite acceder de manera remota a sistemas operativos y
            almacenamiento en la nube que GS360 pone a disposición de los
            Usuarios a través de ciertos servicios. RocketContract es un SaaS en
            donde el Usuario tendrá el control de los documentos, firmas y
            elementos almacenados en la nube a cambio de un precio por concepto
            de licenciamiento no exclusivo del SaaS de nuestra propiedad, por
            tanto nos preocupamos por garantizar el funcionamiento, el hardware
            y el software, sus actualizaciones, funcionalidad, seguridad y
            accesibilidad de las operaciones que realicen los Usuarios en
            nuestro SaaS.
          </p>
          <p>
            <b
              style={{
                textDecoration: "underline",
                textTransform: "uppercase",
              }}
            >
              3. POLÍTICA DE TRATAMIENTO DE DATOS PERSONALES:
            </b>{" "}
            o también conocida como la Política de Privacidad de la información,
            la cual hace parte integral de los Términos y Condiciones, siendo de
            obligatorio el cumplimiento para Usuarios y Visitantes del Sitio, y
            que regula el Tratamiento de Datos Personales de acuerdo con lo
            establecido en la legislación vigente. Para conocer la política de
            tratamiento de datos personales,{" "}
            <Link
              to="/politica-de-proteccion-de-datos-personales"
              style={{ color: "#000" }}
            >
              haz click aquí
            </Link>
            .
          </p>
          <p>
            <b
              style={{
                textDecoration: "underline",
                textTransform: "uppercase",
              }}
            >
              4. ACTUALIZACIONES DEL CONTENIDO:
            </b>{" "}
            GS360 podrá realizar en cualquier momento y sin necesidad de previo
            aviso, modificar, adicionar, eliminar, suprimir, enmendar,
            transformar y en general modificar cualquier contenido, información,
            aplicación, documento, dato, testimonio, reseña, referencia o
            información contenida en el Sitio, requisitos para el registro de
            nuevos usuarios o de usuarios existentes, así como implementar
            códigos de encriptación para la validación de usuarios entre otros,
            tanto del Sitio, sus micrositios y/o aplicaciones tecnológicas de su
            propiedad. Todos los contenidos depositados en el Sitio son de
            propiedad exclusiva de GS360 salvo aquellos que le pertenezcan
            exclusivamente al Usuario a través del alojamiento de datos en
            nuestras plataformas. Para ello GS360 podrá implementar los
            procedimientos mediante programación del código fuente para mejorar
            el Sitio o su(s) Aplicaciones, los cuales podrán ser periódicos y a
            cargo de nosotros en cualquier tiempo y sin previo aviso o
            consentimiento de los Usuarios o Visitantes. Estos términos y
            condiciones no podrán ser objeto de modificación o interpretación
            por parte del Usuario en ningún momento y bajo ningún concepto.
          </p>
          <p>
            <b
              style={{
                textDecoration: "underline",
                textTransform: "uppercase",
              }}
            >
              4. ACTUALIZACIONES DEL CONTENIDO:
            </b>{" "}
            GS360 podrá realizar en cualquier momento y sin necesidad de previo
            aviso, modificar, adicionar, eliminar, suprimir, enmendar,
            transformar y en general modificar cualquier contenido, información,
            aplicación, documento, dato, testimonio, reseña, referencia o
            información contenida en el Sitio, requisitos para el registro de
            nuevos usuarios o de usuarios existentes, así como implementar
            códigos de encriptación para la validación de usuarios entre otros,
            tanto del Sitio, sus micrositios y/o aplicaciones tecnológicas de su
            propiedad. Todos los contenidos depositados en el Sitio son de
            propiedad exclusiva de GS360 salvo aquellos que le pertenezcan
            exclusivamente al Usuario a través del alojamiento de datos en
            nuestras plataformas. Para ello GS360 podrá implementar los
            procedimientos mediante programación del código fuente para mejorar
            el Sitio o su(s) Aplicaciones, los cuales podrán ser periódicos y a
            cargo de nosotros en cualquier tiempo y sin previo aviso o
            consentimiento de los Usuarios o Visitantes. Estos términos y
            condiciones no podrán ser objeto de modificación o interpretación
            por parte del Usuario en ningún momento y bajo ningún concepto.
          </p>
          <p>
            <b
              style={{
                textDecoration: "underline",
                textTransform: "uppercase",
              }}
            >
              5. INFORMACIÓN DE CONTENIDOS:
            </b>{" "}
            La información contenida o depositada en El Sitio o su(s)
            Aplicación(es)corresponde al trabajo que hemos desempeñado por
            mantener actualizada y de acuerdo con la normatividad vigente en la
            Republica de Colombia con el fin que sea correcta, sin embargo,
            Usted entiende y por tanto acepta que la información y la
            normatividad puede ser cambiante por decisiones del Gobierno
            Nacional y sus diferentes ramas del poder público, con lo cual,
            GS360 no puede garantizar la veracidad o exactitud de los contenidos
            que en virtud de las tecnologías de la información y la velocidad en
            los cambios y actualizaciones que realice terceros ajenos a nuestra
            organización por tanto, GS360 no asume ninguna responsabilidad sobre
            la veracidad, exactitud, autenticidad, realidad o fidelidad de la
            información contenida en el Sitio bien sea por cambios normativos o
            por información de terceros.
          </p>
          <p>
            <b
              style={{
                textDecoration: "underline",
                textTransform: "uppercase",
              }}
            >
              6. DISCLAIMER:
            </b>{" "}
            GS360, sus accionistas, socios, directores, empleados, agentes,
            contratistas, patrocinadores, licenciantes y en general las personas
            que por cualquier forma estén vinculados directa o indirectamente
            con su Equipo de Trabajo, no están facultados para otorga ninguna
            garantía en relación con el uso, la información o los servicios
            prestados a través de este Sitio, el cual se pone en conocimiento de
            los Usuarios en las condiciones como se encuentra con sus beneficios
            y falencias que puedan existir, sin embargo, dedicamos y trabajamos
            con el compromiso de realizar las mejores actuaciones y actividades
            de mejora continua para otorgar la mejor experiencia posible en
            nuestra página web, en el uso de nuestra tecnología tanto propia
            como contratada o subcontratada. En tal sentido, el Usuario accede
            bajo su propia cuenta y riesgo al Sitio, al su contenido y/o a sus
            aplicaciones con las limitaciones y licenciamientos establecidos en
            estos Términos y Condiciones, entendiendo que los daños o perjuicios
            que puedan derivarse o causarse a través de los medios informáticos
            de las plataformas tecnológicas, son de su entera responsabilidad.
            Con el acceso, uso, navegación, exploración, registro o acceso al
            sitio web (“Sitio”) y su contenido, por parte del Usuario, usted
            acepta mantener indemne a GS360 de cualquier reclamo, queja,
            investigación, acción legal o responsabilidad por el uso, uso
            indebido o uso malintencionado del Sitio y por tanto no podrá
            demandar, instaurar acciones judiciales o extrajudiciales ni
            reclamar perjuicios, indemnizaciones de ninguna clase como
            consecuencia de las decisiones que GS360 realice respecto de la
            administración, manejo, operación o ejecución del Sitio.
          </p>
          <p>
            <b
              style={{
                textDecoration: "underline",
                textTransform: "uppercase",
              }}
            >
              7. Interpretaciones:
            </b>{" "}
            Los presentes Términos y Condiciones establecidos en este documento
            se deberán interpretar en el sentido común y simple y de conformidad
            con la legislación aplicable y establecida en la Republica de
            Colombia.
          </p>
          <p>
            <b
              style={{
                textDecoration: "underline",
                textTransform: "uppercase",
              }}
            >
              8. Acuerdo Único:
            </b>{" "}
            Estos Términos y Condiciones constituyen el acuerdo único y especial
            para el uso navegación, exploración, registro o acceso al sitio web
            (“Sitio”) y su contenido por parte del Usuario, y por tanto,
            cualquier negociación verbal, escrita, anterior no tendrá efectos
            entre el Usuario y GS360 los cuales las partes aceptan y reconocen
            como de obligatorio cumplimiento.
          </p>
          <p>
            <b
              style={{
                textDecoration: "underline",
                textTransform: "uppercase",
              }}
            >
              9. VIGENCIA:
            </b>{" "}
            La presente política de tratamiento de datos personales y de la
            información fue deliberada, discutida y aprobada por GS360 el 17 de
            marzo de 2021 y rige a partir de su publicación en nuestros medios y
            canales oficiales de comunicación.
          </p>
        </Col>
      </Row>
    </Container>
  );
}

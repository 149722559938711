import React from "react";

/* IMG */
import iconPagoUnico from '../../../assets/img/legalManager/iconPagoUnico.png';
import iconPlantillas from '../../../assets/img/legalManager/iconPlantillas.png';
import iconIntegracionRocket from '../../../assets/img/legalManager/iconIntegracionRocket.png';
import iconCreadaPorAbogados from '../../../assets/img/legalManager/iconCreadaPorAbogados.png';
import iconAccedeDocumentos from '../../../assets/img/legalManager/iconAccedeDocumentos.png';

/*CSS*/
import "../css/LegalManager.css";

/*BOOTSTRAP*/
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default function RecursosLegalManager() {

  
  const options = [    
    { value: 1, 
      img: iconPagoUnico,  
      tit: "Pago único a un precio muy bajo"
    }, 
    { value: 2, 
      img: iconPlantillas,
      tit: "Plantillas listas para descargar"
    },
    { value: 3, 
      img: iconIntegracionRocket,  
      tit: "Integra tus plantillas con Rocket Contracts, para firmarlos digitalmente"
    },
    { value: 4, 
      img: iconCreadaPorAbogados,
      tit: "Plantillas creadas por abogados expertos"
    },
    { value: 5, 
      img: iconAccedeDocumentos,    
      tit: "Accede a tus documentos tantas veces como quieras, serán tuyos de por vida"
    }  
  ]  
 
  
  return (
    <Container className="optionsLegalManager"> 
   
          <Row>
                {options.map((item) => (           
                  <Col className="text-center mt-5">                   
                      <img src={item.img} className="mx-auto mb-1" alt={item.alt}/>      
                      <p className="text-center pt-3">{item.tit}</p>                  
                  </Col>  
                ))}
          </Row>                 
          
      </Container>  

  );
}

import React from "react";
import { Link } from "react-router-dom";

/* IMG */
import iconCalidad from "../../assets/img/proveedores/iconCalidad.png";

import "./css/Services360.css";

/*BOOTSTRAP*/
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default function Services360() {
  return (
    <Container className="mainServices360" fluid>
      <Container>
        <Row className="mt-4 pt-5">
          <Col md={7} xs={12} className="text-center mx-auto">
            <h2 className="titHero text-center pb-0">¿Cómo funciona?</h2>
          </Col>
        </Row>

        <Row className="pt-5 pb-4">
          <Col md={7} xs={12} className="text-center mx-auto">
            <p className="text-center mb-5">
              El networking es fundamental para el éxito de tu negocio y
              queremos poner a disposición de forma totalmente gratuita nuestro
              conocimiento del mercado y las relaciones que hemos desarrollado
              como emprendedores y empresarios durante muchos años.
            </p>
            <p className="text-center  mb-5">
              <b>360º Services</b>, es una plataforma de servicios brindados por
              empresas como la tuya, de mentalidad emprendedora, proactiva y de
              excelencia. Hemos seleccionado y validado cuidadosamente cada
              proveedor para que puedas acelerar las operaciones de tu empresa
              contando con lo mejor de lo mejor.
            </p>
          </Col>
        </Row>

        <Row>
          <Col md={11} xs={12} className="text-center mx-auto pt-5 mb-5">
            <div className="w-100 shadow p-4 pt-5 pb-5 border-radius-5">
              <Row>
                <Col md={2} xs={12} className="text-center mx-auto">
                  <img src={iconCalidad} alt="Calidad %100 Garantizada" />
                </Col>
                <Col md={6} xs={12}>
                  <h2 className="text-center text-sm-left text700 pt-2">
                    Calidad %100 Garantizada
                  </h2>
                  <p className="text-center text-sm-left mb-3">
                    Muchas de estas empresas incluso son proveedoras nuestras
                  </p>
                </Col>
                <Col
                  md={4}
                  xs={12}
                  className="text-center mx-auto d-flex  justify-content-center align-items-center flex-column"
                >
                  <Link
                    to="/contacto"
                    className="botones btnNaranjaNegro mx-auto mx-sm-0"
                    style={{ width: "250px", margin: "5px auto" }}
                  >
                    Ir al directorio 360º Services
                  </Link>
                  <p style={{ fontSize: "12px" }}>Es necesario registrarse</p>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

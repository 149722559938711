import React, { useState } from "react";

/* IMG */
import logoGlobalStartupFooterWhite from "../../../assets/img/logoGlobalStartupFooterWhite.png";

/*CSS*/
import "../css/Registro.css";

/*BOOTSTRAP*/
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";

export default function Ingresar(props) {
  const [value, setValue] = useState();

  return (
    <Modal
      {...props}
      dialogClassName="modal-350w modalRegLog"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header
        closeButton
        style={{
          padding: "15px",
          borderBottom: "0",
          backgroundColor: "#15385C",
        }}
      >
        <img
          src={logoGlobalStartupFooterWhite}
          alt="GS360"
          className="img-fluid"
        />
      </Modal.Header>
      <Modal.Body>
        <h4 className="text-center" style={{ color: "#0365A6" }}>
          Ingresar
        </h4>

        <Form className="text-left pt-4">
          <Form.Row>
            <Form.Group as={Col} md={12} xs={12} controlId="formGridNombre">
              <Form.Label>Correo electrónico</Form.Label>
              <Form.Control type="email" placeholder="" />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} md={12} xs={12} controlId="formGridApellido">
              <Form.Label>Contraseña</Form.Label>
              <Form.Control type="password" placeholder="" />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} md={12} xs={12} controlId="formGridApellido">
              <Form.Check
                custom
                type="checkbox"
                id={`custom-checkbox`}
                label={`Recordar mi contraseña`}
              />
            </Form.Group>
          </Form.Row>
          <button
            class="botones btnNaranja mx-auto w-100"
            type="submit"
            style={{ minWidth: "200px", margin: "5px 0 0 0" }}
          >
            Ingresar
          </button>
          <Form.Row style={{ display: "none" }}>
            <Form.Group as={Col} md={12} xs={12} controlId="formGridApellido">
              <p className="text-center pt-4 mb-0">
                No tengo una cuenta{" "}
                <span
                  style={{
                    color: "#0365A6",
                    textTransform: "uppercase",
                    fontWeight: "bold",
                  }}
                >
                  Registrarme
                </span>
              </p>
            </Form.Group>
          </Form.Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

import React from "react";

/* IMG */
import iconEficiencia from "../../../assets/img/rocketContracts/iconEficiencia.png";
import iconCumpleLegalizacion from "../../../assets/img/rocketContracts/iconCumpleLegalizacion.png";
import iconCreaPlantillas from "../../../assets/img/rocketContracts/iconCreaPlantillas.png";
import iconSeguimientoDocumentos from "../../../assets/img/rocketContracts/iconSeguimientoDocumentos.png";

/*CSS*/
import "../css/RocketContracts.css";

/*BOOTSTRAP*/
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default function ControlEnTiempoReal() {
  const options = [
    {
      value: 1,
      img: iconEficiencia,
      tit: "Aumenta tu eficiencia",
      descrip: "Disminuye costos y ahorra tiempo",
    },
    {
      value: 2,
      img: iconCumpleLegalizacion,
      tit: "Cumple con las normas de la legislación",
      descrip:
        "Cumple con las normas de la legislación Colombiana para la protección de datos y firma digital,\n Rocket Contracts te permite realizar cifrados de alta seguridad para aumentar la protección\n de tus documentos.",
    },
    {
      value: 3,
      img: iconCreaPlantillas,
      tit: "Crea plantillas personalizadas",
      descrip:
        "Úsalas cuando quieras según tus necesidades. Edítalas, adiciona o modifica su contenido\n en línea, para mayor eficiencia en tu gestión contractual.",
    },
    {
      value: 4,
      img: iconSeguimientoDocumentos,
      tit: "Seguimiento de los documentos",
      descrip:
        "Realiza seguimiento de los documentos por firmar en cada una de sus etapas.",
    },
  ];

  return (
    <Container className="heroEmpresaLista" fluid>
      <Container>
        <Row>
          <Col md={12} xs={12} className="text-center text-sm-left">
            <h2 className="titHero  text-center pb-0">
              Control en tiempo real
            </h2>

            <p
              className="heroText  text-center pt-4 mb-5"
              style={{ color: "#535353 important" }}
            >
              Edita, modifica, suprime o añade cláusulas o anexos a tus{" "}
              <br className="d-none d-sm-block" />
              contratos y controla en tiempo real el estado de cada uno de los{" "}
              <br className="d-none d-sm-block" />
              documentos.
            </p>

            {options.map((item) => (
              <Row>
                <Col md={10} xs={12} className="mx-auto">
                  <div className="w-100 shadow border-radius-5 mb-5 mt-4">
                    <Row className="listaControlEnTiempoReal">
                      <Col
                        md={2}
                        xs={12}
                        className="text-center d-flex justify-content-center flex-column"
                      >
                        <div>
                          <img
                            src={item.img}
                            className="mx-auto mb-1"
                            alt={item.alt}
                          />
                        </div>
                      </Col>
                      <Col
                        md={10}
                        xs={12}
                        className="d-flex justify-content-center flex-column"
                      >
                        <div>
                          <h2 className="text-sm-left text-center">
                            {item.tit}
                          </h2>
                          <p className="text-sm-left text-center">
                            {item.descrip}
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            ))}
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

import React, { useState } from "react";

import emailjs from "emailjs-com";
import apiKeys from "../../extra/apikeys.js";

import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { CountryDropdown } from "react-country-region-selector";

import Spin from "../../assets/img/Spin.svg";
import IMGenviadoOk from "../../assets/img/enviadoOK.png";
import IMGenviadoERROR from "../../assets/img/enviadoERROR.png";

/*COMPONENTES */

/*CSS*/
import "./css/Escribinos.css";

/*IMAGENES*/
import iconEscribinos from "../../assets/img/contacto/blue/iconEscribinos.png";

/*BOOTSTRAP*/
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";

export default function Escribinos() {
  const [value, setValue] = useState();
  const [selectedCountry, setSelectedCountry] = useState();
  const [validated, setValidated] = useState(false);

  const [status, setStatus] = useState(null);

  function refreshPage() {
    window.location.reload(false);
  }

  const onSubmit = (e) => {
    const form = e.currentTarget;
    let localValidated;
    e.preventDefault();
    if (form.checkValidity() === false) {
      localValidated = false;
    } else {
      setValidated(true);
      localValidated = true;

      if (localValidated === true) {
        setStatus("loading");

        emailjs
          .sendForm(
            "service_GS360",
            apiKeys.TEMPLATE_ID,
            e.target,
            apiKeys.USER_ID
          )

          .then(
            (result) => {
              setStatus("ok");
            },

            (error) => {
              setStatus("error");
            }
          );
      }
    }
  };

  if (status === null) {
    return (
      <Container>
        <Row>
          <Col md={6} xs={12} className="pb-2 text-center mx-auto formContacto">
            <img src={iconEscribinos} alt="LLamar" className="mx-auto mb-2" />
            <Form
              noValidate
              validated={validated}
              className="text-left mt-4 form"
              onSubmit={onSubmit}
            >
              <Form.Row>
                <Form.Group as={Col} md={6} xs={12} controlId="formGridNombre">
                  <Form.Label>Nombre (*)</Form.Label>
                  <Form.Control
                    id="name"
                    name="name"
                    type="text"
                    placeholder="Nombre"
                    required
                  />
                </Form.Group>

                <Form.Group
                  as={Col}
                  md={6}
                  xs={12}
                  controlId="formGridApellido"
                >
                  <Form.Label>Apellido (*)</Form.Label>
                  <Form.Control
                    type="text"
                    id="lastname"
                    name="lastname"
                    placeholder="Apellido"
                    required
                  />
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group as={Col} md={6} xs={12} controlId="formGridPhone">
                  <Form.Label>Celular</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="+57 XXXXXXXXXX"
                    id="phone"
                    name="phone"
                    required
                  />

                  {/*
                  <PhoneInput
                    placeholder="Teléfono"
                    id="phone"
                    name="phone"
                    value={value}
                    onChange={setValue}
                    required
                  />*/}
                </Form.Group>

                <Form.Group as={Col} md={6} xs={12} controlId="formGridEmail">
                  <Form.Label>Correo electrónico (*)</Form.Label>
                  <Form.Control
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Ingresa tu correo electrónico"
                    required
                  />
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group
                  as={Col}
                  md={6}
                  xs={12}
                  controlId="formGridAddress1"
                >
                  <Form.Label>Pais (*)</Form.Label>
                  <CountryDropdown
                    value={selectedCountry}
                    onChange={(val) => setSelectedCountry(val)}
                    classes="form-control"
                    defaultOptionLabel="Seleccione..."
                    id="country"
                    name="country"
                    required
                  />
                </Form.Group>
                <Form.Group as={Col} md={6} xs={12} controlId="formGridEmail">
                  <Form.Label>Servicio de interés (*)</Form.Label>
                  <Form.Control
                    as="select"
                    id="services"
                    name="services"
                    custom
                    required
                  >
                    <option>Sas Wizard</option>
                    <option>Rocket Contracts</option>
                    <option>Brandineitor</option>
                    <option>Mr. Bill's</option>
                    <option>Legal Manager</option>
                    <option>GS 360</option>
                  </Form.Control>
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group
                  as={Col}
                  md={12}
                  xs={12}
                  controlId="formGridAddress1"
                >
                  <Form.Label>Mensaje</Form.Label>
                  <Form.Control
                    as="textarea"
                    id="message"
                    name="message"
                    rows={3}
                  />
                </Form.Group>
              </Form.Row>

              <button
                class="botones btnNaranja mx-auto"
                type="submit"
                style={{ minWidth: "200px", margin: "5px 0 0 0" }}
              >
                Enviar mensaje
              </button>
            </Form>
          </Col>
        </Row>
      </Container>
    );
  } else if (status === "loading") {
    return (
      <Container>
        <Row>
          <Col md={12} className="text-center d-inline w-100  pt-5 pb-5">
            <img src={Spin} alt="Loading" className="mx-auto mb-2" />
            <h4 style={{ fontSize: "18px", textAlign: "center" }}>
              Enviando Mensaje...
            </h4>
          </Col>
        </Row>
      </Container>
    );
  } else if (status === "ok") {
    return (
      <Container>
        <Row>
          <Col md={12} className="text-center d-inline w-100  pt-5 pb-5">
            <img src={IMGenviadoOk} alt="Loading" className="mx-auto mb-2" />
            <h4 style={{ fontSize: "18px", textAlign: "center" }}>
              Su mensaje fue enviado correctamente
            </h4>
            <button
              onClick={() => refreshPage()}
              className="botones btnNaranja mx-auto mx-sm-0 "
              style={{ width: "120px", margin: "0" }}
            >
              Continuar
            </button>
          </Col>
        </Row>
      </Container>
    );
  } else if (status === "error") {
    return (
      <Container>
        <Row>
          <Col md={12} className="text-center d-inline w-100  pt-05 pb-5">
            <img src={IMGenviadoERROR} alt="Loading" className="mx-auto mb-2" />
            <h4 style={{ fontSize: "18px", textAlign: "center" }}>
              Se ha producido un error al enviar su consulta
            </h4>
          </Col>
        </Row>
      </Container>
    );
  }
}

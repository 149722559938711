import React from "react";
import Faq from "react-faq-component";

/*COMPONENTES */
import { BotonAzulOutline } from "../Botones";

/*CSS*/
import "./css/Faqs.css";

/*IMAGENES*/
import iconFaqs from "../../assets/img/home/iconFaqs.svg";

/*BOOTSTRAP*/
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function Faqs() {
  const preguntasGenerales = {
    rows: [
      {
        title: "¿Cómo creo mi cuenta?",
        content:
          "GS360 se encuentra en etapa beta y estamos aprobando las cuentas manualmente para asegurarnos que puedas tener la mejor experiencia posible. En el menú superior encontrarás un botón azul (Comenzar), ingresa y completa  tus datos, nos contactaremos contigo a la brevedad.",
      },
      {
        title: "¿Cuáles son los medios de pago disponibles?",
        content:
          "Puedes pagar con todas las tarjetas, PayPal y transferencia bancaria.",
      },
      {
        title: "¿Cómo se protegen mis datos?",
        content:
          "La seguridad de los <a href='/politica-de-proteccion-de-datos-personales' class='linkFAQ'>datos personales</a> y la información de nuestra plataforma es uno de los elementos en donde más invertimos. Un gran porcentaje de nuestro presupuesto y tiempo va dedicado a proteger los datos con sofisticados sistemas que encriptan y resguarda la información contra posibles ataques.",
      },
    ],
  };

  const stylesFaqs = {
    bgColor: "transparent",
    titleTextColor: "#2C2F3C",
    rowTitleColor: "#2C2F3C",
    arrowColor: "#07A4A8",
  };

  return (
    <Container fluid className="bgFaqs" id="faqs">
      <Container>
        <Row>
          <Col md={5} xs={12} className="pb-5 text-center text-sm-left">
            <img src={iconFaqs} alt="FAQS" style={{ maxWidth: "50px" }} />
            <h3 className="titFaq text-sm-left text-center pt-2 pb-3">
              ¿Tienes dudas?
            </h3>
            <p className="subTitFaq mb-4 pb-2 text-sm-left text-center">
              Estamos para ayudarte <br className="d-none d-sm-block" />
              en tu próximo negocio
            </p>
            <BotonAzulOutline link="/faqs" texto="Ver más" />
          </Col>
          <Col
            md={7}
            xs={12}
            className="d-flex justify-content-center flex-column"
          >
            <Faq data={preguntasGenerales} styles={stylesFaqs} />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Faqs;

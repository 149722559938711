import React from "react";
import { Link } from "react-router-dom";

import ModalLlamada from "../ModalLlamada";

/* IMG */
import iconLlamar from "../../../assets/img/contacto/iconLlamar.png";
import iconChat from "../../../assets/img/contacto/iconChat.png";
import iconEscribinos from "../../../assets/img/contacto/iconEscribinos.png";

/*CSS*/
import "../css/Contacto.css";

/*BOOTSTRAP*/
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default function BoxesContacto({ clase }) {
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <Container>
      <Row className={clase}>
        <Col md={4} xs={12} className="text-center boxContacto">
          <div className="fndBoxContacto">
            <img src={iconLlamar} alt="LLamar" />

            <h6 className="pb-3 pt-2">Llámanos +57 302 864 6683</h6>

            <p className="pb-3">
              Puede llamar a nuestro equipo durante nuestro horario comercial:
            </p>

            <p className="pb-3">
              <b>Lunes a Viernes</b>
              <br></br> de 9 AM a 5 PM (GMT-5)
            </p>

            <button
              className="linkPedirContacto"
              onClick={() => setModalShow(true)}
            >
              Pedir que me llamen
            </button>
          </div>
        </Col>
        <Col md={4} xs={12} className="text-center boxContacto">
          <div className="fndBoxContacto">
            <img src={iconChat} alt="LLamar" />

            <h6 className="pb-3 pt-2">Chatea por Whatsapp</h6>

            <p className="pb-3">
              Tienes dudas?, no sabes como empezar?, contactanos por Whatsapp!
            </p>

            <p className="pb-3">
              <b>Lunes a Viernes</b>
              <br></br> de 9 AM a 5 PM (GMT-5)
            </p>



            <Link to={{ pathname: "https://wa.me/+573028646683" }} target="_blank" className="linkPedirContacto">Ir a Whatsapp</Link>

            

          </div>
        </Col>
        <Col md={4} xs={12} className="text-center boxContacto">
          <div className="fndBoxContacto">
            <img src={iconEscribinos} alt="LLamar" />

            <h6 className="pb-3 pt-2">Escríbenos</h6>

            <p className="pb-3">
              Envíe un correo electrónico con sus preguntas a nuestro equipo y
              le responderemos dentro de 1 día hábil.
            </p>

            <Link to="/contacto/escribinos" className="linkPedirContacto">
              Enviar mensaje
            </Link>
          </div>
        </Col>
      </Row>

      <ModalLlamada show={modalShow} onHide={() => setModalShow(false)} />
    </Container>
  );
}

import React from "react";
import { Link } from "react-scroll";

/* IMG */
import iconMrBillsContabiliza from "../../../assets/img/mrBill/iconContabiliza.png";
import iconAhorreTiempo from "../../../assets/img/mrBill/iconTiempo.png";
import iconStaffContabilidad from "../../../assets/img/mrBill/iconStaffContabilidad.png";
import iconPresentacionReportes from "../../../assets/img/mrBill/iconReportes.png";
import iconReportesClaros from "../../../assets/img/mrBill/iconReportesC.png";
import iconComunicacionPermanente from "../../../assets/img/mrBill/iconComunicacion.png";

/*CSS*/
import "../css/MrBills.css";

/*BOOTSTRAP*/
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default function VentajasMrBills() {
  const options = [
    {
      value: 1,
      img: iconMrBillsContabiliza,
      tit:
        "Outsourcing operativo de la contabilidad. Dedícate a crear, Mr Bill's lo contabiliza.",
    },
    { value: 2, img: iconAhorreTiempo, tit: "Ahorro de tiempo" },
    {
      value: 3,
      img: iconStaffContabilidad,
      tit:
        "La contabilidad de tu negocio con el respaldo de un equipo de expertos. ",
    },
    {
      value: 4,
      img: iconPresentacionReportes,
      tit:
        "Preparación, reporte y presentación de impuestos ante las autoridades.",
    },
    {
      value: 5,
      img: iconReportesClaros,
      tit:
        "Reportes intuitivos, claros y en linea gracias a nuestro proveedor de software contable en la nube",
    },
    {
      value: 6,
      img: iconComunicacionPermanente,
      tit: "Comunicación permanente",
    },
  ];

  return (
    <Container className="inicioMrBills" fluid>
      <Container>
        <Row className="mt-4 pt-5 pb-4">
          <Col md={7} xs={12} className="text-center mx-auto">
            <h2 className="subtitHero text-center pb-0">
              ¡Mr Bill's lo hace todo por ti!
            </h2>
          </Col>
        </Row>

        <Row>
          {options.map((item) => (
            <Col md={4} xs={12} className="text-center mt-5">
              <img src={item.img} className="mx-auto mb-1" alt={item.alt} />
              <p className="text-center">{item.tit}</p>
            </Col>
          ))}
        </Row>

        <Row>
          <Col md={12} xs={12} className="mx-auto mt-5 mb-5">
            <div className="w-100 shadow p-4 pt-4 pb-5 border-radius-5">
              <Row>
                <Col md={11} xs={12} className="mx-auto">
                  <Row>
                    <Col md={9} xs={12}>
                      <h2 className="titComiezaMrBills text-sm-left text-center pt-4">
                        Cuéntanos qué haces y nosotros lo registramos.{" "}
                      </h2>
                      <p className="text-sm-left text-center mb-0">
                        Al involucrarnos y entender las actividades
                        empresariales de nuestros clientes, disminuimos los
                        riesgos y errores en registros contables porque
                        entendemos el core de tu negocio.{" "}
                      </p>
                    </Col>
                    <Col
                      md={3}
                      xs={12}
                      className="text-center mx-auto d-flex justify-content-sm-end justify-content-center align-items-center"
                    >
                      <div>
                        <Link
                          to="IDpricesMrBill"
                          smooth={true}
                          className="botones btnVioletaInverse mx-auto mx-sm-0"
                          style={{
                            width: "150px",
                            margin: "15px 0 0 0",
                            cursor: "pointer",
                          }}
                        >
                          Comienza Ahora
                        </Link>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

import React, { useState } from "react";

import ModalContacto from "../../Contacto/ModalContacto";

/*CSS*/
import "../css/MrBills.css";

/*BOOTSTRAP*/
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";

export default function PlanesMrBills() {
  const options = [
    { value: 1, tit: "Contabilidad General bajo Normas NIIF" },
    { value: 2, tit: "Reportes y Soportes Contables" },
    {
      value: 3,
      tit: "Clasificación, Registro y Control de las operaciones económicas de la compañía.",
    },
    { value: 4, tit: "Conciliaciones Bancarias." },
    {
      value: 5,
      tit: "Elaboración de Estado de Situación Financiera Anual para la DIAN (Requiere haber tenido Mr Bill´s durante todo el año fiscal.)",
    },
  ];

  const precioBase = 840000;

  const optionsToAdd = [
    {
      id: 1,
      titulo: "Pack de 5 transacciones adicionales",
      precio: 95000,
    },
    {
      id: 2,
      titulo: "Generación de facturas (Hasta 10 facturas)",
      precio: 350000,
    },
    {
      id: 3,
      titulo: "Recepción y pago de facturas de proveedores (Hasta 10 facturas)",
      precio: 80000,
    },
    {
      id: 4,
      titulo: "Nomina x 1 Empleado",
      precio: 188000,
    },
    {
      id: 5,
      titulo: "Nomina Hasta 5 Empleados",
      precio: 325000,
    },
  ];

  const [modalContactoShow, setModalContactoShow] = useState(false);

  const initialProd = [
    { id: 0, titulo: "Mr Bill’s Concierge", precio: precioBase },
  ];

  const [mostrarTodos, setMostrarTodos] = useState(false);
  const [selectedItems, setSelectedItems] = useState(initialProd);
  const [totalPrice, setTotalPrice] = useState(precioBase);

  function handleRemove(id, precio) {
    //console.log(id);
    const newList = selectedItems.filter((item) => item.id !== id);
    setSelectedItems(newList);
    setTotalPrice(totalPrice - precio);
  }

  function handleAdd(id, titulo, precio) {
    //console.log(id, titulo, precio);
    let seleccionados = [...selectedItems];
    // Add item to it
    seleccionados.push({ id: id, titulo: titulo, precio: precio });
    // Set state
    setSelectedItems(seleccionados);
    setTotalPrice(totalPrice + precio);
  }

  //console.log(selectedItems);
  return (
    <Container className="bgMrBillsPlanes" fluid name="IDpricesMrBill">
      <Row>
        <Col
          md={8}
          xs={12}
          className="text-center text-sm-left mx-auto planesMrBillWrap"
        >
          <h2 className="text-center pb-5 titMrBillsPlanes">
            Un plan pensado para tu necesidad
          </h2>

          <Row className="mb-4">
            <Col md={11} xs={12} className="mx-auto">
              <Row>
                <Col md={12} xs={12}>
                  <h3 className="titConciergeMrBills">Mr Bill’s Concierge</h3>
                </Col>
              </Row>
              <Card>
                <Card.Body
                  style={{
                    backgroundColor: `${mostrarTodos ? "#ffeeac" : "#FFF"}`,
                  }}
                >
                  <Row>
                    <Col md={11} xs={10} className="mx-auto pt-3">
                      <p style={{ fontSize: "14px" }}>
                        A) Plan todo incluido para empresas que recién
                        comienzan, con hasta 20 transacciones (ingresos/egresos)
                        mensuales.
                      </p>
                    </Col>
                    <Col md={11} xs={10} className="mx-auto">
                      <Row className="list-unstyled listaMrBill mt-3 mb-3">
                        {options.map((item) => (
                          <Col className={`col-md-${item.col} col-12`}>
                            <p>{item.tit}</p>
                          </Col>
                        ))}
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={11} xs={12} className="mx-auto">
                      <Row>
                        <Col
                          md={9}
                          xs={12}
                          className="text-sm-left text-center"
                        >
                          <p className="precioOpcionBill">
                            <b>$ {precioBase.toLocaleString("de-DE")}</b>
                            <span> IVA Incluido/mes</span>
                          </p>
                        </Col>
                        <Col md={3} xs={12} className="">
                          {!mostrarTodos ? (
                            <button
                              className="botones btnVioletaInverse mx-auto mx-sm-0"
                              style={{ width: "130px", margin: "0" }}
                              onClick={() => setMostrarTodos(true)}
                            >
                              Agregar
                            </button>
                          ) : (
                            <button
                              className="botones btnVioletaInverse mx-auto mx-sm-0"
                              style={{ width: "130px", margin: "0" }}
                              onClick={() => setMostrarTodos(false)}
                            >
                              Quitar
                            </button>
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          {mostrarTodos ? (
            <>
              {optionsToAdd.map((item) => (
                <Row className="mb-4">
                  <Col md={11} xs={12} className="mx-auto ">
                    <Card>
                      <Card.Body
                        style={{
                          paddingBottom: "20px",
                          borderTopLeftRadius: "10px",
                          borderTopRightRadius: "10px",
                          backgroundColor: `${
                            selectedItems.some((x) => x.id === item.id)
                              ? "#ffeeac"
                              : "#FFF"
                          }`,
                        }}
                      >
                        <Row>
                          <Col md={11} xs={12} className="mx-auto">
                            <Row>
                              <Col
                                md={9}
                                xs={12}
                                className="text-sm-left text-center"
                              >
                                <p className="precioOpcionBill">
                                  {item.titulo}
                                </p>
                                <p className="precioOpcionBill">
                                  <b>$ {item.precio.toLocaleString("de-DE")}</b>
                                  <span> IVA Incluido /mes</span>
                                </p>
                              </Col>
                              <Col md={3} xs={12} className="">
                                {selectedItems.some((x) => x.id === item.id) ? (
                                  <button
                                    className="botones btnVioletaInverse active mx-auto mx-sm-0"
                                    style={{ width: "130px", margin: "0" }}
                                    onClick={() =>
                                      handleRemove(item.id, item.precio)
                                    }
                                  >
                                    Quitar
                                  </button>
                                ) : (
                                  <button
                                    className="botones btnVioletaInverse mx-auto mx-sm-0"
                                    style={{ width: "130px", margin: "0" }}
                                    onClick={() =>
                                      handleAdd(
                                        item.id,
                                        item.titulo,
                                        item.precio
                                      )
                                    }
                                  >
                                    Agregar
                                  </button>
                                )}
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              ))}

              <Row className="mb-4">
                <Col md={11} xs={12} className="mx-auto ">
                  <Card>
                    <Card.Body
                      style={{
                        paddingBottom: "20px",
                        borderTopLeftRadius: "10px",
                        borderTopRightRadius: "10px",
                      }}
                    >
                      <Row>
                        <Col md={11} xs={12} className="mx-auto">
                          <Row>
                            <Col
                              md={9}
                              xs={12}
                              className="text-sm-left text-center d-flex justify-content-center flex-column align-content-center"
                            >
                              <p className="precioOpcionBill">
                                Total mensual:{" "}
                                <b>$ {totalPrice.toLocaleString("de-DE")}</b>
                                <span> IVA Incluido / Mes</span>
                              </p>
                            </Col>
                            <Col
                              md={3}
                              xs={12}
                              className="d-flex justify-content-center flex-column align-content-center"
                            >
                              <button
                                className="botones btnNaranja mx-auto mx-sm-0"
                                style={{ width: "130px", margin: "0" }}
                                onClick={() => setModalContactoShow(true)}
                              >
                                Continuar
                              </button>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </>
          ) : null}
        </Col>
      </Row>
      <ModalContacto
        datos={selectedItems}
        total={totalPrice}
        show={modalContactoShow}
        onHide={() => setModalContactoShow(false)}
      />
    </Container>
  );
}

import React, { useState, useEffect } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";

/*IMAGENES*/
import Spin from "../../../assets/img/Spin.svg";
import IMGenviadoOk from "../../../assets/img/enviadoOK.png";
import IMGenviadoERROR from "../../../assets/img/enviadoERROR.png";

/*COMPONENTES */

/*CSS*/
import "./css/FinalizarRegistro.css";

/*BOOTSTRAP*/
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

export default function FinalizarRegistro(props) {
  const authResult = new URLSearchParams(window.location.search);
  const leadid = authResult ? authResult.get("leadid") : null;

  const [modalShow, setModalShow] = useState(false);
  const [status, setStatus] = useState(null);
  const [mensaje, setMensaje] = useState(null);

  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [countrySelected, setCountrySelected] = useState(null);

  //TRAIGO LOS PAISES
  useEffect(() => {
    const getCountries = () => {
      return axios
        .get("https://api.globalstartup360.com/api/countries")
        .then(function (response) {
          //console.log(response.data.data);
          setCountries(response.data.data);
        })
        .catch(function (error) {
          //console.log(error.data.data);
        });
    };
    getCountries();
  }, []);

  //TRAIGO LAS CIUDADES
  // CADA VEZ QUE CAMBIA COUNTRY EJECUTO ESTO
  const handleCountryChange = (e) => {
    setCountrySelected(e.target.value);
  };

  //TRAIGO LAS CIUDADES CUANDO COUNTRYSELECTED TIENE ALGO
  useEffect(() => {
    const getCities = () => {
      return axios
        .get(`https://api.globalstartup360.com/api/cities/${countrySelected}`)
        .then(function (response) {
          //console.log(response.data.data);
          setCities(response.data.data);
        })
        .catch(function (error) {
          //console.log(error.data.data);
        });
    };
    getCities();
  }, [countrySelected]);

  // FORMULARIO

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  //const watchNombre = watch("name");
  const watchNombre = "Octavio";
  //console.log(watchNombre);

  const onSubmit = (data) => {
    //console.log(data);

    setModalShow(true);
    setStatus("loading");

    axios
      .post("https://api.globalstartup360.com/api/lead_complete", data)

      .then(function (response) {
        setModalShow(true);
        localStorage.setItem("nombreRegistrado", data.name);
        setStatus("ok");
      })
      .catch(function (error) {
        setModalShow(true);
        setStatus("error");
        setMensaje(error.response.data.message);
      });
  };

  //console.log(watch("country"));
  //console.log(watch("passcheck"));

  return (
    <Container>
      <Row>
        <Col md={6} xs={12} className="pb-2 text-center mx-auto formContacto">
          <Form
            onSubmit={handleSubmit(onSubmit)}
            className="text-left mt-4 form"
          >
            <Form.Row>
              <Form.Group as={Col} md={6} xs={12} controlId="formGridNombre">
                <Form.Label>Nombre (*)</Form.Label>
                <Form.Control
                  id="name"
                  type="text"
                  placeholder=""
                  {...register("name", {
                    required: true,
                  })}
                />
                {errors.name && (
                  <span style={{ fontSize: "12px" }}>Campo obligatorio</span>
                )}
              </Form.Group>

              <Form.Group as={Col} md={6} xs={12} controlId="formGridApellido">
                <Form.Label>Apellido (*)</Form.Label>
                <Form.Control
                  type="text"
                  name="lastname"
                  placeholder=""
                  {...register("lastname", {
                    required: true,
                  })}
                />
                {errors.lastname && (
                  <span style={{ fontSize: "12px" }}>Campo obligatorio</span>
                )}
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} md={9} xs={12} controlId="formGridNombre">
                <Form.Label>Dirección (*)</Form.Label>
                <Form.Control
                  name="direccion"
                  type="text"
                  placeholder=""
                  {...register("address", {
                    required: true,
                  })}
                />
                {errors.address && (
                  <span style={{ fontSize: "12px" }}>Campo obligatorio</span>
                )}
              </Form.Group>

              <Form.Group as={Col} md={3} xs={12}>
                <Form.Label>Código postal</Form.Label>
                <Form.Control
                  type="text"
                  name="cp"
                  placeholder=""
                  {...register("zipcode", {})}
                />
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} md={6} xs={12} controlId="formGridAddress1">
                <Form.Label>Pais (*)</Form.Label>

                {countries.length > 0 && (
                  <>
                    <Form.Control
                      as="select"
                      name="country"
                      {...register("country", {
                        required: true,
                      })}
                      onChange={handleCountryChange}
                    >
                      <option value="0">- Seleccione un país -</option>
                      {countries.map((pais) => (
                        <option key={pais.id} value={pais.id}>
                          {pais.name}
                        </option>
                      ))}
                      ;
                    </Form.Control>
                    {errors.country && (
                      <span style={{ fontSize: "12px" }}>
                        Campo obligatorio
                      </span>
                    )}
                  </>
                )}
              </Form.Group>
              <Form.Group as={Col} md={6} xs={12} controlId="formGridNombre">
                {cities.length > 0 && (
                  <>
                    <Form.Label>Ciudad (*)</Form.Label>
                    <Form.Control
                      as="select"
                      name="cityid"
                      {...register("cityid", {
                        required: true,
                      })}
                    >
                      <option value="0">- Seleccione una ciudad -</option>
                      {cities.map((city) => (
                        <option key={city.id} value={city.id}>
                          {city.name}
                        </option>
                      ))}
                      ;
                    </Form.Control>
                    {errors.cityid && (
                      <span style={{ fontSize: "12px" }}>
                        Campo obligatorio
                      </span>
                    )}
                  </>
                )}
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} md={12} xs={12} controlId="formGridNombre">
                <Form.Label>Empresa/Nombre emprendimiento</Form.Label>
                <Form.Control
                  id="emprendimiento"
                  name="emprendimiento"
                  type="text"
                  placeholder=""
                  {...register("companyname", {
                    required: true,
                  })}
                />
                {errors.companyname && (
                  <span style={{ fontSize: "12px" }}>Campo obligatorio</span>
                )}
              </Form.Group>
            </Form.Row>

            <input
              hidden
              value={leadid}
              {...register("leadid", {
                required: true,
              })}
            />
            {errors.leadid && (
              <span style={{ fontSize: "12px" }}>
                No existe el ID de su registro
              </span>
            )}

            <button
              class="botones btnNaranja mx-auto"
              type="submit"
              style={{ minWidth: "200px", margin: "5px 0 0 0" }}
            >
              Continuar
            </button>
          </Form>
        </Col>
      </Row>

      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        dialogClassName="modal-600w"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header
          closeButton
          style={{ padding: "15px 15px 0 0", borderBottom: "0" }}
        ></Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col md={12} className="text-center d-inline w-100  pt-0 pb-5">
                {status === "loading" && (
                  <>
                    <img src={Spin} alt="Loading" className="mx-auto mb-2" />
                    <h4 style={{ fontSize: "18px", textAlign: "center" }}>
                      Enviando sus datos
                    </h4>
                  </>
                )}

                {status === "ok" && (
                  <>
                    <img
                      src={IMGenviadoOk}
                      alt="Loading"
                      className="mx-auto mb-2"
                    />
                    <h4 style={{ fontSize: "18px", textAlign: "center" }}>
                      Se ha registrado correctamente.
                    </h4>
                    <Link to="/bienvenido" style={{ textDecoration: "none" }}>
                      <button
                        class="botones btnNaranja mx-auto"
                        type="submit"
                        style={{ minWidth: "200px", margin: "25px 0 0 0" }}
                      >
                        Continuar
                      </button>
                    </Link>
                  </>
                )}

                {status === "error" && (
                  <>
                    <img
                      src={IMGenviadoERROR}
                      alt="Loading"
                      className="mx-auto mb-2"
                    />
                    <h4 style={{ fontSize: "18px", textAlign: "center" }}>
                      {mensaje}
                    </h4>
                  </>
                )}
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    </Container>
  );
}

import React from "react";

/* IMG */
import imgCostos from "../../assets/img/brandineitor/imgCostos.png";
import imgInformes from "../../assets/img/brandineitor/imgInformes.png";
import imgAdicionales from "../../assets/img/brandineitor/imgAdicionales.png";
import Garantizado100 from "../../assets/img/brandineitor/Garantizado100.png";

import "./css/Brandineitor.css";

/*BOOTSTRAP*/
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default function MainBrandineitor() {
  const options = [
    { value: 1, tit: "Lemas comerciales" },
    { value: 2, tit: "Propiedad intelectual." },
    { value: 3, tit: "Nombre comercial" },
    { value: 4, tit: "Registro de software." },
    { value: 5, tit: "Patentes." },
  ];

  return (
    <Container className="mainBrandineitor">
      <Row className="mb-4">
        <Col md={9} xs={12} className="text-center mx-auto">
          <h2 className="subtitHero text-center pb-0 mb-0">
            La esencia de tu empresa, tu marca.
          </h2>
        </Col>
      </Row>
      <Row>
        <Col md={7} xs={12} className="text-center mx-auto">
          <p className="text-center">
            Una marca te permite distinguir los productos (bienes o servicios)
            que ofreces a tus clientes, altamente valorable para tu negocio,
            siempre y cuando tenga el estatus de marca reconocido por la
            correspondiente autoridad en Colombia.
          </p>
          <p className="text-center pt-4">
            Todo tu esfuerzo en generar identidad, recordación y posicionamiento
            en tus clientes, hacen que confíen en tí, no permitas que otros
            puedan aprovecharse de esto y tomar ventaja, haz que tu marca sea
            invencible!
          </p>
        </Col>
      </Row>

      <Row className="mb-5 pb-4">
        <Col md={4} xs={8} className="mx-auto">
          <img
            src={imgCostos}
            alt="Costos Transparentes"
            className="img-fluid"
          />
        </Col>
        <Col
          md={8}
          xs={12}
          className="d-flex justify-content-center align-items-center"
        >
          <div className="text-sm-left text-center">
            <h4 className="subtitHero text-sm-left text-center pb-0">
              Costos Transparentes
            </h4>
            <h6 className="text-sm-left text-center pt-2">
              Sin extras ni cobros adicionales a precios exactos y justos para
              ti como entrepreneur. En Brandineitor todos los costos están
              incluidos en nuestro servicio… y si es low-cost!. El nivel de
              servicio es la prioridad, no te arrepentirás.
            </h6>
            <img
              src={Garantizado100}
              alt="100% Garantizado"
              className="img-fluid mt-4"
            />
          </div>
        </Col>
      </Row>

      <Row className="mb-5 pb-4">
        <Col
          md={8}
          xs={12}
          className="d-flex justify-content-center align-items-center order-1 order-sm-0"
        >
          <div>
            <h4 className="subtitHero text-sm-left text-center pb-0">
              Informe y asesoría gratis
            </h4>
            <h6 className="text-sm-left text-center pt-2">
              Solicita nuestro informe gratuito de análisis de marca, te
              ayudaremos a tomar las mejores decisiones. Brandineitor será el
              guardián de tu marca por los próximos 10 años, vigilando y
              protegiendo de posibles plagios.
            </h6>
          </div>
        </Col>
        <Col md={4} xs={8} className="mx-auto order-0 order-sm-1">
          <img
            src={imgInformes}
            alt="Informe y asesoría gratis"
            className="img-fluid"
          />
        </Col>
      </Row>

      <Row className="pb-4">
        <Col md={4} xs={8} className="mx-auto">
          <img
            src={imgAdicionales}
            alt="Servicios adicionales"
            className="img-fluid"
          />
        </Col>
        <Col
          md={8}
          xs={12}
          className="d-flex justify-content-center align-items-center"
        >
          <div>
            <h4 className="subtitHero text-sm-left text-center pb-0">
              Servicios adicionales
            </h4>
            <h6 className="text-sm-left text-center pt-2">
              Braindineitor te apoya no solo en proteger tu marca, sino en
              cuidar todo lo más valioso para tu empresa.
            </h6>
            <Row>
              <Col md={12} xs={8} className="mx-auto">
                <Row className="list-unstyled listaBrandineitor mt-4 mb-5">
                  {options.map((item) => (
                    <Col className="col-md-6 col-12">
                      <p>{item.tit}</p>
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

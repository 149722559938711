import React, { useState } from "react";

import ModalContacto from "../Contacto/ModalContacto";

/* IMG */
import bgRocketContracts from "../../assets/img/home/bgRocketContracts.svg";
import tagRocketContracts from "../../assets/img/home/tagRocketContracts.svg";

/*CSS*/
import "./css/RocketContracts.css";

/*BOOTSTRAP*/
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function Hero() {
  const [modalContactoShow, setModalContactoShow] = useState(false);

  const selectedItems = [{ id: 0, titulo: "RocketContracts", precio: 0.25 }];
  const totalPrice = 0.25;

  const options = [
    {
      value: 1,
      tit: "Disminuye costos y ahorra tiempo mediante la firma digital.",
    },
    { value: 2, tit: "Lleva seguimiento de cada uno de tus documentos." },
    {
      value: 3,
      tit: "Crea plantillas que podrás usar las veces que necesites.",
    },
    { value: 4, tit: "Cuida el medio ambiente, ahorra papel." },
    {
      value: 5,
      tit:
        "Apoya las nuevas medidas de bioseguridad al poder realizar todo de forma remota.",
    },
    {
      value: 6,
      tit:
        "Incluye hasta 5 documentos mensuales para firmar electrónicamente.",
    },
  ];

  return (
    <Container className="rocketContracts" fluid>
      <Row>
        <Col md={5} xs={12} className="d-none d-sm-block" />

        <Col md={7} xs={12} className="text-center text-sm-left">
          <img
            src={tagRocketContracts}
            className="img-fluid mb-4 mx-auto mx-sm-0"
            alt="Rocket Contracts"
          />

          <h2 className="subtitHero text-sm-left text-center pb-0">
            Todos tus contratos,
          </h2>
          <h1 className="titHero text-sm-left text-center">en un solo lugar</h1>
          <p className="heroText text-sm-left text-center pt-4">
            Crea contratos, organízalos por categorías,
            <br className="d-sm-block d-none" />
            envíalos uno a uno o masivamente a cientos de
            <br className="d-sm-block d-none" />
            personas para su firma.
          </p>

          <Row>
            <Col md={12} sm={10} xs={10} className="mx-auto mx-sm-0">
              <ul className="list-unstyled listaRocket mt-4 mb-5">
                {options.map((item) => (
                  <li>
                    <p>{item.tit}</p>
                  </li>
                ))}
              </ul>
            </Col>
          </Row>
          <Row>
            <Col md={3} sm={6} xs={12}>
              <button
                onClick={() => setModalContactoShow(true)}
                className="botones btnAzul mx-auto mx-sm-0"
                style={{ width: "170px", margin: "0" }}
              >
                Comienza Ahora
              </button>
            </Col>
            <Col md={8} sm={6} xs={12}>
              <p className="precioPorContrato text-center text-sm-left">
                Proximamente...
              </p>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="d-block d-sm-none mt-5">
        <Col xs={12} className="p-0">
          <img
            src={bgRocketContracts}
            className="img-fluid"
            alt="GS360 Rocket Contracts"
          />
        </Col>
      </Row>
      <ModalContacto
        datos={selectedItems}
        total={totalPrice}
        show={modalContactoShow}
        onHide={() => setModalContactoShow(false)}
      />
    </Container>
  );
}

export default Hero;

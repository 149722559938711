import React, { useState } from "react";

import ModalProveedores from "../ModalProveedores";

import imgLogo from "../../../assets/img/proveedores/imgLogo.png";

/*CSS*/
import "../css/Proveedores.css";

/*BOOTSTRAP*/
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Pagination from "react-bootstrap/Pagination";

export default function ResultadosProveedores() {
  const [modalProveedoresShow, setModalProveedoresShow] = useState(false);
  const [modalProveedoresDatos, setModalProveedoresDatos] = useState(false);

  const handleClick = (datos) => {
    setModalProveedoresShow(true);
    setModalProveedoresDatos(datos);
  };

  let active = 2;
  let items = [];
  for (let number = 1; number <= 5; number++) {
    items.push(
      <Pagination.Item key={number} active={number === active}>
        {number}
      </Pagination.Item>
    );
  }

  const options = [
    {
      value: 1,
      img: imgLogo,
      tit: "Nombre del proveedor",
      verificado: true,
      descrip:
        "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim",
    },
    {
      value: 2,
      img: imgLogo,
      tit: "Nombre del proveedor",
      verificado: false,
      descrip:
        "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim",
    },
    {
      value: 3,
      img: imgLogo,
      tit: "Nombre del proveedor",
      verificado: true,
      descrip:
        "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim",
    },
    {
      value: 4,
      img: imgLogo,
      tit: "Nombre del proveedor",
      verificado: true,
      descrip:
        "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim",
    },
    {
      value: 5,
      img: imgLogo,
      tit: "Nombre del proveedor",
      verificado: false,
      descrip:
        "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim",
    },
    {
      value: 6,
      img: imgLogo,
      tit: "Nombre del proveedor",
      verificado: true,
      descrip:
        "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim",
    },
  ];

  return (
    <Container>
      <Row>
        <Col md={12} xs={12}>
          {options.map((item) => (
            <div className="borderProveedor" key={item.value}>
              <Row className="resultadoProveedor">
                <Col md={2} xs={12} className="text-center text-sm-left">
                  <img src={item.img} alt="" />
                </Col>
                <Col md={7} xs={12}>
                  <Row>
                    <Col className="pb-1 pt-1">
                      <h5>
                        {item.tit}
                        {item.verificado ? <span>Verificado</span> : ""}
                      </h5>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pb-2">
                      <p className="textoProveedores">{item.descrip}</p>
                    </Col>
                  </Row>
                </Col>
                <Col md={3} xs={12} className="d-flex align-items-center">
                  <button
                    className="botones btnOutlineNegro mx-auto"
                    style={{ width: "200px", margin: "15px 0 0 0" }}
                    onClick={() => handleClick(item)}
                  >
                    Ver datos de contacto
                  </button>
                </Col>
              </Row>
            </div>
          ))}
        </Col>
      </Row>

      <Row>
        <Col md={12}>
          <Pagination className="paginationProv">{items}</Pagination>
        </Col>
      </Row>

      <ModalProveedores
        show={modalProveedoresShow}
        datos={modalProveedoresDatos}
        onHide={() => setModalProveedoresShow(false)}
      />
    </Container>
  );
}

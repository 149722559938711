import React from "react";
import Faq from "react-faq-component";
/* IMG */
import iconFaqs from "../../../assets/img/home/iconFaqs.svg";

/*CSS*/
import "../css/Faqs.css";

/*BOOTSTRAP*/
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default function MainFaq() {
  const preguntasGenerales = {
    rows: [
      {
        title: "¿Como creo mi cuenta?",
        content:
          "GS360 se encuentra en etapa beta y estamos aprobando las cuentas manualmente para asegurarnos que puedas tener la mejor experiencia posible. En el menú superior encontrarás un botón azul (Comenzar), ingresa y completa  tus datos, nos contactaremos contigo a la brevedad.",
      },
      {
        title: "¿Cuáles son los medios de pago disponibles?",
        content:
          "Puedes pagar con todas las tarjetas, PayPal y transferencia bancaria.",
      },
      {
        title: "¿Cómo se protegen mis datos?",
        content:
          "La seguridad de los <a href='/politica-de-proteccion-de-datos-personales' class='linkFAQ'>datos personales</a> y la información de nuestra plataforma es uno de los elementos en donde más invertimos. Un gran porcentaje de nuestro presupuesto y tiempo va dedicado a proteger los datos con sofisticados sistemas que encriptan y resguarda la información contra posibles ataques.",
      },
    ],
  };

  const preguntasServicios = {
    rows: [
      {
        title: "¿Cómo abro una empresa en Colombia?",
        content:
          "Abrir una empresa es muy fácil, nuestro servicio <a href='/servicios/saswizard' class='linkFAQ'>SAS Wizard</a> te provee todo lo que necesitas, tu asistente te solicitará la información requerida y realizara todo el procedimiento por ti, te entregaremos la empresa lista para que puedas comenzar a operar rápidamente! Visita <a href='/servicios/saswizard' class='linkFAQ'>SAS Wizard</a> para mas información.",
      },
      {
        title:
          "¿ Pueden ayudarme con la contabilidad y obligaciones de mi empresa?",
        content:
          "¡Por supuesto!, Mr Bills es un servicio diseñado para que puedas enfocarte en las actividades de core de tu negocio mientras Mr Bill’s contabiliza, reporta y presenta las declaraciones de impuestos por ti con total seguridad. <a href='/servicios/mrbill' class='linkFAQ'>Click aquí para mas info</a>.",
      },
      {
        title: "¿Puedo firmar digitalmente documentos con GS360?",
        content:
          "¡Claro que si!, hemos desarrollado una plataforma especializada para firmar documentos digitalmente con validez plena según la legislación Colombiana, y no solo eso, sino que también hemos incluido un gestor para que puedes organizarlos en categorías, podrás enviar documentos a firmar de una a cientos de personas. Visita <a href='/servicios/rocketcontracts' class='linkFAQ'>RocketContracts</a> para mas info!.",
      },
      {
        title:
          "¿Tienen plantillas de contratos y documentos pre-diseñados para mi empresa?",
        content:
          "¡Tenemos y muchos! Hemos resumido en decenas de documentos el trabajo y la experiencia de abogados expertos para que puedas contar con documentos legales especializados en plantillas prediseñadas listas para utilizar en diversas circunstancias (contratos laborales, despidos, compras, ventas, etc). Visita <a href='/servicios/legalmanager' class='linkFAQ'>Legal Manager</a> para más info.",
      },
      {
        title: "¿Pueden ayudarme a registrar y proteger mi marca?",
        content:
          "Claro, Brandineitor es el guardián de tu marca,  la registraremos en las clases que corresponda y la protegeremos por 10 años para que nadie saque provecho de tu esfuerzo sin tu autorización. Visita <a href='/servicios/brandineitor' class='linkFAQ'>Brandineitor</a> para más info.",
      },
      {
        title:
          "Necesito varios servicios para empezar mi emprendimiento, ¿Me ayudan?",
        content:
          "¡Dalo por hecho! Hemos pasado por lo mismo y gracias a eso hemos consolidado el networking de todo estos años con lo mejor de lo mejor para que puedas contar con los proveedores TOP en cada área que necesites, diseñadores, programadores, imprentas, marketing, etc. Y si eres bueno en lo que haces también podemos incluirte a ti en este directorio!. Visita <a href='/servicios/360services' class='linkFAQ'>GS360 Services</a> para más info.",
      },
    ],
  };

  const stylesFaqs = {
    bgColor: "transparent",
    titleTextColor: "#2C2F3C",
    rowTitleColor: "#2C2F3C",
    arrowColor: "#07A4A8",
  };

  return (
    <Container>
      <Row className="pt-4 seccionFaq">
        <Col md={12} xs={12}>
          <h4>Generales</h4>
          <Faq data={preguntasGenerales} styles={stylesFaqs} />

          <h4>Servicios</h4>
          <Faq data={preguntasServicios} styles={stylesFaqs} />
        </Col>
      </Row>
    </Container>
  );
}

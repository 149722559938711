import React from "react";

/* SECCIONES RECURSOS*/
import FiltroRecursos from "../FiltroRecursos";
import ResultadosRecursos from "../ResultadosRecursos";
import OrdenRecursos from "../OrdenRecursos";
/* IMG */

/*CSS*/
import "../css/Recursos.css";

/*BOOTSTRAP*/
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";



export default function MainRecursosDigitales() {
  
  return (

       <Container>           
          <Row className="pt-4">
            <Col md={8} xs={12}>
               <FiltroRecursos/> 
               <ResultadosRecursos/>
            </Col>
            <Col md={4} xs={12}> 
              <OrdenRecursos/>
            </Col>                        
          </Row>  
        </Container> 
  );
}

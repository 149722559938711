import React from "react";
import { Link } from "react-router-dom";

/*CSS*/
import "./css/botones.css";

/*BOOTSTRAP*/
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

/*BotonesPortada*/
export const BotonesPortada = ({ link, texto, precio }) => {
  return (
    <Row>
      <Col md={3} sm={6} xs={12}>
        <button
          to={link}
          className="botones btnAzul mx-auto mx-sm-0"
          style={{ width: "170px", margin: "0" }}
        >
          {texto}
        </button>
      </Col>
      <Col md={8} sm={6} xs={12}>
        <p className="precioPorContrato text-center text-sm-left">{precio}</p>
      </Col>
    </Row>
  );
};

/*Boton Azul Outline*/
export const BotonAzulOutline = ({ link, texto }) => {
  return (
    <Link
      to={link}
      className="botones btnAzulOutline mx-auto mx-sm-0"
      style={{ width: "100px", margin: "0" }}
    >
      {texto}
    </Link>
  );
};

import React from "react";
import { Link } from "react-router-dom";

import RecursosLegalManager from "./RecursosLegalManager";

import "./css/LegalManager.css";

/*BOOTSTRAP*/
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default function LegalManagerMain() {
  return (
    <Container className="mainLegalManager">
      <Row className="mb-3 pb-3">
        <Col md={7} xs={12} className="text-center mx-auto">
          <h2 className="subtitHero text-center pb-4">
            Una gran fuente de recursos
          </h2>
          <p className="text-center pt-4">
            Legal Manager es una gran fuente de recursos para proteger
            legalmente a tu negocio.
          </p>
          <p className="text-center pt-4">
            Hemos resumido en decenas de documentos el trabajo y la experiencia
            de abogados expertos para que puedas contar con documentos legales
            especializados en plantillas prediseñadas listas para utilizar en
            diversas circunstancias (contratos laborales, despidos, compras,
            ventas, etc).
          </p>
        </Col>
      </Row>

      <RecursosLegalManager />

      <Link
        to="/contacto"
        className="botones btnVerdeDark mx-auto"
        style={{ width: "200px", margin: "15px 0 0 0" }}
      >
        Comprar documentos
      </Link>
    </Container>
  );
}

import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Link as LinkScroll } from "react-scroll";

/* IMG */
import portadaGs360Home from "../../assets/img/home/portadaGs360Home.png";
import portadaGs360SasWizard from "../../assets/img/sasWizard/portadaGs360SasWizard.png";
import portadaRocketContracts from "../../assets/img/rocketContracts/portadaRocketContracts.png";
import portadaBrandineitor from "../../assets/img/brandineitor/portadaBrandineitor.png";
import portadaMrBills from "../../assets/img/mrBill/portadaMrBills.png";
import portadaGS360Services from "../../assets/img/360services/portadaGS360Services.png";
import portadaLegalManager from "../../assets/img/legalManager/legalManagerPortada.png";
import ModalContacto from "../Contacto/ModalContacto";
import IntroPrecios from "../Precios/IntroPrecios";

import logoSasWizard from "../../assets/img/sasWizard/imgSasWizars.png";
import logoRocketContracts from "../../assets/img/rocketContracts/tagRocketContracts.png";
import logoBrandineitor from "../../assets/img/brandineitor/logoBrandineitor.png";
import logoMrBill from "../../assets/img/mrBill/logoMrBills.png";
import logoGS360Services from "../../assets/img/360services/logoGS360.png";
import logoLegalManager from "../../assets/img/legalManager/logoLegalManager.png";
import iconCalidadGarantizada from "../../assets/img/proveedores/iconCalidad.png";
import iconBuscar from "../../assets/img/proveedores/iconBuscar.png";
import logoGlobalStartupFooter from "../../assets/img/home/logoGlobalStartupFooter.svg";

import scrolDown from "../../assets/img/home/scrolldown.png";
import scrolDownWhite from "../../assets/img/scrolldownWhite.png";
/*CSS*/
import "./css/Hero.css";

/*BOOTSTRAP*/
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";

export function HeroPortada() {
  const [modalContactoShow, setModalContactoShow] = useState(false);
  return (
    <Container className="hero d-flex align-content-stretch">
      <Row className="flex-fill">
        <Col
          md={7}
          xs={12}
          className="d-flex justify-content-center flex-column"
        >
          <img
            src={logoGlobalStartupFooter}
            alt="Global Startup 360°"
            style={{ maxWidth: "120px", marginBottom: "25px" }}
            className="mx-auto ml-sm-0"
          />
          <h1 className="titHero text-sm-left text-center">
            Haz tus negocios.
          </h1>
          <h2 className="subtitHero text-sm-left text-center">
            Nosotros hacemos el resto
          </h2>
          <p className="heroText text-sm-left text-center">
          Empieza el 2023 haciendo real el emprendimiento de tus sueños! 
         
            <br className="d-none d-sm-block" />
            Queremos que iniciar, mantener y expandir tu negocios sea
            <br className="d-none d-sm-block" />
            simple, seguro y a un costo justo.
          </p>
          <button
            className="botones btnNaranja mx-auto mx-sm-0"
            style={{ width: "110px", margin: "15px 0 0 0" }}
            onClick={() => setModalContactoShow(true)}
          >
            Comenzar
          </button>
          <img src={scrolDown} className="scrollDown" alt="Más info +" />
        </Col>
        <Col md={5} xs={12} className="text-center text-sm-left">
          <img src={portadaGs360Home} className="heroImgPortada" alt="GS360" />
        </Col>
      </Row>
      <ModalContacto
        datos=""
        total="0"
        show={modalContactoShow}
        onHide={() => setModalContactoShow(false)}
      />
    </Container>
  );
}

export function HeroSasWizard() {
  return (
    <Container className="hero d-flex align-content-stretch">
      <Row className="flex-fill">
        <Col
          md={7}
          xs={12}
          className="d-flex justify-content-center flex-column"
        >
          <div className="text-sm-left text-center w-100 logoMarca">
            <img src={logoSasWizard} className="img-fluid" alt="Brandineitor" />
          </div>
          <h1 className="titHero text-sm-left text-center">
            Comienza a operar
            <br className="d-none d-sm-block" /> tu empresa en Colombia por sólo $935.000  IVA incluido.
            <br className="d-none d-sm-block" />  HOY % 5 Desc.
          </h1>
          <p className="heroText text-sm-left text-center">
            Concéntrate en tus negocios, deja los trámites en nuestras
            <br className="d-none d-sm-block" />
            manos. Realizamos todo el papeleo para la creación
            <br className="d-none d-sm-block" />
            de tu empresa ante las autoridades correspondientes
          </p>

          <img src={scrolDown} className="scrollDown" alt="Más info +" />
        </Col>
        <Col md={5} xs={12} className="text-center text-sm-left">
          <img
            src={portadaGs360SasWizard}
            className="heroImgSasWizard"
            alt="SasWizard"
          />
        </Col>
      </Row>
    </Container>
  );
}

export function HeroRocketContracts() {
  return (
    <Container className="hero d-flex align-content-stretch">
      <Row className="flex-fill">
        <Col
          md={7}
          xs={12}
          className="d-flex justify-content-center flex-column"
        >
          <div className="text-sm-left text-center w-100 logoMarca">
            <img
              src={logoRocketContracts}
              className="img-fluid"
              alt="Brandineitor"
            />
          </div>
          <h1 className="titHero text-sm-left text-center">
            Gestión de contratos
          </h1>
          <h2 className="subtitHero text-sm-left text-center">
            y firma digital.
          </h2>
          <p className="heroText text-sm-left text-center">
            Crea documentos, organízalos por categorías
            <br className="d-none d-sm-block" />y envíalos a 1 o a miles de
            personas para su firma.
          </p>

          <img src={scrolDown} className="scrollDown" alt="Más info +" />
        </Col>
        <Col md={5} xs={12} className="text-center text-sm-left">
          <img
            src={portadaRocketContracts}
            className="heroRocketContracts"
            alt="Rockets Contracts"
          />
        </Col>
      </Row>
    </Container>
  );
}

export function HeroBrandineitor({ mode }) {
  return (
    <Container className={`hero d-flex align-content-stretch ${mode}`}>
      <Row className="flex-fill">
        <Col
          md={7}
          xs={12}
          className="d-flex justify-content-center flex-column"
        >
          <div className="text-sm-left text-center w-100 logoMarca">
            <img
              src={logoBrandineitor}
              className="img-fluid"
              alt="Brandineitor"
            />
          </div>
          <h1 className="titHero text-sm-left text-center">
            Registra tu marca.
          </h1>
          <h2 className="subtitHero text-sm-left text-center">
            Hazla invencible.
          </h2>
          <p className="heroText text-sm-left text-center">
            Brandineitor es el guardián de tu marca, la mantendrá
            <br className="d-none d-sm-block" />
            protegida de posibles riesgos de similitud y plagio o uso
            <br className="d-none d-sm-block" />
            indebido de tu marca que puedan afectarla. Déjanos
            <br className="d-none d-sm-block" />
            proteger tu marca con todas nuestras fuerzas mientras
            <br className="d-none d-sm-block" />
            continúas creando y posicionándote en el mercado.
          </p>
          <LinkScroll
            to="IDpricesBrandineitor"
            smooth={true}
            className="botones btnCeleste mx-auto mx-sm-0"
            style={{ width: "130px", margin: "15px 0 0 0", cursor: "pointer" }}
          >
            Ver Servicios
          </LinkScroll>

          <img src={scrolDownWhite} className="scrollDown" alt="Más info +" />
        </Col>
        <Col md={5} xs={12} className="text-center text-sm-left">
          <img
            src={portadaBrandineitor}
            className="heroBrandineitor"
            alt="Brandineitor"
          />
        </Col>
      </Row>
    </Container>
  );
}

export function HeroMrBill({ mode }) {
  return (
    <Container className={`hero d-flex align-content-stretch ${mode}`}>
      <Row className="flex-fill">
        <Col
          md={7}
          xs={12}
          className="d-flex justify-content-center flex-column"
        >
          <div className="text-sm-left text-center w-100 logoMarca">
            <img src={logoMrBill} className="img-fluid" alt="Mr. Bills" />
          </div>
          <h1 className="titHero text-sm-left text-center">
            El nuevo concierge digital
            <br className="d-none d-sm-block" />
            para tu contabilidad
          </h1>
          <p className="heroText text-sm-left text-center">
            Enfócate en llevar las actividades claves de tu negocio
            <br className="d-none d-sm-block" />
            mientras Mr Bill’s contabiliza, reporta y presenta
            <br className="d-none d-sm-block" />
            las declaraciones de impuestos por ti con total seguridad.
          </p>

          <LinkScroll
            to="IDpricesMrBill"
            smooth={true}
            className="botones btnVioleta mx-auto mx-sm-0"
            style={{ width: "130px", margin: "15px 0 0 0", cursor: "pointer" }}
          >
            Ver Servicios
          </LinkScroll>

          <img src={scrolDownWhite} className="scrollDown" alt="Más info +" />
        </Col>
        <Col md={5} xs={12} className="text-center text-sm-left">
          <img src={portadaMrBills} className="heroMrBills" alt="Mr. Bills" />
        </Col>
      </Row>
    </Container>
  );
}

export function HeroGS360Services({ mode }) {
  return (
    <Container className={`hero d-flex align-content-stretch ${mode}`}>
      <Row className="flex-fill">
        <Col
          md={7}
          xs={12}
          className="d-flex justify-content-center flex-column"
        >
          <div className="text-sm-left text-center w-100 logoMarca">
            <img
              src={logoGS360Services}
              className="img-fluid"
              alt="360° Services"
            />
          </div>
          <h1 className="titHero text-sm-left text-center">
            Servicios seleccionados
            <br className="d-none d-sm-block" /> especialmente para darle{" "}
            <br className="d-none d-sm-block" />
            un boost a tu negocio
          </h1>
          <Link
            to="/proveedores"
            className="botones btnNaranjaInverse mx-auto mx-sm-0"
            style={{ width: "130px", margin: "15px 0 0 0" }}
          >
            Ver Servicios
          </Link>
          <img src={scrolDownWhite} className="scrollDown" alt="Más info +" />
        </Col>
        <Col md={5} xs={12} className="text-center text-sm-left">
          <img
            src={portadaGS360Services}
            className="heroGS360"
            alt="360° Services"
          />
        </Col>
      </Row>
    </Container>
  );
}

export function HeroLegalManager({ mode }) {
  return (
    <Container className={`hero d-flex align-content-stretch ${mode}`}>
      <Row className="flex-fill">
        <Col
          md={7}
          xs={12}
          className="d-flex justify-content-center flex-column"
        >
          <div className="text-sm-left text-center w-100 logoMarca">
            <img
              src={logoLegalManager}
              className="img-fluid"
              alt="Legal Manager"
            />
          </div>
          <h1 className="titHero text-sm-left text-center">
            Documentos y servicios <br className="d-none d-sm-block" /> legales
            prediseñados
            <br className="d-none d-sm-block" />
            para tu empresa.
          </h1>
          <Link
            to="/contacto"
            className="botones btnVerdeDarkInverse mx-auto mx-sm-0"
            style={{ width: "130px", margin: "15px 0 0 0" }}
          >
            Ver Servicios
          </Link>
          <img src={scrolDownWhite} className="scrollDown" alt="Más info +" />
        </Col>
        <Col md={5} xs={12} className="text-center text-sm-left">
          <img
            src={portadaLegalManager}
            className="heroLegalManager"
            alt="Legal Manager"
          />
        </Col>
      </Row>
    </Container>
  );
}

export function HeroProveedores() {
  return (
    <Container className="heroCorto pb-5">
      <Row>
        <Col md={8} xs={12} className="text-center mx-auto">
          <h1 className="titHero  text-center naranja pb-4">
            Directorio de proveedores
          </h1>
          <p className="heroText text-center">
            360º Services, es una plataforma de servicios brindados por empresas
            como la tuya, de mentalidad emprendedora, proactiva y de excelencia.
            Hemos seleccionado y validado cuidadosamente cada proveedor para que
            puedas acelerar las operaciones de tu empresa contando con lo mejor
            de lo mejor.
          </p>
        </Col>
      </Row>

      <Row>
        <Col md={8} xs={12} className="text-center mx-auto mt-4 pt-5">
          <div className="w-100 shadow p-4 pt-5 pb-5 border-radius-5">
            <Row>
              <Col md={3} xs={12} className="text-center mx-auto">
                <img
                  src={iconCalidadGarantizada}
                  alt="Calidad %100 Garantizada"
                />
              </Col>
              <Col md={9} xs={12}>
                <h2 className="text-center text-sm-left text700 pt-2">
                  Calidad %100 Garantizada
                </h2>
                <p className="text-center text-sm-left mb-0">
                  Muchas de estas empresas incluso son proveedoras nuestras
                </p>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export function HeroRecursosDigitales() {
  return (
    <Container className="heroCorto pb-5">
      <Row>
        <Col md={8} xs={12} className="text-center mx-auto">
          <h1 className="titHero  text-center verde pb-4">
            Recursos digitales
          </h1>
          <p className="heroText text-center">
            Resumimos en decenas de documentos el trabajo y la experiencia de
            abogados expertos para que puedas contar con documentos legales
            especializados en plantillas prediseñadas listas para utilizar en
            diversas circunstancias (contratos laborales, despidos, compras,
            ventas, etc).
          </p>
        </Col>
      </Row>
    </Container>
  );
}

export function HeroFaqs() {
  return (
    <Container className="heroCorto pb-5">
      <Row>
        <Col md={8} xs={12} className="text-center mx-auto">
          <h1 className="titHero  text-center verde pb-4">¿Tienes dudas?</h1>
          <p className="heroTextFaq text-center">
            Estamos para ayudarte en tu próximo negocio
          </p>
        </Col>
      </Row>
      <Row
        className="justify-content-center buscarFaq"
        style={{ display: "none" }}
      >
        <Col md={4} xs={12}>
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text>
                <img src={iconBuscar} alt="" />
              </InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              id="buscar"
              placeholder="Buscar..."
              className="searchInput"
            />
          </InputGroup>
        </Col>
      </Row>
    </Container>
  );
}

export function HeroEscribinos() {
  return (
    <Container className="heroCorto pb-5">
      <Row>
        <Col md={8} xs={12} className="text-center mx-auto">
          <h1 className="titHero  text-center verde pb-4">Escríbenos</h1>
          <p className="heroTextFaq text-center pb-5">
            Abrimos de lunes a viernes de 9 AM a 5 PM (GMT -5). Para los
            mensajes recibidos fuera del horario laboral, espere una
            actualización cuando se reanude el horario comercial normal.
          </p>
        </Col>
      </Row>
    </Container>
  );
}

export function HeroContacto() {
  return (
    <Container className="heroCorto pb-5">
      <Row>
        <Col md={8} xs={12} className="text-center mx-auto">
          <h1 className="titHero  text-center verde pb-4">
            Ponganse en contacto
          </h1>
          <p className="heroTextFaq text-center pb-5">
            Contacte al equipo de atención al cliente
          </p>
        </Col>
      </Row>
    </Container>
  );
}

export function HeroPrecios() {
  return (
    <Container className="heroCorto pb-5">
      <Row>
        <Col md={5} xs={12} className="text-center mx-auto">
          <h1
            className="titHero text-center verde pb-5 mb-4"
            style={{ fontSize: "2rem" }}
          >
            Tarifas simples, transparentes y on-demand
          </h1>
        </Col>
      </Row>
      <Row>
        <IntroPrecios />
      </Row>
    </Container>
  );
}

export function HeroCondiciones({ titulo }) {
  return (
    <Container className="heroCorto pb-5">
      <Row>
        <Col md={8} xs={12} className="text-center mx-auto">
          <h1 className="titHero  text-center verde pb-4">{titulo}</h1>
        </Col>
      </Row>
    </Container>
  );
}

export function HeroFinalizarRegistro() {
  return (
    <Container className="heroCorto pb-5">
      <Row>
        <Col md={8} xs={12} className="text-center mx-auto">
          <h1 className="titHero  text-center verde pb-4">
            Finaliza tu registro
          </h1>
        </Col>
      </Row>
    </Container>
  );
}

export function HeroBienvenida() {
  const name = localStorage.getItem("nombreRegistrado");

  return (
    <Container className="heroCorto pb-5">
      <Row>
        <Col md={8} xs={12} className="text-center mx-auto">
          <h1 className="titHero  text-center verde pb-2">Bienvenido {name}</h1>
          <h2 style={{ fontSize: "24px", marginTop: "0px", fontWeight: "700" }}>
            gracias por sumarte a GS360
          </h2>
        </Col>
      </Row>
    </Container>
  );
}

import React from "react";
import { Link } from "react-router-dom";

/*CSS*/
import "../css/SasWizard.css";

/*BOOTSTRAP*/
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";

function DatosClaves() {
  const options = [
    { value: 1, tit: "Tipo de Empresa", descrip: "SAS" },
    { value: 2, tit: "Capital mínimo", descrip: "COP - 1USD" },
    {
      value: 3,
      tit: "Requisitos Contables",
      descrip:
        "Estado de situación financiera inicial. Definir el valor del aporte en dinero. Determinar el valor de los Activos para operar. Registro obligatorio de la emisión de acciones en el libro de accionistas.",
    },
    {
      value: 4,
      tit: "Privacidad",
      descrip:
        "La información sobre los accionistas es información reservada ante terceros.",
    },
    {
      value: 5,
      tit: "Cambios de Accionistas",
      descrip:
        "No requiere trámites ante notario a menos que sea necesario por ley.",
    },
    {
      value: 6,
      tit: "Auditoria Externa",
      descrip:
        "No es obligatorio salvo cuanto tu empresa tenga Activos Brutos => a 5000 smlmv (2020 $4.389 millones COP o Ingresos => a 3000 smlmv $2.633 millones COP)",
    },
    { value: 7, tit: "Renovación matrícula", descrip: "Anual" },
    {
      value: 8,
      tit: "Impuestos a declarar",
      descrip: "iva (VAT): Regla Gral: Cuatrimestral; Excepción: Bimestral",
    },
    { value: 9, tit: "Renta", descrip: "Anual" },
    { value: 10, tit: "ICA", descrip: "-" },
    {
      value: 11,
      tit: "Retenciones (recaudo anticipado del impuesto)",
      descrip: "Mensual",
    },
  ];

  return (
    <Container>
      <Row>
        <Col md={12} xs={12} className="text-center text-sm-left">
          <h2 className="titNegro text-center pb-4">Datos Claves</h2>
          <Row>
            <Col md={6} xs={12} className="mx-auto">
              <Table bordered responsive>
                <tbody>
                  {options.map((item) => (
                    <tr key={item.value}>
                      <td
                        style={{
                          width: "50%",
                          fontSize: "16px",
                          padding: "20px",
                        }}
                      >
                        {item.tit}
                      </td>
                      <td
                        style={{
                          width: "50%",
                          fontSize: "16px",
                          padding: "20px",
                        }}
                      >
                        {item.descrip}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

export default DatosClaves;

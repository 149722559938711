import React, { useState } from "react";
import { Link } from "react-router-dom";

/* IMG */
import logoSasWizarsPrecios from "../../../assets/img/precios/logoSasWizarsPrecios.svg";
import logoRocketContracts from "../../../assets/img/rocketContracts/dark/logo.png";
import logoBrandineitor from "../../../assets/img/brandineitor/logoBrandineitor.png";
import logoMrBill from "../../../assets/img/mrBill/logoMrBills.png";
import logoGS360Services from "../../../assets/img/360services/logoGS360options.png";
import logoLegalManager from "../../../assets/img/legalManager/dark/logoLegalManager.png";

import ModalContacto from "../../Contacto/ModalContacto";

/*CSS*/
import "../css/Precios.css";

/*BOOTSTRAP*/
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default function IntroPrecios() {
  const [modalContactoShow, setModalContactoShow] = useState(false);

  const options = [
    { value: 1, tit: "Análisis de Registro" },
    { value: 2, tit: "Obtención de NIT" },
    { value: 3, tit: "Documentos" },
    { value: 4, tit: "Obtención de RUT" },
    { value: 5, tit: "Pago de tasas" },
    { value: 5, tit: "Orientación Apertura Cuenta" },
  ];

  const optionsRocket = [
    { value: 1, tit: "Aumenta tu eficiencia" },
    { value: 2, tit: "Crea plantillas personalizadas" },
    { value: 3, tit: "Seguridad" },
    { value: 4, tit: "Seguimiento de los documentos" },
  ];

  return (
    <Container>
      <Row>
        <Col md={12} xs={12} className="text-center boxPrecios">
          <div className="fndBoxPrecios">
            <Row>
              <Col md={2} xs={12} className="pb-3">
                <img src={logoSasWizarsPrecios} alt="SAS Wizard" />
              </Col>
              <Col md={10} xs={12}>
                <h6 className="pb-3 pt-2 text-center text-sm-left">
                  Crea tu empresa por $935.000{" "}
                  <span style={{ fontSize: "14px", fontWeight: "normal" }}>
                    PROMO %5 Desc. (Pago único)
                  </span>
                </h6>
              </Col>
            </Row>
            <Row className="pt-4">
              <Col md={4} xs={12} className="pb-4">
                <p class="text-sm-left text-center" style={{ color: "#fff" }}>
                  Comienza a operar tu empresa en Colombia, concéntrate en tus
                  negocios, deja los trámites en nuestras manos.
                </p>
              </Col>
              <Col md={8} xs={12}>
                <Row>
                  <Col md={10} xs={10} className="mx-auto">
                    <Row className="list-unstyled listaPreciosSpecs mb-4">
                      {options.map((item) => (
                        <Col className="col-md-6 col-12">
                          <p>{item.tit}</p>
                        </Col>
                      ))}
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col md={3} xs={12}>
                <Link
                  to="servicios/saswizard"
                  className="botones btnAzulDarkHover mx-auto mx-sm-0 "
                  style={{ width: "200px", margin: "0" }}
                >
                  Crear mi empresa
                </Link>
              </Col>
              <Col md={9} xs={12}>
                <p
                  className="text-sm-left text-center pt-3"
                  style={{ color: "#fff" }}
                >
                  Si tienes dudas,{" "}
                  <Link to="/contacto" className="linkWhite">
                    contactanos para poder asesorarte {">"}
                  </Link>
                </p>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>

      <Row className="d-flex">
        <Col md={4} xs={12} className="mb-4">
          <div className="fndBoxOpciones">
            <img src={logoLegalManager} alt="Legal Manager" className="mb-3" />
            <p className="mhTxtPrec">
              Una gran fuente de recursos para proteger legalmente a tu negocio,
              accede a cientos de plantillas contractuales para cubrir tus
              operaciones.
            </p>
            <p className="precioOpcion">
              Desde
              <b style={{ fontSize: "1.4rem" }}> $ 188.000</b>

              <span style={{ fontSize: "10px", fontWeight: "normal" }}>
                {" "}
                / Por Doc. /  IVA Incluido  {" "}
              </span>

            </p>
            <button
              onClick={() => setModalContactoShow(true)}
              className="botones btnVerdeDark mx-auto mx-sm-0 "
              style={{ width: "100%", margin: "15px 0 0 0" }}
            >
              Comenzar
            </button>
          </div>
        </Col>

        <Col md={4} xs={12} className="mb-4">
          <div className="fndBoxOpciones">
            <img src={logoBrandineitor} alt="Brandineitor" className="mb-3" />
            <p className="mhTxtPrec">
              Brandineitor es el guardián de tu marca, la mantendrá protegida de
              posibles riesgos de similitud y plagio o uso indebido de tu marca
              que puedan afectarla.
            </p>
            <p className="precioOpcion">
              Desde
              <b style={{ fontSize: "1.4rem" }}> $ 2.240.000</b>
              <span style={{ fontSize: "10px", fontWeight: "normal" }}>
                {" "}
                 IVA Incluido {" "}
              </span>
            </p>
            <Link
              to="/servicios/brandineitor"
              className="botones btnCelesteExtra mx-auto mx-sm-0 "
              style={{ width: "100%", margin: "20px 0 0 0" }}
            >
              Registra tu marca
            </Link>
          </div>
        </Col>

        <Col md={4} xs={12} className="mb-4">
          <div className="fndBoxOpciones">
            <img src={logoMrBill} alt="Mr Bill" className="mb-3" />
            <p className="mhTxtPrec">
              Mr Bill’s contabiliza, reporta y presenta las declaraciones de
              impuestos por ti con total seguridad y compromiso.
            </p>
            <p className="precioOpcion">
              Desde
              <b style={{ fontSize: "1.4rem" }}> $840.000</b>
              <span style={{ fontSize: "10px", fontWeight: "normal" }}>
                {" "}
                 IVA Incluido {" "}
              </span>

            

            </p>
            <Link
              to="/servicios/mrbill"
              className="botones btnVioletaInverse mx-auto mx-sm-0 "
              style={{ width: "100%", margin: "15px 0 0 0" }}
            >
              Comienza Ahora
            </Link>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={8} xs={12} className="mb-4">
          <div className="fndBoxOpciones">
            <img
              src={logoRocketContracts}
              alt="Rocket Contracts"
              className="mb-3"
            />
            <p className="mhTxtPrec" style={{ minHeight: "auto" }}>
              Crea documentos, organízalos por categorías, envíalos uno a uno o
              masivamente a miles de personas para su firma.
            </p>
            <Row>
              <Col md={12} xs={10} className="mx-auto">
                <Row className="list-unstyled listaOptionsRocket mb-4">
                  {optionsRocket.map((item) => (
                    <Col className="col-md-6 col-12">
                      <p>{item.tit}</p>
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>
           
            <p className="precioOpcion">
          {/*   
              Desde
              <b style={{ fontSize: "1.4rem" }}> $ 188.000</b>

        
              <span style={{ fontSize: "14px", fontWeight: "normal" }}>
                 IVA incluido (Incluye hasta 5 documentos mensuales para firmar
                electrónicamente.)
              </span> 

              */}


              
              <span style={{ fontSize: "14px", fontWeight: "normal" }}>
              Próximamente podrás disfrutar de nuestra plataforma de firma electrónica.
              </span> 

             

            </p>
            <Link
              to="/servicios/rocketcontracts"
              className="botones btnAzulExtra mx-auto mx-sm-0 w100xsOption"
              style={{ margin: "15px 0 0 0" }}
            >
              Registrate Gratis
            </Link>
          </div>
        </Col>

        <Col md={4} xs={12} className="mb-4">
          <div className="fndBoxOpciones">
            <img src={logoGS360Services} alt="GS360" className="mb-3" />
            <p className="mhTxtPrec" style={{ minHeight: "130px" }}>
              Una plataforma de servicios brindados por empresas como la tuya,
              de mentalidad emprendedora, proactiva y de excelencia.
            </p>
            <p className="precioOpcion">
              <b style={{ fontSize: "1.4rem" }}> Gratis para ti</b>
            </p>
            <button
              onClick={() => setModalContactoShow(true)}
              className="botones btnNaranjaStrong mx-auto mx-sm-0 "
              style={{ width: "100%", margin: "15px 0 0 0" }}
            >
              Comenzar
            </button>
          </div>
        </Col>
      </Row>
      <ModalContacto
        show={modalContactoShow}
        onHide={() => setModalContactoShow(false)}
      />
    </Container>
  );
}

import React, { useState } from "react";

/*IMG*/
import PlanBrandineitorShield from "../../../assets/img/brandineitor/PlanBrandineitorShield.png";
import PlanBrandineitorWatchdog from "../../../assets/img/brandineitor/PlanBrandineitorWatchdog.png";
import ModalContacto from "../../Contacto/ModalContacto";

/*CSS*/
import "../css/Brandineitor.css";

/*BOOTSTRAP*/
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";

export default function AgregaServicios() {
  const options = [
    { value: 1, tit: "Asesoría inicial de orientación", col: 5 },
    {
      value: 2,
      tit: "Tarifa de registro de marca en la Autoridad de Marcas.",
      col: 7,
    },
    { value: 3, tit: "Solicitud de registro", col: 5 },
    { value: 4, tit: "Contestación de oposiciones.", col: 7 },
    { value: 5, tit: "Seguimiento del trámite", col: 5 },
    { value: 6, tit: "Atención personalizada permanente.", col: 7 },
  ];

  const [modalContactoShow, setModalContactoShow] = useState(false);

  const [selectedItems, setSelectedItems] = useState([]);
  const [totalPrice, setTotalPrice] = useState(null);

  function handleRemove(id, precio) {
    //console.log(id);
    const newList = selectedItems.filter((item) => item.id !== id);
    setSelectedItems(newList);
    setTotalPrice(totalPrice - precio);
  }

  function handleAdd(id, titulo, precio) {
    //console.log(id, titulo, precio);
    let seleccionados = [...selectedItems];
    // Add item to it
    seleccionados.push({ id: id, titulo: titulo, precio: precio });
    // Set state
    setSelectedItems(seleccionados);
    setTotalPrice(totalPrice + precio);
  }

  return (
    <Container className="bgBrandMasServ" fluid name="IDpricesBrandineitor">
      <Row>
        <Col md={12} xs={12} className="text-center text-sm-left">
          <h2 className="text-center pb-4">Agrega tus servicios</h2>

          <Row className="mb-5">
            <Col md={7} xs={12} className="mx-auto optionBrandineitor ">
              <Card>
                <img
                  src={PlanBrandineitorShield}
                  alt="100% Garantizado"
                  className="img-fluid"
                />
                <Card.Body
                  style={{
                    backgroundColor: `${
                      selectedItems.some((x) => x.id === 1) ? "#C7E8E8" : "#FFF"
                    }`,
                  }}
                >
                  <Row>
                    <Col md={5} xs={12}>
                      <h3 className="text-center text-sm-left">
                        Brandineitor Shield
                      </h3>
                    </Col>
                    <Col
                      md={7}
                      xs={12}
                      className="d-flex align-items-end justify-content-center justify-content-sm-start"
                    >
                      <h6 className="text-center text-sm-left">
                        Tu marca registrada y protegida.
                      </h6>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12} xs={8} className="mx-auto pt-3">
                      <Row className="list-unstyled listaBrandShield mt-3 mb-3">
                        {options.map((item) => (
                          <Col className={`col-md-${item.col} col-12`}>
                            <p>{item.tit}</p>
                          </Col>
                        ))}
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      md={4}
                      xs={12}
                      className="d-flex justify-content-md-start justify-content-center  align-items-center"
                    >
                      <p className="precioOpcionBrand">
                        <b>$2.240.000</b>
                        <span style={{ fontSize: "11px" }}>
                          {" "}
                           IVA incluido (Pago único, No incluye las tarifas de registro
                          definidas por la autoridad de marcas.)
                        </span>
                      </p>
                    </Col>
                    <Col md={8} xs={12} className="">
                      {selectedItems.some((x) => x.id === 1) ? (
                        <button
                          className="botones btnCelesteDark mx-auto mx-sm-0"
                          style={{ width: "130px", margin: "0" }}
                          onClick={() => handleRemove(1, 1200000)}
                        >
                          Quitar
                        </button>
                      ) : (
                        <button
                          className="botones btnCelesteDark active mx-auto mx-sm-0"
                          style={{ width: "130px", margin: "0" }}
                          onClick={() =>
                            handleAdd(1, "Brandineitor Shield", 1200000)
                          }
                        >
                          Agregar
                        </button>
                      )}
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <Row className="mb-5">
            <Col md={7} xs={12} className="mx-auto optionBrandineitor ">
              <Card>
                <img
                  src={PlanBrandineitorWatchdog}
                  alt="100% Garantizado"
                  className="img-fluid"
                />
                <Card.Body
                  style={{
                    backgroundColor: `${
                      selectedItems.some((x) => x.id === 2) ? "#C7E8E8" : "#FFF"
                    }`,
                  }}
                >
                  <Row>
                    <Col md={6} xs={12}>
                      <h3 className="text-center text-sm-left">
                        Brandineitor Watchdog
                      </h3>
                    </Col>
                    <Col
                      md={6}
                      xs={12}
                      className="d-flex align-items-end justify-content-center justify-content-sm-start"
                    >
                      <h6 className="text-center text-sm-left">
                        Guardianes de tu marca los 365 días del año.
                      </h6>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12} xs={8} className="mx-auto pt-3">
                      <p className="textoOptionBrand">
                        Establecemos una guardia y revisión continua de todas
                        las amenazas que pueda tener tu marca, revisamos nuevos
                        registros y peticiones para evitar que surjan marcas que
                        puedan ser parecidas o confundirse con la tuya, y te
                        informaremos los costos y trámites a seguir para
                        defender ese activo tan valioso que como emprendedor
                        lograste posicionar en el mercado
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      md={4}
                      xs={12}
                      className="d-flex justify-content-md-start justify-content-center  align-items-center"
                    >
                      <p className="precioOpcionBrand">
                        <b>$  375.000</b>
                        <span> IVA Incluido.</span>
                      </p>
                    </Col>
                    <Col md={8} xs={12} className="">
                      {selectedItems.some((x) => x.id === 2) ? (
                        <button
                          className="botones btnCelesteDark mx-auto mx-sm-0"
                          style={{ width: "130px", margin: "0" }}
                          onClick={() => handleRemove(2, 150000)}
                        >
                          Quitar
                        </button>
                      ) : (
                        <button
                          className="botones btnCelesteDark active mx-auto mx-sm-0"
                          style={{ width: "130px", margin: "0" }}
                          onClick={() =>
                            handleAdd(2, "Brandineitor Watchdog", 150000)
                          }
                        >
                          Agregar
                        </button>
                      )}
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          {totalPrice ? (
            <Row className="mb-5">
              <Col md={7} xs={12} className="mx-auto optionBrandineitor ">
                <Card>
                  <Card.Body style={{ paddingBottom: "20px" }}>
                    <Row>
                      <Col
                        md={9}
                        xs={12}
                        className="d-flex  justify-content-md-start justify-content-center align-items-center  "
                      >
                        <Row>
                          <Col md={12}>
                            <p className="precioOpcionBrand">
                              <b>$ {totalPrice.toLocaleString("de-DE")}</b>
                              <span>  /Mes IVA Incluido</span>
                            </p>
                          </Col>
                        </Row>
                      </Col>
                      <Col md={3} xs={12} className="">
                        <button
                          className="botones btnCelesteDark mx-auto mx-sm-0"
                          style={{ width: "130px", margin: "0" }}
                          onClick={() => setModalContactoShow(true)}
                        >
                          Continuar
                        </button>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          ) : (
            ""
          )}
        </Col>
      </Row>

      <ModalContacto
        datos={selectedItems}
        total={totalPrice}
        show={modalContactoShow}
        onHide={() => setModalContactoShow(false)}
      />
    </Container>
  );
}

import React from "react";

/* IMG */
import iconEcologia from '../../../assets/img/rocketContracts/iconEcologia.png';

/*CSS*/
import "../css/RocketContracts.css";

/*BOOTSTRAP*/
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default function CuidadoMedioAmbiente() {

  
  return (
    <Container className="bgMedioAmbiente" fluid> 
    <Container>
      <Row>   

        <Col md={8} xs={12} className="text-center mx-auto">

        <img src={iconEcologia} className="mx-auto mb-1" alt="Rocket Contracts"/>
                
          <h2 className="text-center pb-0">Cuidamos y conservamos el medio ambiente</h2>       

          <p className="text-center pt-3">
          Promovemos el cuidado y conservación del medio ambiente, por eso gestiona tus contratos y firmalos digitalmente con la convicción y certeza de ser vinculante, sin papeles y desde cualquier lugar amparado por la Ley.
          </p>

        </Col>
      
      </Row>        
    </Container>
  </Container>


  );
}



import React from "react";

/*CSS*/
import "../css/Proveedores.css";

/*BOOTSTRAP*/
import Modal from "react-bootstrap/Modal";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default function ModalProveedores(props) {
  return (
    <Modal
      {...props}
      dialogClassName="modal-600w"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header
        closeButton
        style={{ padding: "15px 15px 0 0", borderBottom: "0" }}
      ></Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col md={12} xs={12} className="pb-2 text-left mx-auto">
              <Row className="resultadoProveedor">
                <Col md={12} xs={12}>
                  <Row>
                    <Col className="pb-2 pt-0">
                      <h5 style={{ fontSize: "20px" }}>{props.datos.tit}</h5>
                    </Col>
                  </Row>
                  <hr></hr>
                  <Row>
                    <Col className="pb-2">
                      <p className="textoProveedores">Teléfono: 123456</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pb-1">
                      <p className="textoProveedores">
                        Av. Lorem Ipsumn 123, Bogotá, Colombia.
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pb-1">
                      <p className="textoProveedores">email@email.com</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pb-1">
                      <p className="textoProveedores">
                        www.url-del-proveedor.com
                      </p>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
}

import React from "react";
import {Link} from "react-router-dom";

import imgLogo from '../../../assets/img/proveedores/imgLogo.png';
import iconVerificado from '../../../assets/img/proveedores/iconVerificado.png';

/*CSS*/
import "../css/Recursos.css";

/*BOOTSTRAP*/
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Pagination from 'react-bootstrap/Pagination'

export default function ResultadosRecursos() {

  let active = 2;
  let items = [];
  for (let number = 1; number <= 5; number++) {
    items.push(
      <Pagination.Item key={number} active={number === active}>
        {number}
      </Pagination.Item>,
    );
  }

  
  const options = [    
    { value: 1,    
      tit: "Título documento para categoría Empleado",
      price: "200.000",
      active: "active",
      descrip: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim",
   },  
   { value: 2,    
    tit: "Título documento para categoría Empleado",
    price: "200.000",
    active: "",
    descrip: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim",
 }, 
 { value: 3,    
  tit: "Título documento para categoría Empleado",
  price: "200.000",
  active: "",
  descrip: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim",
}, 
{ value: 4,    
  tit: "Título documento para categoría Empleado",
  price: "200.000",
  active: "",
  descrip: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim",
}, 
{ value: 5,    
  tit: "Título documento para categoría Empleado",
  price: "200.000",
  active: "",
  descrip: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim",
}, 
{ value: 6,    
  tit: "Título documento para categoría Empleado",
  price: "200.000",
  active: "",
  descrip: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim",
}, 
  ]

  
  return (

       <Container>           
          <Row>
            <Col md={12} xs={12}>

            {options.map((item) => (                  
                  <div className={`borderRecursos ${item.active}`} key={item.value}>
                    <Row className="resultadoRecursos">               
                      
                        <Col md={9} xs={12}>
                          <Row>
                            <Col className="pb-1 pt-1">
                            <h5>{item.tit}                            
                            <span>Vista previa</span>
                            </h5>
                            </Col>
                          </Row>
                          <Row>
                            <Col className="pb-2">
                              <p className="textoRecursos">
                              {item.descrip}
                              </p>
                            </Col>
                          </Row>                  
                        </Col>
                        <Col md={3} xs={12} className="d-flex align-items-center justify-content-center flex-column">
                            <p className="textoPriceRecursos text-center w-100 mb-0">
                              $ {item.price}
                            </p>
                          <Link className="botones btnVerdeDark mx-auto text-center" style={{width:'150px', margin:'5px 0 0 0'}}>Agregar</Link> 
                        </Col>              
                    </Row>
                  </div>        
              ))}

            </Col>                        
          </Row>  

          <Row>
            <Col md={12}>                    
                  <Pagination className="paginationProv">{items}</Pagination>                             
            </Col>
          </Row>
        </Container> 

 
  );
}

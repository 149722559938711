import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";

/* Componentes */
import Logo from "../Logo";

/*CSS*/
import "./css/navbar.css";

/* IMG */
import btnSasWizard from "../../assets/img/navbar/btnSasWizard.png";
import btnRocketContracts from "../../assets/img/navbar/btnRocketContracts.png";
import btnMrBills from "../../assets/img/navbar/btnMrBills.png";
import BtnLegalManager from "../../assets/img/navbar/BtnLegalManager.png";
import btnGS360 from "../../assets/img/navbar/btnGS360.png";
import btnBrandineitor from "../../assets/img/navbar/btnBrandineitor.png";
import iconSoluciones from "../../assets/img/navbar/iconSoluciones.png";

import Ingresar from "../Registro/Ingresar";

import ModalContacto from "../Contacto/ModalContacto";
import ModalRegistro from "../Registro/Registrate";

function NavBar({ mode, hideButtons }) {
  const [modalRegShow, setModalRegShow] = useState(false);
  const [modalContactoShow, setModalContactoShow] = useState(false);
  const [modalLoginShow, setModalLoginShow] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const breakPoint = 767;

  const handleModalRegLogin = () => {
    setModalRegShow(false);
    setModalLoginShow(true);
  };

  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  //console.log(width);

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 80) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  });

  let navbarClasses = ["topNavbar sticky-top"];

  if (width > breakPoint) {
    //console.log("es mayor al breakpoint");
    if (mode === "light") {
      navbarClasses = ["topNavbar sticky-top light"];
    }

    if (mode === "light") {
      if (scrolled) {
        navbarClasses = "";
        navbarClasses = ["topNavbar sticky-top"];
        navbarClasses.push("scrolled");
      }
    }
  } else {
    //console.log("es menor al breakpoint");
    navbarClasses = ["topNavbar sticky-top"];
  }

  if (scrolled) {
    navbarClasses.push("scrolled");
  }

  //console.log(navbarClasses);

  const [show, setShow] = useState(false);
  const showDropdown = (e) => {
    setShow(!show);
  };
  const hideDropdown = (e) => {
    setShow(false);
  };

  return (
    <Container className={navbarClasses.join(" ")} fluid>
      <Container>
        <Navbar variant="light" expand="lg">
          <Navbar.Brand>
            <Link smooth to="/">
              <Logo name="GS360" imagen="gs360" mode={mode} />
            </Link>
          </Navbar.Brand>

          {!hideButtons && (
            <>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />

              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ml-auto ">
                  {width > breakPoint ? (
                    <DropdownButton
                      id="dropdown-item-button"
                      title="Servicios"
                      menuAlign="center"
                      className="mega-dropdown"
                      show={show}
                      onMouseEnter={showDropdown}
                      onMouseLeave={hideDropdown}
                    >
                      <Dropdown.ItemText
                        style={{ width: "1110px" }}
                        className="mega-dropdown-menu"
                      >
                        <Row>
                          <Col md={9} className="pb-3 pt-3">
                            <Row>
                              <Col md={4} className="pt-4">
                                <Link to="/servicios/saswizard">
                                  <img src={btnSasWizard} alt="SAS Wizard" />
                                </Link>
                                <p className="subtitNavBarMM pt-2">
                                  Tu empresa lista en 3 pasos.
                                </p>
                              </Col>
                              <Col md={4} className="pt-4">
                                <Link to="/servicios/rocketcontracts">
                                  <img
                                    src={btnRocketContracts}
                                    alt="SAS Wizard"
                                  />
                                </Link>
                                <p className="subtitNavBarMM pt-2">
                                  Gestión de contratos y firma
                                  <br />
                                  Digital
                                </p>
                              </Col>
                              <Col md={4} className="pt-4">
                                <Link to="/servicios/brandineitor">
                                  <img src={btnBrandineitor} alt="SAS Wizard" />
                                </Link>
                                <p className="subtitNavBarMM pt-2">
                                  Registra tu marca y hazla
                                  <br />
                                  invencible.
                                </p>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={4} className="pt-4">
                                <Link to="/servicios/mrbill">
                                  <img src={btnMrBills} alt="Mr Bills" />
                                </Link>
                                <p className="subtitNavBarMM pt-2">
                                  La contabilidad de tu empresa
                                  <br /> en un pack todo incluido.
                                </p>
                              </Col>
                              <Col md={4} className="pt-4">
                                <Link to="/servicios/legalmanager">
                                  <img
                                    src={BtnLegalManager}
                                    alt="LegalMaNAGER"
                                  />
                                </Link>
                                <p className="subtitNavBarMM pt-2">
                                  Documentos y servicios legales
                                  <br />
                                  pre-diseñados para a tu
                                  <br />
                                  empresa.
                                </p>
                              </Col>
                              <Col md={4} className="pt-4">
                                <Link to="/servicios/360services">
                                  <img src={btnGS360} alt="360GS" />
                                </Link>
                                <p className="subtitNavBarMM pt-2">
                                  Servicios seleccionados
                                  <br />
                                  especialmente para darle
                                  <br />
                                  un boost a tu startup
                                </p>
                              </Col>
                            </Row>
                          </Col>

                          <Col md={3}>
                            <Row className="d-flex h-100">
                              <Col md={12} className="d-flex h-100">
                                <div className="bgDerNavBar d-flex flex-fill justify-content-center flex-column">
                                  <h6>
                                    Haz tus negocios.
                                    <br />
                                    Nosotros hacemos
                                    <br />
                                    el resto
                                    <br />
                                  </h6>
                                  <button
                                    onClick={() => setModalContactoShow(true)}
                                    className="botones btnNaranja mx-auto mx-sm-0"
                                    style={{
                                      width: "110px",
                                      margin: "15px 0 0 0",
                                    }}
                                  >
                                    Comenzar
                                  </button>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Dropdown.ItemText>
                    </DropdownButton>
                  ) : (
                    <DropdownButton
                      id="dropdown-item-button"
                      title="Servicios"
                      menuAlign="center"
                    >
                      <Dropdown.ItemText>
                        <Link
                          className="linkHeader pl-5 w-100 d-block"
                          to="/servicios/saswizard"
                        >
                          SAS Wizard
                        </Link>
                        <Link
                          className="linkHeader pl-5  w-100 d-block"
                          to="/servicios/rocketcontracts"
                        >
                          Rocket Contracts
                        </Link>
                        <Link
                          className="linkHeader pl-5  w-100 d-block"
                          to="/servicios/brandineitor"
                        >
                          Brandineitor
                        </Link>
                        <Link
                          className="linkHeader pl-5  w-100 d-block"
                          to="/servicios/mrbill"
                        >
                          Mr. Bills
                        </Link>
                        <Link
                          className="linkHeader pl-5  w-100 d-block"
                          to="/servicios/legalmanager"
                        >
                          Legal Manager
                        </Link>
                        <Link
                          className="linkHeader pl-5  w-100 d-block"
                          to="/servicios/360services"
                        >
                          360° Services
                        </Link>
                      </Dropdown.ItemText>
                    </DropdownButton>
                  )}

                  <DropdownButton
                    id="dropdown-item-button"
                    title="Soluciones"
                    menuAlign="left"
                    className="mega-dropdown d-none menuSolucionesTop"
                  >
                    <Dropdown.ItemText
                      style={{ width: "600px" }}
                      className="mega-dropdown-menu"
                    >
                      <Row>
                        <Col md={12} className="pb-3 pt-3">
                          <Row>
                            <Col md={6} className="pt-1">
                              <Link to="/" className="linkSolNavBar">
                                <p className="txtSolNavBar pt-2">
                                  <img src={iconSoluciones} alt="Soluciones" />{" "}
                                  Servicios financieros
                                </p>
                              </Link>
                            </Col>
                            <Col md={6} className="pt-1">
                              <Link to="/" className="linkSolNavBar">
                                <p className="txtSolNavBar pt-2">
                                  <img src={iconSoluciones} alt="Soluciones" />{" "}
                                  Salud
                                </p>
                              </Link>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={6} className="pt-1">
                              <Link to="/" className="linkSolNavBar">
                                <p className="txtSolNavBar pt-2">
                                  <img src={iconSoluciones} alt="Soluciones" />{" "}
                                  Inmobiliarias
                                </p>
                              </Link>
                            </Col>
                            <Col md={6} className="pt-1">
                              <Link to="/" className="linkSolNavBar">
                                <p className="txtSolNavBar pt-2">
                                  <img src={iconSoluciones} alt="Soluciones" />{" "}
                                  Construccion
                                </p>
                              </Link>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={6} className="pt-1">
                              <Link to="/" className="linkSolNavBar">
                                <p className="txtSolNavBar pt-2">
                                  <img src={iconSoluciones} alt="Soluciones" />{" "}
                                  Producción audiovisual
                                </p>
                              </Link>
                            </Col>
                            <Col md={6} className="pt-1">
                              <Link to="/" className="linkSolNavBar">
                                <p className="txtSolNavBar pt-2">
                                  <img src={iconSoluciones} alt="Soluciones" />{" "}
                                  Startups
                                </p>
                              </Link>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={6} className="pt-1">
                              <Link to="/" className="linkSolNavBar">
                                <p className="txtSolNavBar pt-2">
                                  <img src={iconSoluciones} alt="Soluciones" />{" "}
                                  Educación
                                </p>
                              </Link>
                            </Col>
                            <Col md={6} className="pt-1">
                              <Link to="/" className="linkSolNavBar">
                                <p className="txtSolNavBar pt-2">
                                  <img src={iconSoluciones} alt="Soluciones" />{" "}
                                  Agencias de Marketing
                                </p>
                              </Link>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={6} className="pt-1">
                              <Link to="/" className="linkSolNavBar">
                                <p className="txtSolNavBar pt-2">
                                  <img src={iconSoluciones} alt="Soluciones" />{" "}
                                  Notarias
                                </p>
                              </Link>
                            </Col>
                            <Col md={6} className="pt-1">
                              <Link to="/" className="linkSolNavBar">
                                <p className="txtSolNavBar pt-2">
                                  <img src={iconSoluciones} alt="Soluciones" />{" "}
                                  RRHH
                                </p>
                              </Link>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Dropdown.ItemText>
                  </DropdownButton>

                  <Link to="/precios" className="linkHeader">
                    Precios
                  </Link>
                  <Link to="/faqs" className="linkHeader">
                    FAQs
                  </Link>
                 
                 
                </Nav>
                
                
                <Nav>

                <Link to="/contacto">
          
                 <button className="botones btnAzul" >
                    Contacto
                  </button>
                  </Link>
                  
                </Nav>


              </Navbar.Collapse>
            </>
          )}
        </Navbar>
      </Container>

      <ModalContacto
        show={modalContactoShow}
        onHide={() => setModalContactoShow(false)}
      />

      <ModalRegistro
        show={modalRegShow}
        handleModalRegLogin={handleModalRegLogin}
        onHide={() => setModalRegShow(false)}
      />

      <Ingresar show={modalLoginShow} onHide={() => setModalLoginShow(false)} />
    </Container>
  );
}

export default NavBar;

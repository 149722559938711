import React from "react";

/*CSS*/
import "./css/header.css";

/* COMPONENTES */
import NavBar from "../NavBar";
import {
  HeroPortada,
  HeroSasWizard,
  HeroRocketContracts,
  HeroBrandineitor,
  HeroMrBill,
  HeroGS360Services,
  HeroLegalManager,
  HeroProveedores,
  HeroRecursosDigitales,
  HeroFaqs,
  HeroEscribinos,
  HeroContacto,
  HeroPrecios,
  HeroCondiciones,
  HeroFinalizarRegistro,
  HeroBienvenida,
} from "../Hero";

import Error404 from "../../componentes/Error404";

/*BOOTSTRAP*/

export function HeaderPortada() {
  return (
    <div className="bgInicial bgPortada">
      <NavBar mode="dark" />

      <HeroPortada />
    </div>
  );
}

export function HeaderSasWizard() {
  return (
    <div className="bgInicial bgSasWizard">
      <NavBar mode="dark" />

      <HeroSasWizard />
    </div>
  );
}

export function HeaderRocketContracts() {
  return (
    <div className="bgRocketContracts">
      <NavBar mode="dark" />

      <HeroRocketContracts />
    </div>
  );
}

export function HeaderBrandineitor() {
  return (
    <div className="bgBrandineitor">
      <NavBar mode="light" />

      <HeroBrandineitor mode="light" />
    </div>
  );
}

export function HeaderMrBill() {
  return (
    <div className="bgInicial bgMrBill">
      <NavBar mode="light" />

      <HeroMrBill mode="light" />
    </div>
  );
}

export function HeaderGS360Services() {
  return (
    <div className="bgInicial bg360Services">
      <NavBar mode="light" />

      <HeroGS360Services mode="light" />
    </div>
  );
}

export function HeaderLegalManager() {
  return (
    <div className="bgInicial bgLegalManager">
      <NavBar mode="light" />

      <HeroLegalManager mode="light" />
    </div>
  );
}

export function HeaderProveedores() {
  return (
    <div className="bgProveedores">
      <NavBar />

      <HeroProveedores />
    </div>
  );
}

export function HeaderRecursosDigitales() {
  return (
    <div className="bgRecursos">
      <NavBar />

      <HeroRecursosDigitales />
    </div>
  );
}

export function HeaderFaqs() {
  return (
    <div className="bgMainFaqs">
      <NavBar />

      <HeroFaqs />
    </div>
  );
}

export function HeaderEscribinos() {
  return (
    <div className="bgMainFaqs">
      <NavBar />

      <HeroEscribinos />
    </div>
  );
}

export function HeaderContacto() {
  return (
    <div className="bgMainFaqs">
      <NavBar />

      <HeroContacto />
    </div>
  );
}

export function Header404() {
  return (
    <div className="bgInicial bgPortada">
      <NavBar mode="dark" />

      <Error404 />
    </div>
  );
}

export function HeaderPrecios() {
  return (
    <div className="bgMainFaqs">
      <NavBar mode="dark" />

      <HeroPrecios />
    </div>
  );
}

export function HeaderCondiciones({ titulo }) {
  return (
    <div className="bgMainFaqs">
      <NavBar />

      <HeroCondiciones titulo={titulo} />
    </div>
  );
}

export function HeaderFinalizarRegistro() {
  return (
    <div className="bgMainFaqs">
      <NavBar />

      <HeroFinalizarRegistro />
    </div>
  );
}

export function HeaderBienvenida() {
  return (
    <div className="bgMainFaqs">
      <NavBar />

      <HeroBienvenida />
    </div>
  );
}

export function HeaderDocumentos() {
  return (
    <div className="bgMainFaqs">
      <NavBar hideButtons={true} />
    </div>
  );
}

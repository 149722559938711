import React, { useState, useRef } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

/* IMG */
import logoGlobalStartupFooterWhite from "../../../assets/img/logoGlobalStartupFooterWhite.png";
import Spin from "../../../assets/img/Spin.svg";
import IMGenviadoOk from "../../../assets/img/enviadoOK.png";
import IMGenviadoERROR from "../../../assets/img/enviadoERROR.png";
/*CSS*/
import "../css/Registro.css";

/*BOOTSTRAP*/
import Modal from "react-bootstrap/Modal";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import PasswordStrengthBar from "react-password-strength-bar";

export default function Registrate(props) {
  const [status, setStatus] = useState(null);
  const [mensaje, setMensaje] = useState(null);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const password = useRef({});
  password.current = watch("password", "");

  const onSubmit = (data) => {
    //console.log(data);

    setStatus("loading");

    axios
      .post("https://api.globalstartup360.com/api/lead_register", data)

      .then(function (response) {
        setStatus("ok");
      })
      .catch(function (error) {
        setStatus("error");
        setMensaje(error.response.data.message);
      });
  };

  //console.log(watch("password"));
  //console.log(watch("passcheck"));

  //REDIRECCION AL CERRAR EL MODAL
  let history = useHistory();

  if (status === null) {
    return (
      <Modal
        {...props}
        dialogClassName="modal-350w modalRegLog"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header
          closeButton
          style={{
            padding: "15px",
            borderBottom: "0",
            backgroundColor: "#15385C",
          }}
        >
          <img
            src={logoGlobalStartupFooterWhite}
            alt="GS360"
            className="img-fluid"
          />
        </Modal.Header>
        <Modal.Body>
          <h4 className="text-center" style={{ color: "#0365A6" }}>
            Registrate
          </h4>

          <Form
            novalidate
            className="text-left pt-4"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Form.Row>
              <Form.Group as={Col} md={12} xs={12}>
                <Form.Label>Correo electrónico</Form.Label>
                <input
                  className="form-control"
                  type="email"
                  name="email"
                  {...register("email", {
                    required: true,
                  })}
                />
                {errors.email && (
                  <span style={{ fontSize: "12px" }}>Campo obligatorio</span>
                )}
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} md={12} xs={12} className="mb-0">
                <Form.Label>Contraseña</Form.Label>
                <input
                  className="form-control"
                  type="password"
                  name="password"
                  {...register("password", {
                    required: true,
                    minLength: {
                      value: 8,
                      message: "Escribe al menos 8 caracteres",
                    },
                  })}
                />
                {errors.password && (
                  <span style={{ fontSize: "12px" }}>
                    {errors.password.message}
                  </span>
                )}

                <PasswordStrengthBar
                  password={password.current}
                  scoreWords={[
                    "Muy débil",
                    "Débil",
                    "Bien",
                    "Muy Bien",
                    "Excelente",
                  ]}
                  shortScoreWord="Incompleto"
                />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} md={12} xs={12}>
                <Form.Label>Repetir contraseña</Form.Label>
                <input
                  className="form-control"
                  type="password"
                  name="passcheck"
                  {...register("passcheck", {
                    required: true,
                    minLength: {
                      value: 8,
                      message: "Escribe al menos 8 caracteres",
                    },
                    validate: (value) =>
                      value === password.current ||
                      "Las contraseñas no coinciden",
                  })}
                />
                {errors.passcheck && (
                  <span style={{ fontSize: "12px" }}>
                    {errors.passcheck.message}
                  </span>
                )}
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} md={12} xs={12}>
                <Form.Check
                  custom
                  type="checkbox"
                  id={`custom-checkbox-terminos`}
                  required
                  label={
                    <>
                      Acepto los{" "}
                      <a
                        href="/terminos-y-condiciones"
                        style={{ color: "#000" }}
                      >
                        Términos y Condiciones
                      </a>
                    </>
                  }
                />
              </Form.Group>
            </Form.Row>
            <Form.Row className="d-none">
              <Form.Group as={Col} md={12} xs={12}>
                <Form.Check
                  custom
                  type="checkbox"
                  id={`custom-checkbox-recordarme`}
                  label={`Recordar mi contraseña`}
                />
              </Form.Group>
            </Form.Row>
            <input
              class="botones btnNaranja mx-auto w-100"
              type="submit"
              style={{ minWidth: "200px", margin: "5px 0 0 0" }}
              value="Registrarme"
            />
            <Form.Row>
              <Form.Group as={Col} md={12} xs={12}>
                <p
                  className="text-center pt-4 mb-0"
                  style={{ fontSize: "12px" }}
                >
                  Ya tengo una cuenta{" "}
                  <a
                    href="https://www.globalstartup360.com/bienvenido"
                    target="_blank"
                    rel="noreferrer"
                    style={{
                      color: "#0365A6",
                      textTransform: "uppercase",
                      fontWeight: "bold",
                      cursor: "pointer",
                    }}
                  >
                    Ingresar
                  </a>
                </p>
              </Form.Group>
            </Form.Row>
          </Form>
        </Modal.Body>
      </Modal>
    );
  } else if (status === "loading") {
    return (
      <Modal
        {...props}
        dialogClassName="modal-600w"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => setStatus(null)}
      >
        <Modal.Header
          closeButton
          style={{ padding: "15px 15px 0 0", borderBottom: "0" }}
        ></Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col md={12} className="text-center d-inline w-100  pt-0 pb-5">
                <img src={Spin} alt="Loading" className="mx-auto mb-2" />
                <h4 style={{ fontSize: "18px", textAlign: "center" }}>
                  Enviando sus datos
                </h4>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    );
  } else if (status === "ok") {
    return (
      <Modal
        {...props}
        dialogClassName="modal-600w"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <Container>
            <Row>
              <Col md={12} className="text-center d-inline w-100  pt-0 pb-5">
                <img
                  src={IMGenviadoOk}
                  alt="Loading"
                  className="mx-auto mb-2 mt-3"
                />
                <h4 style={{ fontSize: "18px", textAlign: "center" }}>
                  Revise su correo. Enviamos un e-mail para validar su identidad
                  y finalizar el proceso de registro.
                </h4>
                <button
                  className="botones btnNaranja mx-auto"
                  style={{ width: "110px", margin: "25px 0 0 0" }}
                  onClick={() =>
                    (window.location.href = "http://www.globalstartup360.com")
                  }
                >
                  Continuar
                </button>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    );
  } else if (status === "error") {
    return (
      <Modal
        {...props}
        dialogClassName="modal-600w"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => setStatus(null)}
      >
        <Modal.Header
          closeButton
          style={{ padding: "15px 15px 0 0", borderBottom: "0" }}
        ></Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col md={12} className="text-center d-inline w-100  pt-0 pb-5">
                <img
                  src={IMGenviadoERROR}
                  alt="Loading"
                  className="mx-auto mb-2"
                />
                <h4 style={{ fontSize: "18px", textAlign: "center" }}>
                  {mensaje}
                </h4>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    );
  }
}

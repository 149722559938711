import React, { useEffect, useState } from "react";
import logoGS360 from "../../assets/img/home/logoHeaderGS360.png";
import logoHeaderGS360White from "../../assets/img/logoHeaderGS360White.png";

function Logo(p) {
  const [scrolled, setScrolled] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const breakPoint = 767;

  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 80) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  });

  return width > breakPoint ? (
    <img
      alt={p.name}
      src={
        p.mode === "light"
          ? scrolled === true
            ? logoGS360
            : logoHeaderGS360White
          : logoGS360
      }
      className="d-inline-block align-top img-fluid"
    />
  ) : (
    <img
      alt={p.name}
      src={logoGS360}
      className="d-inline-block align-top img-fluid"
    />
  );
}

export default Logo;

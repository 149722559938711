import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Moment from "react-moment";
import "moment-timezone";

/* IMG */
import iconDocumentoValido from "../../assets/img/documentos/iconDocumentoValido.svg";
import iconDocumentoInvalido from "../../assets/img/documentos/iconDocumentoNoEncontrado.svg";
import Spin from "../../assets/img/Spin.svg";

import "./css/documentos.css";

/*BOOTSTRAP*/
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default function Documentos() {
  const [datos, setDatos] = useState({});
  const [status, setStatus] = useState("cargando");

  const authToken = new URLSearchParams(window.location.search);
  const token = authToken ? authToken.get("token") : null;

  //TRAIGO LOS PAISES
  useEffect(() => {
    const validateToken = () => {
      return axios
        .get(`https://api.globalstartup360.com/api/validateToken/${token}`)
        .then(function (response) {
          //console.log(response.data.data);
          setStatus(response.data.status);
          setDatos(response.data.data);
        })
        .catch(function (error) {
          console.log(error.data);
        });
    };
    validateToken();
  }, [token]);

  //console.log(datos);

  if (status === "ok") {
    return (
      <Container fluid className="mainDocumentos bgMainDocu docValido d-flex">
        <Container className="d-flex flex-grow ">
          <Row>
            <Col className="d-flex flex-grow flex-column justify-content-between">
              <Row className="d-flex align-self-center flex-row containerDocu">
                <Col
                  md={7}
                  xs={12}
                  className="d-flex justify-content-center justify-content-md-end align-content-center align-items-center pr-sm-5 pr-0"
                >
                  <div className="iconDocValido">
                    {" "}
                    <img
                      src={iconDocumentoValido}
                      alt="Documento Valido"
                      className="img-fluid"
                    />
                  </div>
                </Col>
                <Col
                  md={5}
                  xs={12}
                  className="d-flex justify-content-center  align-content-center align-items-center flex-column"
                >
                  <h2 className="subtitHero text-center text-sm-left ">
                    Este documento es válido
                  </h2>
                  <p className="text-center text-sm-left pt-4 textDocu">
                    El documento {datos.name} (ID: {datos.id}) generado en la
                    fecha{" "}
                    {datos && (
                      <Moment format="DD/MM/YYYY hh:mm">
                        {datos.created_at}
                      </Moment>
                    )}{" "}
                    es válido y se encuentra asegurado en{" "}
                    <b>Rocket Contracts.</b>
                  </p>
                </Col>
              </Row>
              <Row className="d-flex flex-column">
                <Col md={4} xs={10} className="offset-md-8 offset-1 pt-3 pb-3">
                  <p className="footerDocumento">
                    Copyright © 2021 GS360. All rights reserved.
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Container>
    );
  } else if (status === "error") {
    return (
      <Container fluid className="mainInvalido docValido d-flex">
        <Container>
          <Row className="h-100">
            <Col
              md={12}
              xs={12}
              className="d-flex flex-grow flex-column justify-content-between"
            >
              <Row className="d-flex containerDocu">
                <Col
                  md={12}
                  xs={12}
                  className="d-flex justify-content-center align-content-center align-items-center flex-column"
                >
                  <img
                    src={iconDocumentoInvalido}
                    alt="Documento no encontrado"
                    className="img-fluid"
                  />
                  <h2 className="subtitHero text-center pt-3">Ups!</h2>
                  <h2
                    className="subtitHero text-center"
                    style={{ fontSize: "30px" }}
                  >
                    No hemos encontrado el documento.
                  </h2>
                  <p className="text-center pt-0 pb-4 textDocu">
                    Si tienes dudas por favor contactanos.
                  </p>
                  <Link to="/contacto" style={{ textDecoration: "none" }}>
                    <button
                      class="botones btnNaranja mx-auto"
                      type="submit"
                      style={{ minWidth: "200px", margin: "5px 0 0 0" }}
                    >
                      Contactanos
                    </button>
                  </Link>
                </Col>
              </Row>
              <Row className="d-flex flex-column pt-5">
                <Col md={12} xs={12} className="pt-3 pb-3">
                  <p
                    className="footerDocumento"
                    style={{ margin: "0 auto", maxWidth: "300px" }}
                  >
                    Copyright © 2021 GS360. All rights reserved.
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Container>
    );
  } else if (status === "cargando") {
    return (
      <Container
        fluid
        className="mainInvalido docValido d-flex"
        style={{ backgroundColor: "white" }}
      >
        <Container>
          <Row className="h-100">
            <Col
              md={12}
              xs={12}
              className="d-flex flex-grow flex-column justify-content-between"
            >
              <Row className="d-flex containerDocu">
                <Col
                  md={12}
                  xs={12}
                  className="d-flex justify-content-center align-content-center align-items-center flex-column"
                >
                  <img src={Spin} alt="Cargando" className="img-fluid" />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Container>
    );
  }
}

import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import {
  HeaderPortada,
  HeaderSasWizard,
  HeaderRocketContracts,
  HeaderBrandineitor,
  HeaderMrBill,
  HeaderGS360Services,
  HeaderLegalManager,
  HeaderProveedores,
  HeaderRecursosDigitales,
  HeaderFaqs,
  HeaderEscribinos,
  HeaderContacto,
  Header404,
  HeaderPrecios,
  HeaderCondiciones,
  HeaderFinalizarRegistro,
  HeaderBienvenida,
  HeaderDocumentos,
} from "./componentes/Header";
import SasWizard from "./componentes/SasWizard";
import RocketContractsPortada from "./componentes/RocketContractsPortada";
import NecesitaTuNegocio from "./componentes/NecesitaTuNegocio";
import PorqueGS360 from "./componentes/PorqueGS360";
import Faqs from "./componentes/Faqs";

import Footer from "./componentes/Footer";
import ScrollToTop from "./componentes/Extra/ScrollToTop";

/* SECCIONES SASWIZARD */
import EmpresaEnTresPasos from "./componentes/SasWizard/EmpresaEnTresPasos";
import TodoIncluido from "./componentes/SasWizard/TodoIncluido";
import DatosClaves from "./componentes/SasWizard/DatosClaves";
import PreciosSasWizard from "./componentes/SasWizard/PreciosSasWizard";

/* SECCIONES ROCKETCONTRACTS */
import ControlEnTiempoReal from "./componentes/RocketContractsPortada/ControlEnTiempoReal";
import BioSeguridad from "./componentes/RocketContractsPortada/BioSeguridad";
import CuidadoMedioAmbiente from "./componentes/RocketContractsPortada/CuidadoMedioAmbiente";
import PreciosRocketContracts from "./componentes/RocketContractsPortada/PreciosRocketContracts";
/* SECCIONES BRANDINEITOR*/
import MainBrandineitor from "./componentes/Brandineitor/";
import AgregaServicios from "./componentes/Brandineitor/AgregaServicios";

/* SECCIONES MRBILLS*/
import VentajasMrBills from "./componentes/MrBills/VentajasMrBills/";
import ComoFuncionaMrBills from "./componentes/MrBills/ComoFuncionaMrBills/";
import PlanesMrBills from "./componentes/MrBills/PlanesMrBills/";

/* SECCIONES SERVICES360*/
import Services360 from "./componentes/Services360";

/* SECCIONES SERVICES360*/
import LegalManagerMain from "./componentes/LegalManager";
import AsesoramientoLegalManager from "./componentes/LegalManager/AsesoramientoLegalManager";

/* SECCIONES PROVEEDORES*/
import FiltroProveedores from "./componentes/Proveedores/FiltroProveedores";
import ResultadosProveedores from "./componentes/Proveedores/ResultadosProveedores";

/* SECCIONES RECURSOS*/
import MainRecursosDigitales from "./componentes/Recursos/MainRecursos";

/* SECCIONES FAQS*/
import MainFaq from "./componentes/Faqs/MainFaq";
import ContactoFaq from "./componentes/Faqs/ContactoFaq";

/* SECCIONES ESCRIBINOS*/
import Escribinos from "./componentes/Escribinos";

/* SECCIONES ESCRIBINOS*/
import Contacto from "./componentes/Contacto";

/* SECCION Condiciones*/
import Terminos from "./componentes/Terminos";
import Politicas from "./componentes/Politicas";

/* SECCION Finalizar Registro*/
import FinalizarRegistro from "./componentes/Registro/FinalizarRegistro";

/* SECCION Finalizar Registro*/
import Bienvenido from "./componentes/Registro/Bienvenido/";

/* SECCION Documentos */
import Documentos from "./componentes/Documentos/Documentos";

/*GENERAL CSS*/
import "./assets/css/custom.css";

/*BOOTSTRAP*/
import "bootstrap/dist/css/bootstrap.min.css";

/* GOOGLE ANALITYCS */
import useGoogleAnalytics from "./componentes/GoogleAnalitycs/UseAnalitycsHook";

function Routes() {
  useGoogleAnalytics();

  return (
    <>
      <ScrollToTop />
      <Switch>
        {/* PORTADA */}
        <Route exact path="/">
          <HeaderPortada />
          <SasWizard />
          <RocketContractsPortada />
          <NecesitaTuNegocio />
          <PorqueGS360 />
          <Faqs />
          <Footer />
        </Route>

        {/* SASWIZARD */}
        <Route exact path="/servicios/saswizard">
          <HeaderSasWizard />
          <EmpresaEnTresPasos />
          <TodoIncluido />
          <DatosClaves />
          <PreciosSasWizard />
          <Footer />
        </Route>

        {/* ROCKETCONTRACTS*/}
        <Route exact path="/servicios/rocketcontracts">
          <HeaderRocketContracts />
          <ControlEnTiempoReal />
          <BioSeguridad />
          <PreciosRocketContracts />
          <CuidadoMedioAmbiente />
          <Footer />
        </Route>

        {/* BRANDINEITOR */}
        <Route exact path="/servicios/brandineitor">
          <HeaderBrandineitor />
          <MainBrandineitor />
          <AgregaServicios />
          <Footer />
        </Route>

        {/*MR BILLS */}
        <Route exact path="/servicios/mrbill">
          <HeaderMrBill />
          <VentajasMrBills />
          <ComoFuncionaMrBills />
          <PlanesMrBills />
          <Footer />
        </Route>

        {/*360 SERVICES */}
        <Route exact path="/servicios/360services">
          <HeaderGS360Services />
          <Services360 />
          <Footer />
        </Route>

        {/*LEGAL MANAGER */}
        <Route exact path="/servicios/legalmanager">
          <HeaderLegalManager />
          <LegalManagerMain />
          <AsesoramientoLegalManager />
          <Footer />
        </Route>

        {/*Proveedores */}
        <Route exact path="/proveedores">
          <HeaderProveedores />
          <FiltroProveedores />
          <ResultadosProveedores />
          <Footer />
        </Route>

        {/*Recursos Digitales */}
        <Route exact path="/recursos-digitales">
          <HeaderRecursosDigitales />
          <MainRecursosDigitales />
          <Footer />
        </Route>

        {/*FAQS */}
        <Route exact path="/faqs">
          <HeaderFaqs />
          <MainFaq />
          <ContactoFaq />
          <Footer />
        </Route>

        {/*ESCRIBINOS */}
        <Route exact path="/contacto/escribinos">
          <HeaderEscribinos />
          <Escribinos />
          <Footer />
        </Route>

        {/*CONTACTO */}
        <Route exact path="/contacto">
          <HeaderContacto />
          <Contacto />
          <Footer />
        </Route>

        {/*PRECIOS */}
        <Route exact path="/precios">
          <HeaderPrecios />
          <Footer />
        </Route>

        {/*Terminos y condiciones */}
        <Route exact path="/terminos-y-condiciones">
          <HeaderCondiciones titulo="Términos y condiciones de uso" />
          <Terminos />
          <Footer />
        </Route>

        {/*Terminos y condiciones */}
        <Route exact path="/politica-de-proteccion-de-datos-personales">
          <HeaderCondiciones titulo="Política de protección de datos personales" />
          <Politicas />
          <Footer />
        </Route>

        {/*FINALIZAR REGISTRO */}
        <Route exact path="/finalizar-registro">
          <HeaderFinalizarRegistro />
          <FinalizarRegistro />
          <Footer />
        </Route>

        {/*FINALIZAR REGISTRO */}
        <Route exact path="/bienvenido">
          <HeaderBienvenida />
          <Bienvenido />
          <Footer />
        </Route>

        {/*FINALIZAR REGISTRO */}
        <Route exact path="/documentos">
          <HeaderDocumentos />
          <Documentos />
        </Route>

        {/*NOT MATCH */}
        <Route path="*">
          <Header404 />
          <Footer />
        </Route>
      </Switch>
    </>
  );
}

function App() {
  document.title = "GS360 - Colombia";

  return (
    <BrowserRouter>
      <Routes />
    </BrowserRouter>
  );
}

export default App;

import React from "react";
import {Link} from "react-router-dom";

/*CSS*/
import "../css/Recursos.css";

/*BOOTSTRAP*/
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";


export default function OrdenRecursos() {
  
  return (

       <Container>           
          <Row>
            <Col md={12} xs={12}>
            <div className="bgOrder">

              <h5 className="titOrder">Tu orden</h5>

                <Row>        
                    <Col md={7} xs={7}>
                      <div className="DatosOrden">
                        <p>Nombre documento para categoría Empleado</p>
                        <span>Categoría: Empleado</span>
                      </div>                 
                    </Col>
                    <Col  md={5} xs={5}>
                      <p className="textoPriceRecursos">$ 200.000</p>
                    </Col>                    
                </Row>
                
                <hr/>

                <Row>        
                    <Col md={7} xs={7}>
                      <div className="DatosOrden">
                        <p>Nombre documento para categoría Proveedores</p>
                        <span>Categoría: Proveedores</span>
                      </div>                 
                    </Col>
                    <Col  md={5} xs={5}>
                      <p className="textoPriceRecursos">$ 200.000</p>
                    </Col>                    
                </Row>
                
                <hr/>

                <Row>        
                    <Col md={7} xs={7}>
                      <div className="DatosOrden">
                        <p>Nombre documento para categoría Contrato de servicios</p>
                        <span>Categoría: Contrato de servicios</span>
                      </div>                 
                    </Col>
                    <Col  md={5} xs={5}>
                      <p className="textoPriceRecursos">$ 200.000</p>
                    </Col>                    
                </Row>
                
                <hr/>

      
                <Row className="pt-3 pb-3">
                  <Col  md={7} xs={7}>
                  <p className="bgOrderTotal">Total</p>               
                  </Col>
                  <Col  md={5} xs={5}>
                  <p className="textoPriceRecursos">$ 600.000</p>
                  </Col>
                </Row>

                <Row>
                  <Col  md={12} xs={12}>
                    <Link className="botones btnAzul mx-auto text-center" style={{width:'100%', margin:'5px 0 0 0'}}>Comprar</Link> 
                  </Col>             
                </Row>

            </div>
            </Col>                        
          </Row>
         
        </Container> 

 
  );
}

import React from "react";
import { Link } from "react-router-dom";

/*CSS*/
import "../css/SasWizard.css";

/*BOOTSTRAP*/
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function TodoIncluido() {
  const options = [
    {
      value: 1,
      tit: "Elaboración de documentos para crear y registrar la empresa.",
    },
    {
      value: 2,
      tit:
        "Cálculo y gestión para los pagos de impuestos y tasas. Ten en cuenta que las tarifas oficiales son variables según el capital de la inversión",
    },
    {
      value: 3,
      tit: "Análisis pre-registro (o de viabilidad) del nombre de la empresa.",
    },
    {
      value: 4,
      tit:
        "Obtención de número de identificación tributaria (NIT / Tax ID) y registro de libros en la Cámara de Comercio.",
    },
    {
      value: 5,
      tit:
        "Trámite y obtención del Registro Unico Tributario (RUT) ante la autoridad de impuestos de Colombia (DIAN).",
    },
    
    {
      value: 6,
      tit:
        "Envío de la documentación de la empresa constituida a tu correo postal.",
    },
  ];

  const servAdicionales = [
    { value: 1, tit: "Back Office administrativo" },
    { value: 2, tit: "Representación ante terceros" },
    { value: 3, tit: "Administración de correspondencia" },
    { value: 4, tit: "Registro de Marcas" },
    { value: 5, tit: "Administración contable" },
    {
      value: 6,
      tit:
        "Domicilio Legal (Dirección física para el registro de la empresa ante las autoridades)",
    },
    {
      value: 7,
      tit:
        "KIT Documentación y balance inicial para apertura de cuenta bancaria",
    },
  ];

  return (
    <Container className="bgTodoIncluido" fluid>
      <Container>
        <Row>
          <Col md={12} xs={12} className="text-center text-sm-left">
            <h2 className="titAzul text-center pb-4">Todo incluido</h2>
            <Row>
              {options.map((item) => (
                <Col md={6} xs={12} className="text-center">
                  <div className="caractSasWizard w-100">
                    <p className="text-center">{item.tit}</p>
                  </div>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col md={12}>
            <div className="bgServAdic w-100">
              <h3 className="titServAdic text-sm-left  text-center pb-3">
                Servicios <b>adicionales con 360º Services</b>
              </h3>
              <Row>
                <Col md={9} xs={12}>
                  <Row>
                    {servAdicionales.map((item) => (
                      <Col md={6} xs={12} className="text-center">
                        <p className="listaMasServAdic text-left">{item.tit}</p>
                      </Col>
                    ))}
                  </Row>
                </Col>
                <Col md={3} xs={12} className="text-sm-right text-center">
                  <Link
                    className="botones btnNaranjaInverse float-right"
                    style={{ width: "160px", margin: "15px 0 0 0" }}
                    to="/servicios/360services"
                  >
                    Ir a 360° Services
                  </Link>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default TodoIncluido;

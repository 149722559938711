import React from "react";
import { Link } from "react-router-dom";

/*CSS*/
import "./css/Footer.css";

/* IMG */
import logoGlobalStartupFooter from "../../assets/img/home/logoGlobalStartupFooter.svg";
import iconLinkedin from "../../assets/img/home/iconos/iconLinkedin.png";
import iconInstagram from "../../assets/img/home/iconos/iconInstagram.png";
import iconFacebook from "../../assets/img/home/iconos/iconFacebook.png";
import iconHeart from "../../assets/img/heart.png";

/*BOOTSTRAP*/
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function Footer() {
  const menu1 = [
    { value: 1, titulo: "Precios", link: "/precios" },
    { value: 2, titulo: "FAQS", link: "/faqs" },
    { value: 3, titulo: "Blog", link: "/blog" },
    { value: 5, titulo: "Contacto", link: "/contacto" },
  ];

  const menu2 = [
    { value: 1, titulo: "SAS Wizard", link: "/servicios/saswizard" },
    {
      value: 2,
      titulo: "Rocket Contracts",
      link: "/servicios/rocketcontracts",
    },
    { value: 3, titulo: "Brandineitor", link: "/servicios/brandineitor" },
    { value: 4, titulo: "MrBills", link: "/servicios/mrbill" },
    { value: 5, titulo: "Legal Manager", link: "/servicios/legalmanager" },
    { value: 6, titulo: "360º Services", link: "/servicios/360services" },
  ];

  const menu3 = [
    {
      value: 1,
      titulo: "Términos y Condiciones de Uso",
      link: "/terminos-y-condiciones",
    },
    {
      value: 2,
      titulo: "Política de protección de datos personales",
      link: "/politica-de-proteccion-de-datos-personales",
    },
  ];

  return (
    <Container className="bgFooter" fluid>
      <Container className="d-flex flex-column">
        <Row className="">
          <Col md={3} xs={12} className="text-center text-sm-left">
            <Link to="/">
              <img
                src={logoGlobalStartupFooter}
                alt=""
                class="img-fluid mb-5"
              />
            </Link>
          </Col>
          <Col md={3} xs={12} className="mb-5">
            <h5 className="titH5 text-center text-sm-left">INSTITUCIONAL</h5>
            <ul className="list-unstyled text-center text-sm-left mb-0">
              {menu1.map((item) => (
                <li>
                  <Link className="linkFooter" to={item.link}>
                    {item.titulo}
                  </Link>
                </li>
              ))}
            </ul>
          </Col>
          <Col md={3} xs={12} className="mb-5">
            <h5 className="titH5 text-center text-sm-left">SERVICIOS</h5>
            <ul className="list-unstyled text-center text-sm-left mb-0">
              {menu2.map((item) => (
                <li>
                  <Link className="linkFooter" to={item.link}>
                    {item.titulo}
                  </Link>
                </li>
              ))}
            </ul>
          </Col>
          <Col md={3} xs={12} className="mb-5">
            <h5 className="titH5 text-center text-sm-left ">LEGALES</h5>
            <ul className="list-unstyled text-center text-sm-left mb-0">
              {menu3.map((item) => (
                <li>
                  <Link className="linkFooter" to={item.link}>
                    {item.titulo}
                  </Link>
                </li>
              ))}
            </ul>
          </Col>
        </Row>
        <Row className="text-center text-sm-left d-flex justify-content-center">
          <ul className="list-unstyled list-inline">
            <li className="list-inline-item">
              <a
                href="https://www.linkedin.com/company/gs360"
                target="_blank"
                rel="noreferrer"
              >
                <img src={iconLinkedin} alt="" class="img-fluid mb-5" />
              </a>
            </li>
            <li className="list-inline-item">
              <a
                href="https://www.facebook.com/globalstartup360"
                target="_blank"
                rel="noreferrer"
              >
                <img src={iconFacebook} alt="" class="img-fluid mb-5" />
              </a>
            </li>
            <li className="list-inline-item">
              <a
                href="https://www.instagram.com/globalstartup360/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={iconInstagram} alt="" class="img-fluid mb-5" />
              </a>
            </li>
          </ul>
        </Row>
        <Row className="d-flex justify-content-center mt-auto mb-5">
          <Col md={12}>
            <p className="text-center textFooter">
              Copyright © {new Date().getFullYear()}, Global Startup Colombia
              S.A.S. (GS360) NIT: 901.398.616-3. Todos los derechos reservados.
              Hecho de <img src={iconHeart} alt="" style={{ width: "12px" }} />{" "}
              en Bogotá, Colombia.
            </p>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Footer;

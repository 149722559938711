import React, { useState } from "react";

/* IMG */

import logoRocketContracts from "../../../assets/img/rocketContracts/dark/logo.png";
import ModalContacto from "../../Contacto/ModalContacto";
/*CSS*/
import "../css/RocketContracts.css";

/*BOOTSTRAP*/
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default function PreciosRocketContracts() {
  const [modalContactoShow, setModalContactoShow] = useState(false);

  const optionsRocket = [
    { value: 1, tit: "Aumenta tu eficiencia" },
    { value: 2, tit: "Crea plantillas personalizadas" },
    { value: 3, tit: "Seguridad" },
    { value: 4, tit: "Seguimiento de los documentos" },
  ];

  const selectedItems = [{ id: 0, titulo: "Firma de Documentos", precio: 500 }];
  const totalPrice = 500;

  return (
    <Container>
      <Row>
        <Col md={12} xs={12} className="mb-5 ">
          <div className="fndBoxOpciones">
            <img
              src={logoRocketContracts}
              alt="Rocket Contracts"
              className="mb-3"
            />
            <p className="mhTxtPrec" style={{ minHeight: "auto" }}>
              Crea documentos, organízalos por categorías, envíalos uno a uno o
              masivamente a miles de personas para su firma.
            </p>
            <Row>
              <Col md={12} xs={10} className="mx-auto">
                <Row className="list-unstyled listaOptionsRocket mb-4">
                  {optionsRocket.map((item) => (
                    <Col className="col-md-6 col-12">
                      <p>{item.tit}</p>
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>
            <p className="precioOpcion">
              Desde
              <b style={{ fontSize: "1.4rem" }}> $ 188.000 </b>
              <span>
                IVA Incluido (Incluye hasta 5 documentos mensuales para firmar
                electrónicamente.)
              </span>
            </p>
            <button
              onClick={() => setModalContactoShow(true)}
              className="botones btnAzulExtra mx-auto mx-sm-0 w100xsOption"
              style={{ margin: "15px 0 0 0" }}
            >
              Registrate Gratis
            </button>
          </div>
        </Col>
      </Row>
      <ModalContacto
        datos={selectedItems}
        total={totalPrice}
        show={modalContactoShow}
        onHide={() => setModalContactoShow(false)}
      />
    </Container>
  );
}

import React, { useState } from "react";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

import emailjs from "emailjs-com";
import apiKeys from "../../../extra/apikeys.js";

/* IMG */
import Spin from "../../../assets/img/Spin.svg";
import IMGenviadoOk from "../../../assets/img/enviadoOK.png";
import IMGenviadoERROR from "../../../assets/img/enviadoERROR.png";

/*CSS*/
import "../css/Contacto.css";

/*BOOTSTRAP*/
import Modal from "react-bootstrap/Modal";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";

export default function ModalLlamada(props) {
  const [value, setValue] = useState();
  const [validated, setValidated] = useState(false);

  const [status, setStatus] = useState(null);

  function refreshPage() {
    window.location.reload(false);
  }

  const onSubmit = (e) => {
    const form = e.currentTarget;
    let localValidated;
    e.preventDefault();
    if (form.checkValidity() === false) {
      localValidated = false;
    } else {
      setValidated(true);
      localValidated = true;

      if (localValidated === true) {
        setStatus("loading");

        emailjs
          .sendForm(
            "service_GS360",
            apiKeys.TEMPLATE_LLAMADA,
            e.target,
            apiKeys.USER_ID
          )

          .then(
            (result) => {
              setStatus("ok");
            },

            (error) => {
              setStatus("error");
            }
          );
      }
    }
  };

  if (status === null) {
    return (
      <Modal
        {...props}
        dialogClassName="modal-350w"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header
          closeButton
          style={{ padding: "15px 15px 0 0", borderBottom: "0" }}
        ></Modal.Header>
        <Modal.Body>
          <h4 className="text-center" style={{ color: "#0365A6" }}>
            Solicite una llamada
          </h4>

          <Form
            className="text-left pt-4"
            noValidate
            validated={validated}
            onSubmit={onSubmit}
          >
            <Form.Row>
              <Form.Group as={Col} md={12} xs={12} controlId="formGridNombre">
                <Form.Label>Nombre y apellido</Form.Label>
                <Form.Control
                  type="text"
                  placeholder=""
                  id="name"
                  name="name"
                  required
                />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} md={12} xs={12} controlId="formGridApellido">
                <Form.Label>Teléfono</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="+57 XXXXXXXXXX"
                  id="phone"
                  name="phone"
                  required
                />

                {/*
                  <PhoneInput
                    placeholder="Teléfono"
                    id="phone"
                    name="phone"
                    value={value}
                    onChange={setValue}
                    required
                  />*/}
              </Form.Group>
            </Form.Row>
            <button
              class="botones btnNaranja mx-auto w-100"
              type="submit"
              style={{ minWidth: "200px", margin: "5px 0 0 0" }}
            >
              Llamarme
            </button>
          </Form>
        </Modal.Body>
      </Modal>
    );
  } else if (status === "loading") {
    return (
      <Modal
        {...props}
        dialogClassName="modal-600w"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => setStatus(null)}
      >
        <Modal.Header
          closeButton
          style={{ padding: "15px 15px 0 0", borderBottom: "0" }}
        ></Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col md={12} className="text-center d-inline w-100  pt-0 pb-5">
                <img src={Spin} alt="Loading" className="mx-auto mb-2" />
                <h4 style={{ fontSize: "18px", textAlign: "center" }}>
                  Enviando Mensaje...
                </h4>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    );
  } else if (status === "ok") {
    return (
      <Modal
        {...props}
        dialogClassName="modal-600w"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => refreshPage()}
      >
        <Modal.Header
          closeButton
          style={{ padding: "15px 15px 0 0", borderBottom: "0" }}
        ></Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col md={12} className="text-center d-inline w-100  pt-0 pb-5">
                <img
                  src={IMGenviadoOk}
                  alt="Loading"
                  className="mx-auto mb-2"
                />
                <h4 style={{ fontSize: "18px", textAlign: "center" }}>
                  Su mensaje fue enviado correctamente
                </h4>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    );
  } else if (status === "error") {
    return (
      <Modal
        {...props}
        dialogClassName="modal-600w"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => setStatus(null)}
      >
        <Modal.Header
          closeButton
          style={{ padding: "15px 15px 0 0", borderBottom: "0" }}
        ></Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col md={12} className="text-center d-inline w-100  pt-0 pb-5">
                <img
                  src={IMGenviadoERROR}
                  alt="Loading"
                  className="mx-auto mb-2"
                />
                <h4 style={{ fontSize: "18px", textAlign: "center" }}>
                  Se ha producido un error al enviar su consulta
                </h4>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    );
  }
}

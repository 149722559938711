import React from "react";

/* IMG */
import iconCalendario from "../../../assets/img/sasWizard/iconCalendario.png";
import iconEjecutivo from "../../../assets/img/sasWizard/iconEjecutivo.png";
import iconPrivacidad from "../../../assets/img/sasWizard/iconPrivacidad.png";
import iconTransparencia from "../../../assets/img/sasWizard/iconTransparencia.png";

/*CSS*/
import "../css/SasWizard.css";

/*BOOTSTRAP*/
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function EmpresaEnTresPasos() {
  const options = [
    {
      value: 1,
      img: iconCalendario,
      alt: "Apertura de Empresas",
      tit: "Tu empresa en Colombia \n en 2 semanas.",
    },
    {
      value: 2,
      img: iconTransparencia,
      alt: "100% transparencia en costos, siempre low-cost.",
      tit: "100% transparencia en costos, \n siempre low-cost.",
    },
    {
      value: 3,
      img: iconPrivacidad,
      alt: "Confidencialidad sobre todo y para todo. ",
      tit: "Confidencialidad sobre todo \n y para todo. ",
    },
    {
      value: 4,
      img: iconEjecutivo,
      alt: "Ejecutivo de cuentas disponible todo el año.",
      tit: "Ejecutivo de cuentas disponible \n todo el año.",
    },
  ];

  return (
    <Container className="heroEmpresaLista" fluid>
      <Container>
        <Row>
          <Col md={12} xs={12} className="text-center text-sm-left">
            <h2 className="titHero  text-center pb-0">Tu empresa lista</h2>
            <h2 className="subtitHero  text-center pb-0">en 3 pasos</h2>

            <p className="heroText  text-center pt-4">
              Obtendrás la documentación oficial, la identificación tributaria y{" "}
              <br className="d-none d-sm-block" />
              asesoramiento para la apertura de la cuenta bancaria ante el Banco{" "}
              <br className="d-none d-sm-block" />
              que más se ajuste tus necesidades
            </p>

            <Row>
              <Col md={8} xs={12} className="mx-auto">
                <p className="heroSubText  text-center pt-4">
                  Nos enfocamos principalmente en la constitución de Sociedades
                  por Acciones Simplificada (SAS).
                </p>

                <p className="heroSubText  text-center pt-4">
                  Su “simplicidad” en los trámites, documentos, manejo y
                  operaciones comerciales es el vehículo empresarial ideal para
                  comenzar un emprendimiento en Colombia.
                </p>

                <p className="heroSubText  text-center pt-4">
                  Los accionistas (Shareholders) responden limitadamente
                  respecto de sus aportes y puedes constituirla así seas un solo
                  accionista.{" "}
                </p>
              </Col>
            </Row>

            <Row>
              {options.map((item) => (
                <Col md={3} xs={12} className="text-center mt-5">
                  <img src={item.img} className="mx-auto mb-1" alt={item.alt} />
                  <p className="text-center">{item.tit}</p>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default EmpresaEnTresPasos;

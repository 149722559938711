import React, { useState } from "react";

/*COMPONENTES */
import BoxesContacto from "./BoxesContacto";

import ModalContacto from "../Contacto/ModalContacto";

/*CSS*/
import "./css/Contacto.css";

/*IMAGENES*/

/*BOOTSTRAP*/
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default function Contacto() {
  const [modalContactoShow, setModalContactoShow] = useState(false);

  return (
    <>
      <BoxesContacto clase="contBoxContactoCorto" />

      <Container className="bgContactoFooter" fluid>
        <Container>
          <Row>
            <Col md={7} xs={12} className="mx-auto pb-5">
              <h1 className="titHero text-center">Haz tus negocios.</h1>
              <h2 className="subtitHero text-center">
                Nosotros hacemos el resto
              </h2>
              <p className="heroText text-center p-4">
                Empieza el 2023 haciendo real el emprendimiento de tus sueños!
                Estamos transformando la experiencia de llevar adelante
                cuestiones legales y procesos de back-office en Colombia.
                Queremos que iniciar, mantener y expandir tu negocios sea
                simple, seguro y a un costo justo.
              </p>
              <button
                className="botones btnNaranja mx-auto"
                style={{ width: "110px", margin: "15px 0 0 0" }}
                onClick={() => setModalContactoShow(true)}
              >
                Comenzar
              </button>
            </Col>
          </Row>
        </Container>
      </Container>
      <ModalContacto
        show={modalContactoShow}
        onHide={() => setModalContactoShow(false)}
      />
    </>
  );
}

import React, { useState } from "react";

/* IMG */
import imgSasWizars from "../../assets/img/home/imgSasWizars.svg";
import portadaSasHome from "../../assets/img/home/portadaSasHome.jpg";
import ModalContacto from "../Contacto/ModalContacto";
/*CSS*/
import "./css/SasWizard.css";

/*BOOTSTRAP*/
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function Hero() {
  const [modalContactoShow, setModalContactoShow] = useState(false);

  const selectedItems = [
    { id: 0, titulo: "SasWizard - Crear empresa", precio: 500000 },
  ];
  const totalPrice = 500000;

  const options = [
    { value: 1, tit: "Crea tu empresa en línea." },
    { value: 2, tit: "Realizamos todos los trámites necesarios para tí. " },
   
    {
      value: 3,
      tit:
        "Recibes en tu casa un paquete con toda la \ndocumentación de tu empresa lista para operar.",
    },
  ];

  return (
    <Container className="heroSAS" fluid>
      <Container>
        <Row>
          <Col md={8} xs={12} className="text-center text-sm-left">
            <img
              src={imgSasWizars}
              className="img-fluid mb-4 mx-auto mx-sm-0"
              alt="SAS Wizard"
            />

            <h2 className="titHero text-sm-left text-center pb-0">
              Creamos tu empresa
              <br className="d-none d-sm-block" />
              en Colombia, en 1<br className="d-none d-sm-block" /> semana.
              </h2>

            <p className="heroText text-sm-left text-center pt-4">
              Concéntrate en tus negocios, deja los trámites
              <br className="d-none d-sm-block" /> engorrosos en nuestras manos.
            </p>

            <Row>
              <Col md={8} sm={10} xs={10} className="mx-auto mx-sm-0">
                <ul className="list-unstyled listaRocket mt-4 mb-5">
                  {options.map((item) => (
                    <li>
                      <p>{item.tit}</p>
                    </li>
                  ))}
                </ul>
              </Col>
            </Row>
            <Row>
              <Col md={3} sm={6} xs={12}>
                <button
                  onClick={() => setModalContactoShow(true)}
                  className="botones btnAzul mx-auto mx-sm-0"
                  style={{ width: "170px", margin: "0" }}
                >
                  Comienza Ahora
                </button>
              </Col>
              <Col md={8} sm={6} xs={12}>
                <p className="precioPorContrato text-center text-sm-left pt-1">
                  Desde $ 935.000 PROMO %5 Desc.
                  <span
                    style={{
                      fontWeight: "normal",
                      fontSize: "12px",
                      color: "#000",
                    }}
                  >
                    <br></br>
                    (No incluye tasas de la
                    cámara de comercio.)
                  </span>
                </p>
              </Col>
            </Row>
          </Col>
          <Col md={4} xs={12} className="text-center text-sm-left">
            <img src={portadaSasHome} className="heroSASImg" alt="GS360" />
          </Col>
        </Row>
      </Container>
      <ModalContacto
        datos={selectedItems}
        total={totalPrice}
        show={modalContactoShow}
        onHide={() => setModalContactoShow(false)}
      />
    </Container>
  );
}

export default Hero;

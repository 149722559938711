import React from "react";

import grafico1 from "../../assets/img/grafico-1.png";
import grafico2 from "../../assets/img/grafico-2.png";
import Modal from "react-bootstrap/Modal";

/*CSS*/
import "../Terminos/css/Terminos.css";

/*BOOTSTRAP*/
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default function Politicas() {
  const [grafico1Show, setGrafico1Show] = React.useState(false);
  const [grafico2Show, setGrafico2Show] = React.useState(false);

  return (
    <Container>
      <Row className="pt-4 seccionTerminos">
        <Col md={12} xs={12} className="text-justify">
          <p className="text-justify">
            <b>GLOBAL STARTUP COLOMBIA 360 S.A.S. (En lo sucesivo GS360)</b>,
            sociedad por acciones simplificada cuyo objeto principal es “podrá
            realizar cualquier actividad lícita, de carácter comercial o civil,
            de conformidad con lo establecido en las normatividades vigentes,
            pero desempeñará como actividad principal el servicio de{" "}
            <b>
              “Actividades de desarrollo de sistemas informáticos
              (planificación, análisis, diseño, programación, pruebas) - (6201),
              dentro y fuera del territorio colombiano”
            </b>
            , para la intermediación en la prestación de servicios legales y
            administrativos a través de plataformas digitales, con lo cual, a
            través de la presente política de tratamiento de datos personales
            determina la necesidad proteger y regular el tratamiento de la
            información personal de todas aquellas personas con las que tiene
            relaciones comerciales de cualquier índole y del público en general,
            los medios y mecanismos necesarios que permitan garantizar la
            seguridad, confidencialidad, y tratamiento de la información que
            reposa, reposaría o podría reposar en sus bases de datos (propias
            y/o administradas por terceros)de acuerdo con las finalidades
            establecidas en el presente documento. Así las cosas ponemos en
            conocimiento del público en general, la siguiente Política de
            Tratamiento de Datos Personales y de la Información de acuerdo con
            lo siguiente: Política de Tratamiento de Datos Personales
          </p>
          <h4>I. DEFINICIONES BÁSICAS</h4>
          <p>
            • <b>AUTORIZACIÓN:</b> Consentimiento previo, expreso e informado
            del Titular para llevar a cabo el Tratamiento de datos personales la
            cual deberá ser obtenida por cualquier medio que pueda ser objeto de
            consulta posterior.
          </p>
          <p>
            • <b>AVISO DE PRIVACIDAD:</b> Comunicación escrita o verbal que
            informa inequívocamente a los titulares de la información, la
            realización por distintos medios o formas de recolección,
            almacenamiento, uso, circulación, modificación, actualización,
            supresión, procesamiento, intercambio, transferencia y/o transmisión
            de datos personales por parte de GS360, en cualquier medio físico,
            virtual, digital, espacial, que serán depositados en las bases de
            datos, de acuerdo con los lineamientos establecidos en la política
            de tratamiento de datos personales y de la información.
          </p>
          <p>
            • <b>BASE DE DATOS:</b> Conjunto organizado de datos personales que
            sea objeto de Tratamiento; su periodo de vigencia será determinado
            unilateralmente por GS360 salvo solicitud de supresión del dato
            personal por parte del titular de la información.
          </p>
          <p>
            • <b>DATO PERSONAL:</b> Cualquier información vinculada o que pueda
            asociarse a una o varias personas naturales determinadas o
            determinables como el nombre, número de identificación, imagen,
            huella dactilar o rasgos físicos;
          </p>
          <p>
            • <b>DATO PÚBLICO:</b> Aquellos datos contenidos en documentos
            públicos como los relativos al estado civil de las personas, nombre
            completo, número de identificación independientemente del tipo de
            documento que lo acredite, profesión, oficio o actividad comercial o
            publica, dirección comercial, teléfono comercial y en general
            aquellos datos contenidos en documentos públicos, registros
            públicos, gacetas, boletines oficiales y sentencia judiciales que no
            estén sometidos a reserva, y que por disposición legal esta
            categoría de datos no necesita autorización del titular para su
            tratamiento.
          </p>
          <p>
            • <b>DATO PRIVADO:</b> Es el dato de naturaleza íntima o reservada
            sólo es relevante para el titular. El gusto, preferencias de
            consumo, sitios de navegación, tendencia de consumo y en general los
            datos que identifican aspectos que solo el titular de la información
            puede establecer como relevante. Por disposición legal, este tipo de
            datos necesita autorización del titular para su tratamiento.
          </p>
          <p>
            • <b>DATO SEMIPRIVADO:</b> Es semiprivado el dato que no tiene
            naturaleza íntima, reservada, ni pública y cuyo conocimiento o
            divulgación puede interesar no sólo a su titular sino a cierto
            sector o grupo de personas o de la sociedad en general, como el dato
            financiero y crediticio, los relacionados con la actividad comercial
            o de prestación de servicios; correo electrónico, número teléfono
            personal, dirección de residencia. Por disposición legal, este tipo
            de datos necesita autorización del titular para su tratamiento.
          </p>
          <p>
            • <b>DATO SENSIBLE Y TRATAMIENTO ESPECIAL:</b> Aquel dato que afecta
            la intimidad del Titular o cuyo uso indebido puede generar
            discriminación, como origen racial o étnico, la orientación
            política, convicciones religiosas o filosóficas; pertenencia a
            organizaciones sindicales, sociales, comunitarias ambientales, o de
            derechos humanos, políticas o que promueva interés(es) de cualquier
            movimiento(s) ciudadano(s) o partido(s) políticos; los datos
            relacionados con la salud, la vida sexual y los datos biométricos
            son ejemplos claros de datos sensibles. Por disposición legal, este
            tipo de datos necesita autorización previa, expresa, libre e
            inequívoca del titular de la información para su tratamiento, y no
            se encuentra obligado a suministrarla, en caso de que sea solicitada
            por parte de GS360 en su calidad de Responsable del Tratamiento.
          </p>
          <p>
            • <b>ENCARGADO DEL TRATAMIENTO:</b> Persona natural o jurídica,
            pública o privada que por sí mismo o por interpuesta persona,
            realiza el Tratamiento de datos personales por cuenta del
            Responsable del Tratamiento. Para el tratamiento de información a
            través del servicio de RocketContract® o aquellos que ejerza bajo la
            modalidad de Cloud Computing, GS360 será el Encargado del
            Tratamiento de la Información.
          </p>
          <p>
            • <b>RESPONSABLE DEL TRATAMIENTO:</b> Persona natural o jurídica,
            pública o privada, que por sí misma o en asocio con otros, decida
            sobre la base de datos y/o el Tratamiento de los datos. El
            tratamiento de información a través del servicio de RocketContract®
            o aquellos que ejerza bajo la modalidad de Cloud Computing, los
            Clientes serán los Responsables del Tratamiento de la Información.
            En los demás servicios que no se realicen bajo la modalidad de Cloud
            Computing, GS360 será el Responsable del Tratamiento de la
            Información.
          </p>
          <p>
            • <b>TITULAR:</b> Persona natural cuyos datos personales sean objeto
            de Tratamiento;
          </p>
          <p>
            • <b>TRATAMIENTO:</b> Cualquier operación o conjunto de operaciones
            sobre datos personales, tales como la recolección, almacenamiento,
            uso, circulación o supresión.
          </p>
          <p>
            • <b>TRANSFERENCIA:</b> Operación realizada por GS360 en condición
            de Responsable o de Encargado (según tipo de servicio) transfiere a
            un tercero (determinado o determinable) la información objeto de
            tratamiento de datos, bajo circulación de información con terceros
            (receptores) que pueden ubicarse al interior o por fuera del país.
          </p>
          <h4>II. PRINCIPIOS RECTORES</h4>
          <div className="table-responsive">
            <table className="table table-stripped">
              <tr>
                <td style={{ width: "50%" }}>
                  <b>
                    Principio de legalidad en materia de Tratamiento de datos:
                  </b>{" "}
                  El Tratamiento a que se refiere la normatividad vigente es una
                  actividad reglada que debe sujetarse a lo establecido en ella
                  y en las demás disposiciones que la desarrollen;
                </td>
                <td>
                  <b>Principio de finalidad:</b> El Tratamiento debe obedecer a
                  una finalidad legítima de acuerdo con la Constitución Política
                  y la Ley, la cual debe ser informada al Titular;
                </td>
              </tr>
              <tr>
                <td>
                  <b>Principio de libertad:</b> El Tratamiento sólo puede
                  ejercerse con el consentimiento, previo, expreso e informado
                  del Titular. Los datos personales no podrán ser obtenidos o
                  divulgados sin previa autorización, o en ausencia de mandato
                  legal o judicial que releve el consentimiento;
                </td>
                <td>
                  <b>Principio de veracidad o calidad:</b> La información sujeta
                  a Tratamiento debe ser veraz, completa, exacta, actualizada,
                  comprobable y comprensible. Se prohíbe el Tratamiento de datos
                  parciales, incompletos, fraccionados o que induzcan a error;
                </td>
              </tr>
              <tr>
                <td>
                  <b>Principio de transparencia:</b> En el Tratamiento debe
                  garantizarse el derecho del Titular a obtener del Responsable
                  del Tratamiento o del Encargado del Tratamiento, en cualquier
                  momento y sin restricciones, información acerca de la
                  existencia de datos que le conciernan;
                </td>
                <td>
                  <b>Principio de acceso y circulación restringida:</b> El
                  Tratamiento sólo podrá hacerse por personas autorizadas por el
                  Titular y/o por las personas previstas en la normatividad
                  vigente. Los datos personales, salvo la información pública,
                  no podrán estar disponibles fácilmente a través de la página
                  web u otros medios de información o comunicación, salvo que el
                  acceso sea técnicamente controlable para brindar un
                  conocimiento restringido sólo a los Titulares o terceros
                  autorizados conforme a la normatividad vigente;
                </td>
              </tr>
              <tr>
                <td>
                  <b>Principio de seguridad:</b> La información sujeta a
                  Tratamiento por el Responsable del Tratamiento o Encargado del
                  Tratamiento a que se refiere la normatividad vigente, se
                  deberá manejar con las medidas técnicas, humanas y
                  administrativas que sean necesarias para otorgar seguridad a
                  los registros evitando su adulteración, pérdida, consulta, uso
                  o acceso no autorizado o fraudulento;
                </td>
                <td>
                  <b>Principio de confidencialidad:</b> Todas las personas que
                  intervengan en el Tratamiento de datos personales que no
                  tengan la naturaleza de públicos están obligadas a garantizar
                  la reserva de la información, inclusive después de finalizada
                  su relación con alguna de las labores que comprende el
                  Tratamiento, pudiendo sólo realizar suministro o comunicación
                  de datos personales cuando ello corresponda al desarrollo de
                  las actividades autorizadas en la normatividad vigente y en
                  los términos de la misma.
                </td>
              </tr>
            </table>
          </div>
          <h4>III. OBJETIVOS</h4>
          <p>
            Establecer los criterios para la recolección, almacenamiento, uso,
            circulación, supresión de la información personal de todas aquellas
            personas naturales que tengan relación directa o indirecta con la
            Organización, estableciendo los parámetros de confidencialidad, buen
            manejo y respeto sobre el manejo de los datos personales depositados
            en nuestras bases de datos (propias o de terceros), e ilustrando el
            procedimiento que podrán ejercer los titulares de la información,
            con el fin de conocer, actualizar, rectificar, modificar o suprimir
            la información que sea depositada en las bases de datos de acuerdo
            con lo establecido por la Ley 1581 de 2012, sus Decretos
            Reglamentarios 1377 de 2013 y 886 de 2014, así como la normatividad
            vigente en Colombia.
          </p>
          <h4>IV. ALCANCE</h4>
          <p>
            Ésta política aplica para toda la información personal registrada en
            las bases de datos de GS360 y es de obligatorio cumplimiento tanto
            para el Visitante, Usuario o de los Titulares de la Información que
            otorguen su consentimiento para la recolección, almacenamiento, uso,
            circulación, modificación, actualización, supresión, procesamiento,
            intercambio, transferencia y/o transmisión de datos personales con
            los cuales tiene, ha tenido y/o posiblemente tendrá una relación
            directa o indirecta de carácter comercial, y que se encuentren
            depositados o almacenados en nuestras páginas web, aplicaciones
            móviles, servidores y en general en nuestras Bases de Datos.{" "}
          </p>
          <p>
            GS360, por sí mismo o por interpuesta persona, realizará el
            Tratamiento de la Información obtenida en forma responsable, honesta
            y transparente y se obliga con el Titular de la Información de
            informar, las actualizaciones que realice de esta Política de
            Tratamiento de Datos Personales, así como de implementar el
            procedimiento para la supresión de los datos personales que hayan
            sido debidamente solicitados por los Titulares de la Información.
          </p>
          <h4>V. TRATAMIENTO DE LOS DATOS Y DE LA INFORMACIÓN POR GS360.</h4>
          <p>
            <b> Tratamiento de los Datos Personales y la información.</b> GS360
            solicitará la autorización para el tratamiento de datos personales,
            así como informará a través de los Avisos de Privacidad, la
            realización del tratamiento de los datos personales y de la
            información por cualquier medio. En el evento en que se acceda a las
            plataformas tecnológicas y digitales de GS360, se solicitará la
            autorización previa a cualquier tipo de trámite en etapa
            precontractual para poder acceder a los servicios ofrecidos por
            GS360. El tratamiento de los datos personales y la información por
            parte de GS360, es indispensable para el desarrollo de su actividad
            empresarial, acceso y prestación de los servicios por parte de todas
            las personas que intervienen en dicho fin, bien sea Usuarios,
            Visitantes, Trabajadores, Directores o Miembros del Staff de GS360.
          </p>
          <p>
            <b>Formas de Tratamiento de los Datos Personales.</b> GS360 realiza
            el Tratamiento de los datos y la información a través de los
            siguientes medios:
          </p>
          <p>
            ▪ Acceso a las plataformas digitales GS360, ▪ Intercambio de
            mensajes de datos como correos electrónicos, mensajes de texto,
            mensajes a través de plataformas digitales de comunicación, Redes
            Sociales entre otros.
          </p>
          <p>
            ▪ A través del acceso a las Bases de Datos Pública que disponen los
            Estados, como por ejemplo las de las cámaras de comercio.
          </p>
          <p> ▪ A través de ofertas de servicios realizadas a GS360.</p>
          <p>▪ A través de los datos de sus Accionistas.</p>
          <p>
            ▪ A través de los Registros Públicos disponibles por los Estados.
          </p>
          <p>▪ A través de reuniones virtuales sincrónicas o simultaneas.</p>
          <p> ▪ A través de portafolios de servicios. </p>
          <p>▪ A través de las hojas de vida de candidatos. </p>
          <p>▪ A través de entrevistas de trabajo. </p>
          <p>
            ▪ A través de HeadHunters o caza talentos a quienes los titulares
            les hayan otorgado suministrado sus datos.
          </p>
          <p>
            ▪ Mediante formatos de afiliación a la EPS, ARL y demás entidades
            del Sistema General de Seguridad Social.
          </p>
          <p>
            ▪ A través del contrato de trabajo o de prestación de servicios.
          </p>
          <p>
            ▪ A través de encuestas, eventos sociales, corporativos y
            empresariales.
          </p>
          <p>▪ A través de su red de networking entre sus Usuarios. </p>
          <p>
            ▪ A través de referenciación por parte de otros clientes, aliados,
            accionistas, directores, trabajadores entre otros.
          </p>
          <p>▪ A través del uso de cookies en sus plataformas digitales.</p>
          <p>
            <b>Privacidad de la información.</b> El Equipo de GS360, desde sus
            Fundadores, Directores y Trabajadores estamos comprometidos con la
            confidencialidad, privacidad y seguridad de la información personal
            depositada en nuestras bases de datos, para lo cual hemos
            implementado
            <b>
              {" "}
              mecanismos de seguridad, restricción de acceso, uso, circulación y
              reproducción de la información que nos has brindado
            </b>
            ; medidas de seguridad que aplican tanto para nuestra Organización,
            como para los visitantes, Usuarios, clientes, Contratistas o
            Subcontratistas entre otros.
          </p>
          <p>
            <b>Mecanismos de acceso y seguridad de los datos personales. </b>{" "}
            Conservamos la información en repositorios seguros que gozan de
            mecanismos de seguridad y acceso a la información tanto físicos como
            digitales. Estos repositorios no serán revelados ni conocidos sino
            por un grupo específico de personas que podrán tener acceso físico,
            en donde se custodia la información encriptada de tus datos,
            autorizaciones e información asociadas a tu cuenta de usuario.
            Conservamos y custodiamos fiel copia de las autorizaciones para el
            tratamiento de datos personales, la cual podrá conocer en cualquier
            momento y revocar en cualquier tiempo cuando se cumplan con los
            requisitos establecidos en el{" "}
            <i>
              Procedimiento para la corrección, modificación, actualización y/o
              supresión de datos
            </i>
          </p>
          <p>
            Para acceder a los servicios de GS360, siempre tendrás que
            registrarte como Usuario independientemente del rol con el cual
            hayas sido vinculado en nuestras bases de datos, contarás con un
            Usuario, Contraseña y mecanismos de doble y hasta triple
            verificación de identidad en donde podríamos utilizar inclusive
            rasgos faciales o biométricos para acreditar la información y
            titularidad de quien accede a nuestras bases de datos, así como
            acuerdos de confidencialidad de la información suscrito con nuestro
            personal, contratistas, subcontratistas y aliados.
          </p>
          <h4>VI. FINALIDADES DEL TRATAMIENTO DE LA INFORMACIÓN. </h4>
          <p>
            En el desarrollo del tratamiento de la información por parte de
            GS360, se determina de manera prevalente las siguientes finalidades
            respecto del tratamiento de la información personal que se almacena
            en sus bases de datos.
          </p>
          <p>
            <b>FINALIDADES FRENTE AL CLIENTE.</b>
          </p>
          <p>• Para desarrollar las actividades empresariales de GS360.</p>{" "}
          <p>
            • Para cumplir el objeto contractual con nuestros clientes (actuales
            o potenciales).
          </p>{" "}
          <p>
            • Para conocer las necesidades, particularidades y posibles
            soluciones que podamos brindarles a los clientes, a través de
            nuestros servicios.
          </p>{" "}
          <p>
            • Para desarrollar actividades comerciales, ampliación de clientela
            (nueva) y fidelización de clientela (actual o potencial).
          </p>{" "}
          <p>
            • Para desarrollar el tratamiento de datos personales sensibles
            principalmente biométricos que permitan establecer, desarrollar y
            cumplir los protocolos de identificación, validación y acceso de
            nuestros Clientes y Usuarios, a las plataformas tecnológicas y
            servicios ofrecidos por GS360, que permitan garantizar la seguridad
            y validación de los controles de acceso a la información.
          </p>{" "}
          <p>
            • Para desarrollar actividades de marketing, publicidad y promoción
            de los productos ofrecidos GS360 o por Terceros con quienes sostenga
            una relación comercial.
          </p>{" "}
          <p>
            • Para realizar invitaciones a eventos y ofrecer nuevos productos de
            GS360 teniendo como base la promoción y venta de los productos
            (propios o de terceros), contacto directo con clientes habituales,
            potenciales y/o prospectos de clientes, entre otras.
          </p>{" "}
          <p>
            • Para realizar por sí mismo o en alianza con un tercero, análisis
            de datos e investigación de mercados, de productos y servicios de
            GS360, por cualquier medio de comunicación vigente.
          </p>{" "}
          <p>
            • Para adelantar las gestiones comerciales, facturación, estado de
            cartera, cobro, recaudo de las obligaciones dinerarias a cargo del
            cliente.{" "}
          </p>{" "}
          <p>
            • Para adelantar gestiones de conocimiento de cliente, acceso a la
            información depositada en Centrales de Riesgo.{" "}
          </p>{" "}
          <p>
            • Para Transferir datos personales dentro y fuera del país con
            terceros determinados o indeterminados con quienes deba cumplir
            regulaciones relacionadas con el Lavado de Activos; Financiación del
            Terrorismo, Producción de Armas de Destrucción Masiva, Procesamiento
            de Datos, obligaciones contractuales, campañas conjuntas y en
            general cualquier actividad relacionada con las actividades
            empresariales realizadas por GS360.
          </p>
          <p>
            <b>FINALIDADES INTERNAS DE LA ORGANIZACIÓN</b>
          </p>
          <p>
            • Para las gestiones administrativas de GS360 con sus Accionistas,
            Asesores, Directores, Gerentes, Empleados, Contratistas,
            Subcontratistas que permitan desarrollar su actividad empresarial y
            objeto social.
          </p>{" "}
          <p>
            • Para realizar las gestiones de recursos humanos; pagos de nómina;
            personal; prestaciones sociales; promoción y selección de personal;
            custodia de hojas de vida así como certificaciones laborales,
            capacitaciones y formación del talento humano, disciplinarios entre
            otras.
          </p>
          <p>
            {" "}
            • Para realizar gestiones contractuales, laborales, contables,
            fiscales y administrativas en asuntos relacionados con los clientes,
            proveedores, cobros de cartera, pagos, contabilidad, verificación de
            requisitos jurídicos, técnicos y/o financieros entre otras.;
          </p>
          <p>
            {" "}
            • Gestión técnica y administrativa respecto de la administración de
            sistemas de información; desarrollo operativo; envío de
            comunicaciones entre otras.;{" "}
          </p>
          <p>
            • Para ofrecer programas de bienestar corporativo, planificación de
            actividades empresariales y actividades con el talento humano
            incluyendo sus beneficiarios.{" "}
          </p>
          <p>
            • Para desarrollar el tratamiento de datos personales sensibles
            principalmente biométricos que permitan establecer, desarrollar y
            cumplir los protocolos de identificación, validación y acceso a las
            plataformas tecnológicas, Bases de Datos, Páginas Web y en general a
            los activos tangibles e intangibles de GS360, con el fin de
            garantizar la seguridad y validación de los controles de acceso a la
            información.{" "}
          </p>
          <p>
            • Transferir datos personales dentro y fuera del país con
            organizaciones privadas o públicas con quienes tenga una relación
            contractual o legal, bien sea en condición de Matriz, Controlante,
            Subordinada, Filial, Comercial o Legal. Las anteriores finalidades
            son enunciativas mas no taxativas y podrán ser ampliadas de acuerdo
            con el Tratamiento de la información que se derive en el desarrollo
            de las actividades que comprende el objeto social.
          </p>
          <h4>VII. OBLIGACIONES DE LA POLÍTICA.</h4>
          <p>
            Esta política es de obligatorio cumplimiento para todas aquellas
            personas que ingresen a nuestras plataformas tecnológicas, sean
            clientes o con aquellas personas con las cuales sostiene sus
            relaciones comerciales, bien sea a título de Usuarios, Suscriptores,
            Clientes, Proveedores, Trabajadores y del público en general, dentro
            del marco de las actividades que realice en desarrollo de su objeto
            social.
          </p>
          <p>
            GS360 solicitará a los titulares de la información su autorización
            previa, expresa, inequívoca y debidamente informada, para el
            tratamiento de la información de la cual son titulares, así como
            atenderá en debida forma, los requerimientos y solicitudes
            realizados en cuanto a la recolección, almacenamiento, uso,
            circulación, modificación, actualización, supresión, procesamiento,
            intercambio, transferencia y/o transmisión de datos personales
            realizado por los titulares de la Información, disponiendo los
            medios, mecanismos y/o elementos necesarios para la protección de
            los datos personales y su reclamación para actualización,
            modificación o supresión.
          </p>
          <h4>
            VIII. DERECHOS DE LOS TITULARES FRENTE AL TRATAMIENTO DE DATOS
            REALIZADOS POR GS360.
          </h4>
          <p>
            • Conocer, actualizar y rectificar sus datos personales frente a los
            Responsables del Tratamiento o Encargados del Tratamiento. Este
            derecho se podrá ejercer, entre otros frente a datos parciales,
            inexactos, incompletos, fraccionados, que induzcan a error, o
            aquellos cuyo Tratamiento estén expresamente prohibido o no hayan
            sido autorizados;{" "}
          </p>
          <p>
            • Solicitar prueba de la autorización otorgada al Responsable del
            Tratamiento salvo cuando expresamente se exceptúe como requisito
            para el Tratamiento, de conformidad con lo previsto en el artículo
            10 de la normatividad vigente;{" "}
          </p>{" "}
          <p>
            • Ser informado por el Responsable del Tratamiento o el Encargado
            del Tratamiento, previa solicitud, respecto del uso que le ha dado a
            sus datos personales;{" "}
          </p>{" "}
          <p>
            • Presentar ante la Superintendencia de Industria y Comercio quejas
            por infracciones a lo dispuesto en la normatividad vigente y las
            demás normas que la modifiquen, adicionen o complementen;{" "}
          </p>{" "}
          <p>
            • Revocar la autorización y/o solicitar la supresión del dato cuando
            en el Tratamiento no se respeten los principios, derechos y
            garantías constitucionales y legales. La revocatoria y/o supresión
            procederá cuando la Superintendencia de Industria y Comercio haya
            determinado que en el Tratamiento el Responsable o Encargado han
            incurrido en conductas contrarias a esta ley y a la Constitución
            Política;{" "}
          </p>
          <p>
            • Acceder en forma gratuita a sus datos personales que hayan sido
            objeto de Tratamiento.
          </p>
          <h4>IX. DEBERES DE GS360 EN MATERIA DE DATOS PERSONALES. </h4>
          <p>
            Solicitar al Titular la autorización informándole de manera clara y
            expresa lo siguiente:{" "}
          </p>
          <p>• Finalidad del tratamiento de la información; </p>{" "}
          <p>• El Tratamiento al cual serán sometidos sus datos personales; </p>{" "}
          <p>
            • El carácter facultativo de la respuesta a las preguntas que le
            sean hechas, cuando estas versen sobre datos sensibles o sobre los
            datos de las niñas, niños y adolescentes;{" "}
          </p>{" "}
          <p>• Los derechos que le asisten como Titular; </p>{" "}
          <p>
            • La identificación, dirección electrónica y teléfono del
            Responsable del Tratamiento.{" "}
          </p>{" "}
          <p>
            • Conservar prueba de la autorización y/o documentos
            correspondientes{" "}
          </p>
          <p>
            {" "}
            • Entregar cuando el Titular lo solicite, los documentos que
            sustentan el Tratamiento de la Información.
          </p>
          <h4>
            X. PROCEDIMIENTO PARA LA CORRECCIÓN, MODIFICACIÓN, ACTUALIZACIÓN Y/O
            SUPRESIÓN DE DATOS.
          </h4>{" "}
          <p>
            <b>Solicitud:</b> El Titular o sus causahabientes podrán realizar
            una petición mediante escrito debidamente motivado dirigido al
            correo electrónico habeasdata@gs360.co La solicitud deberá contener
            como mínimo el Nombre del Titular, descripción de los hechos que dan
            lugar a su solicitud y/o reclamo, la dirección de correspondencia y
            los documentos que desee aportar. Este escrito lo puede hacer usted
            directamente o diligenciar a través de nuestras proformas ubicadas
            en la página web.
          </p>
          <p>
            <b>Análisis:</b> Una vez recibida la solicitud, haremos el análisis
            y verificación de los requisitos mínimos o si llegare a ser
            necesaria ampliar la información. En los 2 días siguientes a la
            recepción, se incluirá en la base de datos una leyenda que diga{" "}
            <b>"RECLAMO EN TRÁMITE"</b> si la base de datos lo permite, y el
            motivo del mismo, manteniéndose la leyenda hasta que el
            procedimiento de reclamación sea decidido.
          </p>
          <p>
            {" "}
            <b>GS360</b> deberá atender la solicitud dentro de los quince (15)
            días hábiles contados a partir del día siguiente a la fecha en que
            se recibió la solicitud{" "}
          </p>
          <p>
            Si no fuere posible atender el reclamo dentro de dicho término, se
            informará al interesado los motivos de la demora y la fecha en que
            se atenderá su reclamo, la cual en ningún caso podrá superar los
            ocho (8) días hábiles siguientes al vencimiento del primer término.
          </p>
          <p>
            <b>Decisión:</b> GS360 a través de un escrito pronunciará su
            decisión en cuanto a la solicitud que realizó el titular de la
            información o su causahabiente. Las decisiones relacionadas con los
            datos personales deberán adoptarse de manera objetiva y estas podrán
            o no acogerse a lo esperado por el titular o su Causahabiente.
          </p>
          <p>
            <b>Resultado:</b> El resultado se encuentra contenido en el escrito
            de decisión y se deberá implementar dentro de los 8 días hábiles
            siguientes a la fecha de la decisión. Este podrá establecerse de la
            siguiente manera:
          </p>
          <p>
            a) Si la solicitud es sobre la corrección, modificación,
            actualización o aclaración de los datos e información contenida en
            las Bases de Datos y la solicitud aceptada por GS360, realizaremos
            el correspondiente registro notificándole la información de
            conformidad con los datos suministrados por el titular.{" "}
          </p>
          <p>
            b) Si la solicitud es sobre la supresión de los datos contenidos en
            nuestras bases de datos y la decisión es aceptada por GS360,
            procederemos a realizar eliminar la información de nuestras bases de
            datos informándole el resultado final o hasta el momento en que se
            proceda a tener nueva relación y autorización previa{" "}
          </p>
          <p>
            c) Si la decisión no es aceptada por GS360, usted podrá solicitar y
            justificar nuevamente el caso mediante escrito y aportando las
            pruebas que considere necesarias. Radicada la solicitud, GS360
            tendrá el mismo término para analizar, decidir y comunicar la
            decisión adoptada sobre el caso en particular.
          </p>
          <img
            src={grafico1}
            alt=""
            className="img-fluid"
            onClick={() => setGrafico1Show(true)}
          />
          <br></br>
          <br></br>
          <img
            src={grafico2}
            alt=""
            className="img-fluid"
            onClick={() => setGrafico2Show(true)}
          />
          <h4>I. NORMATIVIDAD APLICABLE.</h4>
          <p>
            Este documento se encuentra estructurado con base en lo establecido
            en los artículos 15 y 20 de la Constitución Política de Colombia la
            Ley 1266 de 2008, la Ley 1581 de 2012, los Decretos Reglamentarios
            1727 de 2009; 2952 de 2010 y el Decreto Reglamentario parcial No
            1377 de 2013. Le serán aplicables las demás normas emitidas o
            expedidas por el Gobierno Nacional en relación con Datos Personales,
            que se expidan con posterioridad a la vigencia del presente
            documento.
          </p>
          <h4>II. VIGENCIA.</h4>
          <p>
            La presente política de tratamiento de datos personales y de la
            información fue deliberada, discutida y aprobada por GS360 el 17 de
            marzo de 2021 y rige a partir de su publicación en nuestros medios y
            canales oficiales de comunicación.
          </p>{" "}
        </Col>
      </Row>

      <Modal
        show={grafico1Show}
        onHide={() => setGrafico1Show(false)}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        {" "}
        <Modal.Header closeButton />
        <Modal.Body>
          <img src={grafico1} alt="" className="img-fluid" />
        </Modal.Body>
      </Modal>

      <Modal
        show={grafico2Show}
        onHide={() => setGrafico2Show(false)}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <img src={grafico2} alt="" className="img-fluid" />
        </Modal.Body>
      </Modal>
    </Container>
  );
}

import React from "react";

/* IMG */
import iconAperturaEmpresa from '../../assets/img/home/iconos/aperturaEmpresa.png';
import iconBackOffice from '../../assets/img/home/iconos/backoffice.png';
import iconFirmaElectronica from '../../assets/img/home/iconos/firmaElectronica.png';
import iconProteccionMarca from '../../assets/img/home/iconos/proteccionMarca.png';
import iconProteccionLegal from '../../assets/img/home/iconos/proteccionLegal.png';
import iconProductosServiciosNetworking from '../../assets/img/home/iconos/productosServicios.png';

/*CSS*/
import "./css/NecesitaTuNegocio.css";

/*BOOTSTRAP*/
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function NecesitaTuNegocio() {

  const options = [    
    { value: 1, 
      img: iconAperturaEmpresa,
      alt: "Apertura de Empresas",
      tit: "Apertura de empresas \n en Colombia"
    }, 
    { value: 2, 
      img: iconBackOffice,
      alt: "Backoffice contable",
      tit: "Backoffice contable"
    },
    { value: 3, 
      img: iconFirmaElectronica,
      alt: "Firma electrónica Legal",
      tit: "Firma electrónica \n Legal"
    },
    { value: 4, 
      img: iconProteccionMarca,
      alt: "Protección de Marca",
      tit: "Protección de Marca"
    },
    { value: 5, 
      img: iconProteccionLegal,
      alt: "Protección Legal",
      tit: "Protección Legal"
    },  
    { value: 6, 
      img: iconProductosServiciosNetworking,
      alt: "Productos, Servicios y Networking con empresas locales",
      tit: "Productos, Servicios \n y Networking \n con empresas locales"
    },   
  ]
  

  return (
    <Container className="bgNecesitaTuNegocio" fluid>
      <Container>
        <Row>
          <Col md={12} xs={12} className="text-center">         
            <h1 className="titHero text-center" style={{color:'000 !important'}}>Todo lo que necesita<br className="d-sm-block d-none"/> tu negocio</h1>         
            <p className="heroText text-center pt-4">Como emprendedores sabemos lo que necesitas y lo<br className="d-sm-block d-none"/> reunimos todo en un solo lugar con tarifas transparentes<br className="d-sm-block d-none"/> y justas.

            </p>  
          </Col>
        </Row>
        <Row>
        {options.map((item) => (           
              <Col md={2} xs={12} className="text-center mt-5">
                <div class="bgWhiteNT h-100 ">
                  <img src={item.img} className="mx-auto mb-1" alt={item.alto}/>      
                  <p className="text-center">{item.tit}</p>
                </div> 
              </Col>  
            ))}
        </Row>
      </Container>      
    </Container>
  );
}

export default NecesitaTuNegocio;

import React from "react";

/* IMG */
import imgInformes from "../../assets/img/brandineitor/imgInformes.png";

import "./css/error404.css";

/*BOOTSTRAP*/
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default function Error404() {
  return (
    <Container className="main404">
      <Row className="mb-5 pb-4">
        <Col md={7} xs={12} className="text-center mx-auto">
          <img src={imgInformes} alt="Proximamente" className="img-fluid" />
          <h2 className="subtitHero text-center pb-0">Proximamente</h2>
          <p className="text-center pt-4"></p>
        </Col>
      </Row>
    </Container>
  );
}

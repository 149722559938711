import React from "react";
import { Link } from "react-router-dom";

/* IMG */
import iconViewList from "../../../assets/img/proveedores/iconViewList.png";
import iconViewCuadricula from "../../../assets/img/proveedores/iconViewCuadricula.png";
import iconBuscar from "../../../assets/img/proveedores/iconBuscar.png";
/*CSS*/
import "../css/Recursos.css";

/*BOOTSTRAP*/
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import InputGroup from "react-bootstrap/InputGroup";

export default function FiltroRecursos() {
  return (
    <Container className="filtroRecursos pb-4">
      <Row>
        <Col
          md={5}
          xs={12}
          className="d-flex align-items-center justify-content-sm-start justify-content-center mb-4"
        >
          <p className="mb-0">Categoria</p>
          <Form.Control
            as="select"
            className="ml-2 selectCategory"
            id="inlineFormCustomSelect"
            custom
          >
            <option value="0">Choose...</option>
            <option value="1">One</option>
            <option value="2">Two</option>
            <option value="3">Three</option>
          </Form.Control>
        </Col>
        <Col md={7} xs={12} className="mb-4">
          <Row>
            <Col md={12} xs={12}>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>
                    <img src={iconBuscar} alt="" />
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  id="buscar"
                  placeholder="Buscar..."
                  className="searchInput"
                />
              </InputGroup>
            </Col>
            <Col md={4} xs={4} className="text-sm-center text-left d-none">
              <img src={iconViewList} alt="" className="img-fluid m-2" />
              <img
                src={iconViewCuadricula}
                alt=""
                className="img-fluid  m-2 mr-0"
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}
